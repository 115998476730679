<form #anamnesisForm="ngForm" *ngIf="haveAnamesis; else noAnamesis" #anamnesis>
	<div class="container-fluid anamnesis-container">
		<div class="row">
			<div class="col-12 mb-2 substeps-container">
				<span *ngFor="let step of steps; let i = index">
					<fa-icon *ngIf="i < currentStep - 1" class="substep-icon" [icon]="faCheck"></fa-icon>
					<span class="substep-title" [ngClass]="currentStep === i + 1 ? 'active' : ''">{{ step }}</span>
					<fa-icon *ngIf="i !== steps.length - 1" class="substep-icon" [icon]="faArrowRight"></fa-icon>
				</span>
			</div>
		</div>

		<div class="col-12 mb-2">
			<span>{{ 'ANAMNESIS.ENTRY_1' | translate }}</span>
			<br />
			<span>{{ 'ANAMNESIS.ENTRY_2' | translate }}</span>
		</div>

		<div class="row mt-2" *ngFor="let questionsGroup of groupedQuestions; let i = index">
			<div class="col-12" *ngFor="let question of questionsGroup.questions">
				<!-- CHECKBOX / RADIO -->
				<div class="col p-1 border-bottom" [ngClass]="question.depends_on !== null && !questionDependenciesSatisfied(question) ? 'inactive' : ''" *ngIf="currentStep === i + 1 && question.input_type !== 'slider'">
					<div class="d-flex mb-1 align-items-center">
						<span class="fw-bold me-1">{{ question.question }}</span>
						<fa-icon *ngIf="question.tooltip && question.tooltip !== ''" [icon]="faQuestion" aria-hidden="true" [ngbTooltip]="question.tooltip" tooltipClass="question-tooltip col-4" placement="right"></fa-icon>
					</div>

					<div class="d-flex flex-wrap">
						<div class="p-2 m-2 d-flex btn-answer" *ngFor="let answer of question.answers; let e = index" (click)="toggle(question, e)">
							<input class="pe-none" [type]="question.input_type" id="btn-check-{{ question.question_id }}-{{ answer.reply_id }}" [(ngModel)]="answer.checked" #btncheck="ngModel" name="btn-check-{{ question.question_id }}-{{ answer.reply_id }}" [checked]="answer.checked" autocomplete="off" />
							<label class="p-1 pe-none" for="btn-check-{{ question.question_id }}-{{ answer.reply_id }}">{{ answer.reply }}</label>
						</div>
					</div>
				</div>

				<!-- SLIDER -->
				<div class="col p-1 border-bottom" [ngClass]="question.depends_on !== null && !questionDependenciesSatisfied(question) ? 'inactive' : ''" *ngIf="currentStep === i + 1 && question.input_type === 'slider'">
					<div class="d-flex mb-4 align-items-center">
						<span>{{ question.question }}</span>
					</div>

					<mat-slider class="slider-answer" min="0" max="4" step="1">
						<div class="slider-labels">
							<b class="text-uppercase">{{ question.answers[0].reply }}</b>
							<b class="text-uppercase">{{ question.answers[2].reply }}</b>
							<b class="text-uppercase">{{ question.answers[4].reply }}</b>
						</div>
						<input (input)="onSliderInputChange($event, question)" matSliderThumb [value]="getSliderDefaultValue(question)" />
					</mat-slider>
				</div>
			</div>
		</div>

		<!-- VA -->
		<div class="row mt-2" *ngIf="currentStep === steps.length && addVaFlag">
			<app-pat-va [thisVa]="thisVa" (out-va)="thisVa = $event; outVa.emit($event)" (uncorrected-populated)="handleUncorrectedValuesPopulated($event)"></app-pat-va>
		</div>
	</div>
	<div class="container mt-2" *ngIf="false">
		<div class="row">
			<div class="col-2">
				<label class="btn btn-download" ngbTooltip="Download the agreement"><fa-icon [icon]="faDownload"></fa-icon></label>
			</div>
		</div>
	</div>
</form>

<ng-template #noAnamesis>
	<div class="container">
		<div class="row my-5">
			<div class="col-12">
				<h5 class="text-center fw-bold">{{ 'ANAMNESIS.NO_ANAMNESIS' | translate }}</h5>
			</div>
		</div>
	</div>
</ng-template>

<div class="mt-5">&nbsp;</div>
