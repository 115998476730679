<!-- <div class="loading-container" [hidden]="!session.isLoadingPatients()">
	<div class="tail-spin">&nbsp;</div>
</div> -->

<div>
	<!-- <div class="topview container-fluid" [hidden]="!session.isLoadingPatients()">
    <div class="txtLoader"><mat-spinner></mat-spinner>{{"MISC.LOADING" | translate}} </div>
  </div> -->
	<div class="container" *ngIf="session.isLevel1() && !session.isClalit()">
		<div class="row">
			<div class="col-12 d-flex justify-content-center">
				<div class="shadow newReports" [hidden]="newReportCount == 0" disabled>{{ newReportCount }} {{ (newReportCount > 1 ? 'REPORT.NEW_REPORTS' : 'REPORT.NEW_REPORT') | translate }}</div>
			</div>
		</div>
	</div>
	<div class="patient-list-container topview z-depth-2">
		<!--  -->
		<div class="row">
			<div class="full-container">
				<div class="panel panel-default">
					<div class="panel-head">
						<div class="container-fluid">
							<!-- <div class="row mb-4" *ngIf="!session.isLevel1() || (session.isGroupB() && doctorId > 0)">
								<nav aria-label="breadcrumb" class="col-12">
									<ol class="breadcrumb">
										<li class="breadcrumb-item"><a> {{ session.getDoctorName(doctorId) }}</a></li>
										<li class="breadcrumb-item active" aria-current="page">{{ 'PATIENT_LIST.PAGE_TITLE_DOCTOR' | translate }}</li>
									</ol>
								</nav>
							</div> -->
							<!--<div *ngIf="session.isLevel3()">
								 <div class="filter">
						<input type="radio" [(ngModel)]="filterList" value=""> {{'RELATIONS_LIST.ALL' | translate}} &nbsp;&nbsp;
						<input type="radio" [(ngModel)]="filterList" value="A"> {{'RELATIONS_LIST.ACTIVE' | translate}} &nbsp;&nbsp;
						<input type="radio" [(ngModel)]="filterList" value="D"> {{'RELATIONS_LIST.DELETED' | translate}}
					  </div> 
							</div>-->
							<div class="row">
								<div class="col-12">
									<div class="row">
										<div class="col align-self-end">
											<div class="float-end">
												<button type="button" class="btn btn-primary btn-sm reload shadow" (click)="reloadList()" [disabled]="!reloadEnable">
													{{ 'BUTTONS.REFRESH' | translate }} <span><fa-icon [icon]="faRotate" aria-hidden="true"></fa-icon></span>
												</button>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-12">
											<button *ngIf="session.userCanCreatePatient() else breadcrumb" type="button" class="btn btn-primary shadow" (click)="newPatientModal()">{{ 'PATIENT_LIST.ADD_NEW_PATIENT' | translate }}</button>

											<ng-template #breadcrumb>
												<ol class="breadcrumb" *ngIf="session.isLevel3() ">
													<li class="breadcrumb-item"><a> {{ doctorUsername }}</a></li>
													<li class="breadcrumb-item active" aria-current="page">{{ 'PATIENT_LIST.PAGE_TITLE_DOCTOR' | translate }}</li>
												</ol>
											</ng-template>

											<!-- <div class="ms-4 d-inline" *ngIf="session.isTelerefractEnabled() && session.isMini()">
												<label class="fw-bold me-2" style="font-size: 14px">{{'PATIENT_LIST.FULLSCREEN' | translate}}</label>
												<mat-slide-toggle [color]="color" [disabled]="true" [(ngModel)]="isSetFull" ngbTooltip="{{'PATIENT_LIST.FULLSCREEN_TOOLTIP' | translate}}" (change)="setFullNewPat()"> </mat-slide-toggle>
											</div> -->
										</div>

										<p class="pe-5" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
											<b>{{'PATIENT_LIST.FOR_LV2.FILTER_MAINTITLE' | translate}}</b>
										</p>

										<div class="col-12">
											<!-- Filters -->

											<form [formGroup]="filterForm1" class="row" *ngIf="session.isSpecialist() || session.isClinicAdmin() || session.isMiniC()">
												<div class="grad__cont col-xxl-2 col-sm-3 position-relative d-flex flex-row align-items-center">
													<mat-form-field appearance="outline" class="w-100">
														<!-- nella balance viene applicato questo colore automatico, qui no boh, applico io a mano -->
														<mat-label style="color: #4f0aeb">{{'PATIENT_LIST.FOR_LV2.GRADING_STATUS' | translate}}</mat-label>
														<mat-select name="grading" (selectionChange)="filterValue()" formControlName="grading">
															<mat-option *ngFor="let grad of gradingStatus" [value]="grad"> {{grad}} </mat-option>
														</mat-select>
													</mat-form-field>
												</div>

												<div class="col-xxl-2 col-sm-3 grader__cont position-relative flex-row align-items-center" [hidden]="!session.isClinicAdmin()" [ngClass]="{'d-flex': session.isClinicAdmin()}">
													<!-- <mat-form-field appearance="outline">
														
														<mat-label style="color: #4f0aeb">{{'PATIENT_LIST.FOR_LV2.GRADERS' | translate}}</mat-label>
														<mat-select name="graders" [ngModel]="gradersNames[0]" (selectionChange)="filterValue()" formControlName="graders">
															<mat-option *ngFor="let grad of gradersNames" [value]="grad"> {{grad}} </mat-option>
														</mat-select>
													</mat-form-field> -->

													<mat-form-field appearance="outline" [ngClass]="{'is-valid': myGradersControl.disabled}" class="w-100">
														<mat-label>{{'PATIENT_LIST.FOR_LV2.GRADERS' | translate}}</mat-label>

														<input type="text" placeholder="{{'PATIENT_LIST.FOR_LV2.FILTER_TYPING' | translate}}" matInput [formControl]="myGradersControl" [matAutocomplete]="auto" />

														<mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="myGradersControl.disable(); filterValue()">
															<mat-option *ngFor="let option of filteredGraders | async" [value]="option"> {{option}} </mat-option>
														</mat-autocomplete>

														<fa-icon [icon]="faCaretDown" faCaretDown class="float-end"></fa-icon>
													</mat-form-field>
													<a class="reset-button" [ngClass]="{'show': myGradersControl.disabled && !activateSpinner}" (click)="myGradersControl.enable(); myGradersControl.setValue(''); filterValue(); patientPref.graders = '' ">
														<fa-icon [icon]="faXmark"></fa-icon>
													</a>
												</div>

												<div class="col-xxl-2 col-sm-3 operator__cont position-relative d-flex flex-row align-items-center">
													<!-- <mat-form-field appearance="outline">
														<mat-label style="color: #4f0aeb"> {{'PATIENT_LIST.FOR_LV2.OPERATORS' | translate}}</mat-label>
														<mat-select [ngModel]="operatorsNames[0]" name="operators" (selectionChange)="filterValue()" formControlName="operators">
															<mat-option *ngFor="let op of operatorsNames" [value]="op"> {{op}} </mat-option>
														</mat-select>
													</mat-form-field> -->

													<mat-form-field appearance="outline" [ngClass]="{'is-valid': myOperatorsControl.disabled}" class="w-100">
														<mat-label>{{'PATIENT_LIST.FOR_LV2.OPERATORS' | translate}}</mat-label>

														<input type="text" placeholder="{{'PATIENT_LIST.FOR_LV2.FILTER_TYPING' | translate}}" matInput [formControl]="myOperatorsControl" [matAutocomplete]="auto2" />

														<mat-autocomplete autoActiveFirstOption #auto2="matAutocomplete" (optionSelected)="myOperatorsControl.disable(); filterValue()">
															<mat-option *ngFor="let option of filteredOperators | async" [value]="option"> {{option}} </mat-option>
														</mat-autocomplete>

														<fa-icon [icon]="faCaretDown" class="float-end" faCaretDown></fa-icon>
													</mat-form-field>
													<a class="reset-button ms-2" [ngClass]="{'show': myOperatorsControl.disabled && !activateSpinner}" (click)="myOperatorsControl.enable(); myOperatorsControl.setValue(''); filterValue(); patientPref.operators = '' "><fa-icon [icon]="faXmark" faXmark></fa-icon></a>
												</div>

												<!-- date filters -->

												<div class="col-xxl-2 col-sm-3 request-date__cont position-relative d-flex flex-row align-items-center">
													<mat-form-field appearance="outline" (click)="picker1.open();" class="w-100">
														<mat-label>{{'PATIENT_LIST.FOR_LV2.REQUEST_DATE' | translate}}</mat-label>
														<input matInput [matDatepicker]="picker1" (dateChange)="filterValue()" formControlName="requestDate" />
														<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
														<mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
														<mat-datepicker #picker1></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('requestDate').value != null ? 'show': '' " (click)="picker1.select(null); patientPref.req_date= null"></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 due-date__cont position-relative d-flex flex-row align-items-center">
													<mat-form-field appearance="outline" (click)="picker2.open()" class="w-100">
														<mat-label>{{'PATIENT_LIST.FOR_LV2.DUE_DATE' | translate}}</mat-label>
														<input matInput [matDatepicker]="picker2" (dateChange)="filterValue()" formControlName="dueDate" />
														<!-- <mat-hint>MM/DD/YYYY</mat-hint> -->
														<mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
														<mat-datepicker #picker2></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('dueDate').value != null? 'show': '' " (click)="picker2.select(null); patientPref.due_date = null"></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 report-date__cont position-relative d-flex flex-row align-items-center">
													<mat-form-field ngbTooltip="Report Date" appearance="outline" (click)="picker3.open()" class="w-100">
														<mat-label>{{'PATIENT_LIST.FOR_LV2.REPORT_DATE' | translate}}</mat-label>
														<input matInput [matDatepicker]="picker3" (dateChange)="filterValue()" formControlName="reportDate" />

														<mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
														<mat-datepicker #picker3></mat-datepicker>
													</mat-form-field>
													<fa-icon [icon]="faXmark" style="color: #ff3b2d" [ngClass]="filterForm1.get('reportDate').value != null? 'show': '' " (click)="picker3.select(null); patientPref.rep_date = null "></fa-icon>
												</div>

												<div class="col-xxl-2 col-sm-3 filter-text__cont position-relative d-flex flex-row align-items-center">
													<mat-form-field appearance="outline" class="w-100">
														<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
													</mat-form-field>
												</div>
											</form>

											<!-- <button type="button" class="btn" (click)="test()">test</button> -->
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="panel-body position-relative" style="min-height: 35vh">
						<loader [activateSpinner]="activateSpinner"></loader>
						<!-- 
						<div class="loader-container" [ngClass]="activateSpinner ? 'show' : '' ">
							<div [hidden]="false" class="lds-ring">
								<div></div>
								<div></div>
								<div></div>
								<div></div>
							</div>
						</div> -->

						<div>
							<table mat-table [dataSource]="patientList" class="patient-list" matSort style="width: 100%" [ngClass]="!activateSpinner ? 'show' : '' ">
								<!-- User NAme Column -->
								<ng-container matColumnDef="name">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ patNameTitle }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)" style="font-weight: bold" title="{{  !session.isClinicAdmin() ? ('PATIENT_LIST.HINT_VISITS' | translate) : '' }}">{{ element.name }}</td>
								</ng-container>

								<ng-container matColumnDef="internal_code">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.INTERNAL_CODE' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ element.internal_code }}</td>
								</ng-container>

								<ng-container matColumnDef="lastExamDate">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.LAST_EXAM_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ session.formatDateTime(element.lastExamDate) }}</td>
								</ng-container>

								<ng-container matColumnDef="subscription_time">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.REGISTRATION_DATE' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ session.formatDate(element.subscription_time) }}</td>
								</ng-container>

								<ng-container matColumnDef="code">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.CODE' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ element.code }}</td>
								</ng-container>

								<ng-container matColumnDef="birthYear">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.AGE' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)" [ngStyle]="{'font-weight': today.getFullYear() <= element.birthYear ? 'bold': '', 'color': today.getFullYear() <= element.birthYear ? '#ff3b2d' : '' }">
										{{ (session.isLevel1() ? element.birthDate : '' + element.birthYear) | age }}
									</td>
								</ng-container>

								<ng-container matColumnDef="sex">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'PATIENT_LIST.SEX' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ 'SEX.' + element.sex | translate }}</td>
								</ng-container>

								<ng-container matColumnDef="statusHG">
									<th mat-header-cell *matHeaderCellDef [hidden]="!canSeeStatus" mat-sort-header>
										{{ 'PATIENT_LIST.HG_STATUS' | translate }} &nbsp;
										<fa-icon [icon]="faQuestion" aria-hidden="true" (click)="$event.stopPropagation(); openLegend('HG_status')"></fa-icon>
									</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)" [hidden]="!canSeeStatus">
										<span><i class="status-icon nomargin {{ element.dotReport.class }}" ngbTooltip="{{ element.dotReport.tooltip }}"></i></span>
									</td>
								</ng-container>

								<ng-container matColumnDef="statusAI">
									<th mat-header-cell *matHeaderCellDef [hidden]="!aiEnabled" mat-sort-header>{{ 'PATIENT_LIST.AI_STATUS' | translate }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)" [hidden]="!aiEnabled">
										<span><i class="status-icon nomargin {{ element.dotReportAI.class }}" ngbTooltip="{{ element.dotReportAI.tooltip }}"></i></span>
									</td>
								</ng-container>

								<ng-container matColumnDef="statusDP">
									<th mat-header-cell *matHeaderCellDef [hidden]="!diagonalEnabled" mat-sort-header>Diagonal Plus</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)" [hidden]="!diagonalEnabled">
										<span><i class="status-icon nomargin {{ element.dotReportDP.class }}" ngbTooltip="{{ element.dotReportDP.tooltip }}"></i></span>
									</td>
								</ng-container>

								<ng-container matColumnDef="filter">
									<th mat-header-cell *matHeaderCellDef class="text-end" style="width: 10%">
										<!-- Aggiounta questa larghezza per avere la colonna della larghezza del filtro e recuperare spazio per il tablet -->
										<mat-form-field appearance="outline">
											<input matInput type="text" (keyup)="filterText()" placeholder="{{ 'MISC.SEARCH' | translate }}" #filter />
										</mat-form-field>
									</th>
									<td mat-cell *matCellDef="let element">
										<a class="table-button" *ngIf="session.userCanEditPatient() && !element.isReadOnly()" ngbTooltip="{{ 'BUTTONS.EDIT' | translate }}" (click)="openPatientModal(element)">
											<fa-icon *ngIf="!isLoadingPatientModal; else patientEditSpinner" [icon]="faEdit" aria-hidden="true"></fa-icon>
											<ng-template #patientEditSpinner>
												<div class="spinner-border spinner-border-sm text-primary" role="status">
													<span class="sr-only">Loading...</span>
												</div>
											</ng-template>
										</a>

										<a class="table-button" *ngIf="session.userCanDeletePatient(element)" (click)="openDeleteModal(element)" ngbTooltip="{{ 'BUTTONS.DELETE' | translate }}">
											<fa-icon [icon]="faTrashAlt" aria-hidden="true"> </fa-icon>
										</a>

										<a *ngIf="today.getFullYear() <= element.birthYear" class="me-2 wrong-age-icon" ngbTooltip="{{ 'PATIENT_LIST.WRONG_AGE' | translate }}"><fa-icon [icon]="faTriangleExclamation" aria-hidden="true"></fa-icon></a>

										<span *ngIf="element.isReadOnly() && element.dicom_id!='' " class="opacity-50" style="font-size: 12px">Dicom id: {{element.dicom_id}}</span>
									</td>
								</ng-container>

								<ng-container matColumnDef="request_date">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PATIENT_LIST.FOR_LV2.REQUEST_DATE' | translate}}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{session.formatDateTime(element.request_date)}}</td>
								</ng-container>

								<ng-container matColumnDef="due_date">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PATIENT_LIST.FOR_LV2.DUE_DATE' | translate}}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{session.formatDate(element.due_date)}}</td>
								</ng-container>

								<ng-container matColumnDef="report_date">
									<th mat-header-cell *matHeaderCellDef>{{'PATIENT_LIST.FOR_LV2.REPORT_DATE' | translate}}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{session.formatDateTime(element.lastReportDate)}}</td>
								</ng-container>

								<ng-container matColumnDef="grader">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{'PATIENT_LIST.FOR_LV2.GRADER' | translate}}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{element.grader}}</td>
								</ng-container>

								<ng-container matColumnDef="created_by">
									<th mat-header-cell *matHeaderCellDef mat-sort-header>{{ session.isSpecialist() || session.isClinicAdmin() ? ('PATIENT_LIST.FOR_LV2.OPERATOR' | translate) : ('PATIENT.CREATED_BY' | translate) }}</th>
									<td mat-cell *matCellDef="let element" (click)="showVisits(element)">{{ getStrCreator(element.operator) }}</td>
								</ng-container>

								<tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
								<tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="[today.getFullYear() < row.birthYear ? 'wrong-age' : '', isMiniC ? 'isminic' : '' ,(row.assigned_to == currUserId || row.exams_to_review == 0) ? '' : 'not_assigned']"></tr>
							</table>

							<mat-paginator [pageSizeOptions]="[10, 15, 20, 25, 50]" showFirstLastButtons aria-label="Select page of periodic elements" [ngClass]="!activateSpinner ? 'show' : '' "> </mat-paginator>

							<div class="noresults-banner" [ngClass]="{show : (patList.length == 0 && !session.isLoadingPatients()) || isFilterTextEmpty }">
								<div class="container">
									<div class="row text-center noresults-banner__cont">
										<div class="col-5"></div>
										<div class="col-2 alert alert-secondary" role="alert" *ngIf="!filterSet">{{ 'PATIENT_LIST.FOR_LV2.NO_PATIENTS' | translate }}</div>
										<div class="col-2 alert alert-secondary" role="alert" *ngIf="filterSet">{{ 'PATIENT_LIST.FOR_LV2.NO_PAT_FILTER' | translate }}</div>
										<div class="col-5"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
