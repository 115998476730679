<div class="row">
	<div class="col-12" *ngFor="let vis of visusArray; let i=index">
		<div class="row p-4 border-bottom">
			<div class="col-6 d-flex align-items-center">
				<div><span>{{i+1}}</span></div>

				<span class="ms-4 fw-bold">{{vis.name + (i === 0 ? '*' : '')}}</span>
			</div>

			<div class="col-6 d-flex">
				<div class="pe-3">
					<div class="input-group">
						<input type="text" [name]="i+'right'" [id]="i+'right'" class="form-control" tabindex="0" [(ngModel)]="vis.value.right" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" patterncheck (keyup)="copyVa(i, 'right')" />
						<span class="input-group-text">{{'VA.RIGHT' | translate }}</span>
					</div>
				</div>

				<div class="pe-3">
					<div class="input-group">
						<input type="text" [name]="i+'left'" [id]="i+'left'" class="form-control" tabindex="0" [(ngModel)]="vis.value.left" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" patterncheck (keyup)="copyVa(i, 'left')" />
						<span class="input-group-text">{{'VA.LEFT' | translate }}</span>
					</div>
				</div>

				<div>
					<div class="input-group">
						<input type="text" [name]="i+'bino'" [id]="i+'bino'" class="form-control" tabindex="0" [(ngModel)]="vis.value.bino" autocapitalize="off" autocomplete="off" autocorrect="off" spellcheck="false" patterncheck (keyup)="copyVa(i, 'bino')" />
						<span class="input-group-text">{{'VA.BINO' | translate }}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
