import { Component, OnInit, ViewChild, AfterViewInit, ElementRef, OnDestroy } from '@angular/core'
import { ActivatedRoute, Router, UrlSegment } from '@angular/router'

import { SessionService } from 'src/app/service/session.service'
import { MapService } from 'src/app/service/map.service'
import { AppToastService } from 'src/app/service/toast.service'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'

import { createMap } from 'maplibre-gl-js-amplify'
import { Amplify, ResourcesConfig } from 'aws-amplify'
import * as maplibregl from 'maplibre-gl'
import { Geo, Place } from '@aws-amplify/geo'

import { searchOptions, LatLng, location, mapConfig } from 'src/app/models/map.model'
import { Util } from 'src/app/models/util.model'
import { Address } from 'src/app/models/address.model'
import { ToastOptions } from 'src/app/models/toast.model'

import { Config } from 'src/config'
import { TranslateService } from '@ngx-translate/core'

import { faInfoCircle, faTriangleExclamation, faSave, faPenToSquare, faTrashCan, faRotateLeft, faPlus } from '@fortawesome/free-solid-svg-icons'
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap'
import { Subscription } from 'rxjs'

@Component({
	selector: 'app-location',
	templateUrl: './location.component.html',
	styleUrls: ['./location.component.scss'],
})
export class LocationComponent implements OnInit, OnDestroy {
	@ViewChild('input') input!: ElementRef
	latLng: LatLng //quelle salvate del mainAddress
	searchOptions: searchOptions // gli passo la country e le coordinate se ne ha, in modo da centrarlo li all'inizio
	isLoadingMap: boolean // solo per lo spinner, xk nel caso di coordinate assenti, la mappa viene caricata, ma ci vuole un po con connessioni non performanti per cercare ericevere risposta
	isSearchingLocation: boolean
	addressListReady: boolean //mostra/nascone i risulati della ricerca, per menu locations mostra/nasconde (anche) le locations salvate
	changeAddress: boolean
	seachString: string
	infoString: string
	warningAddr: boolean
	waitSave: boolean

	oldLabel: string
	attentionBodyAddress: string

	isProfile: boolean

	mainAddress: Address
	clinicAddresses: Address[]

	map: maplibregl.Map
	marker: maplibregl.Marker
	searchString: string

	addressList: location[]
	selectedLocations: location[]

	profileStateSubscription: Subscription

	mapOptions = {
		container: 'map', // id del div	in cui mettere la mappa
		region: '',
	}

	amplyConfig: ResourcesConfig = {
		// non posso usare il tipo ResourcesConfig in quanto c'é un errore nella libreria che cerca search_indices invece di searchIndices come é nel tipo
		// DP ResourcesConfig
		// senza ggiornare la libreria alla 6.2.0 ora sembra fixato boh
		Auth: {
			Cognito: {
				identityPoolId: '',
				allowGuestAccess: true,
			},
		},
		Geo: {
			LocationService: {
				region: '',
				maps: {
					items: {},
					default: '',
				},
				searchIndices: {
					//nel tipo LocationServiceConfig é searchIndices
					items: [],
					default: '',
				},
			},
		},
	}

	faInfoCircle = faInfoCircle
	faTriangleExclamation = faTriangleExclamation
	faSave = faSave
	faPenToSquare = faPenToSquare
	faTrashCan = faTrashCan
	faRotateLeft = faRotateLeft
	faPlus = faPlus

	constructor(
		public session: SessionService,
		private toastService: AppToastService,
		private mapService: MapService,
		private translator: TranslateService,
		private activatedRoute: ActivatedRoute,
		private modalService: NgbModal
	) {
		Util.debug('LocationElement - Constructor')
		this.changeAddress = true
		this.isLoadingMap = true
		this.isSearchingLocation = true
		this.addressListReady = false
		this.seachString = ''
		this.infoString = ''
		this.warningAddr = false
		this.waitSave = false

		this.oldLabel = ''
		this.attentionBodyAddress = this.translator.instant('MAP_EL.ATTENTION_BODY_ADDR')

		this.mainAddress = this.session.user.getMainAddress()
		this.clinicAddresses = this.getAddresses()
		this.addressList = []
		this.selectedLocations = []
		// console.log(this.session.user)
		this.latLng = this.session.user.getMainAddressCoordinates()
		this.marker = null

		let url: UrlSegment[] = this.activatedRoute.snapshot.url

		if (url[0].path == 'profile') {
			this.isProfile = true // per gestione in base se profile o no
		}

		// mi ricavo la configurazione di amplify dal bridge
		let token = this.session.user.token
		this.mapService
			.getAmplifyConfiguration(token)
			.then((res: mapConfig) => {
				// console.log(res)
				Util.debug('LocationElement - getAmplifyConfiguration completed')

				// quando fixeranno il modello, si potrá usare il tipo ResourcesConfig e potró creare una classe nel modello che faccia questa assegnazione automatiacamente
				this.mapOptions.region = res.mapConfig.region
				this.amplyConfig.Geo.LocationService.region = res.mapConfig.region
				this.amplyConfig.Auth.Cognito.identityPoolId = res.mapConfig.identityPoolId
				this.amplyConfig.Geo.LocationService.maps.items[res.mapConfig.mapName] = {}
				this.amplyConfig.Geo.LocationService.maps.default = res.mapConfig.mapName
				this.amplyConfig.Geo.LocationService.searchIndices.items = [res.mapConfig.indexItems]
				this.amplyConfig.Geo.LocationService.searchIndices.default = res.mapConfig.defaultIndex

				this.searchOptions = new searchOptions({ latitude: 0, longitude: 0 }, this.session.user.getCountry(), res.mapConfig.indexItems)

				Amplify.configure(this.amplyConfig)

				// console.log(Amplify.getConfig())

				if (this.isProfile) {
					this.startComponentProfile()
				} else {
					this.startComponent()
				}
			})
			.catch((err) => {
				console.log(err)
				this.session.infoBridgeAvailable = false

				this.session.refreshProfile()

				this.isSearchingLocation = false
			})
	}

	ngOnInit(): void {
		if (!this.isProfile) {
			this.profileStateSubscription = this.session.checkProfileComplete.subscribe((val: boolean) => {
				Util.debug('Profile updated')
				// aggiorno gli id della lista richiedendo la lista dopo che si é aggiornato il profilo
				// é necessario perché nel caso in cui, aggingo un nuovo indirizzo, e subito dopo lo modifico, non ho l'id, quindi me ne creerebbe uno nuovo
				let newAddresses = this.getAddresses()
				this.updateLocationsId(newAddresses)
			})
		}
	}

	// PROFILE SECTION
	private startComponentProfile() {
		if (this.latLng) {
			this.changeAddress = true
			this.searchOptions.biasPosition = [this.latLng.longitude, this.latLng.latitude]

			this.loadMap()
				.then(() => {
					this.flyToLocation(this.latLng.longitude, this.latLng.latitude, 12)
					this.isSearchingLocation = false

					let label = this.mainAddress.address_line1 + ', ' + this.mainAddress.zip + ', ' + this.mainAddress.city + ', ' + this.mainAddress.province

					this.marker = this.createMarker(this.latLng.longitude, this.latLng.latitude, label)
					this.marker.addTo(this.map)
				})
				.catch(() => {
					this.session.infoBridgeAvailable = false
					this.isSearchingLocation = false
					this.session.refreshProfile()
				})
		} else {
			Util.debug('no coordinates saved')
			// console.log(this.latLng)
			this.changeAddress = false

			this.loadMap()
				.then(() => {
					this.searchCountry(this.session.user.getCountry()).then((res) => {
						// console.log(res)

						this.flyToLocation(res.geometry!.point[0], res.geometry!.point[1], 6)
						let addrToSeach = this.addressCompose(this.session.user.getMainAddress())

						this.searchInsertedAddress(addrToSeach).then((res: Place[]) => {
							this.isSearchingLocation = false

							let filtered = this.filterPlaces(res)

							if (filtered.length > 0) {
								this.createAddressList(filtered).then((res) => {
									// console.log(this.addressList)

									//CASO1: se trovo un solo indirizzo valido, lo seleziono e faccio vedere sulla mappa e avviso
									if (res.length == 1) {
										this.addLocation(res[0])

										this.infoString = this.translator.instant('MAP_EL.NOTIFICATION.BODY_CASE1')

										//CASO2: se trovo piú indirizzi validi, li mostro nella lista e avviso, dovrá sceglierne uno o modificare la ricerca
									} else {
										this.addressListReady = true
										this.warningAddr = true

										this.infoString = this.translator.instant('MAP_EL.NOTIFICATION.BODY_CASE2')
										this.seachString = this.translator.instant('MAP_EL.ADDRESS_FIND') + res.length
									}
								})
								//CASO3: se non trovo nessun indirizzo valido con i campi salvati in precedenza, avviso e chiedo di fare una nuova ricerca
							} else {
								console.log('no results')
								this.warningAddr = true
								this.infoString = this.translator.instant('MAP_EL.NOTIFICATION.BODY_CASE3')
								this.seachString = this.translator.instant('MAP_EL.ADDRESS_NOT_FOUND')
							}
						})
					})
				})
				.catch(() => {
					this.session.infoBridgeAvailable = false
					this.isSearchingLocation = false
					this.session.refreshProfile()
				})
		}
	}

	private loadMap(): Promise<void> {
		Util.debug('LocationElement - loadMap ')

		const promise = new Promise<void>((resolve, reject) => {
			createMap(this.mapOptions)
				.then((map: maplibregl.Map) => {
					let mappa: maplibregl.Map = map
					this.map = mappa

					this.map.on('load', () => {
						Util.debug('LocationElement - Map loaded')

						this.isLoadingMap = false
						this.map.addControl(new maplibregl.NavigationControl())

						resolve()
					})

					// let ipAddr = new maplibregl.GeolocateControl({ showUserLocation: true })
					// ipAddr.on('geolocate', (e: maplibregl.GeolocateControl) => {
					// 	console.log(e)
					// })
					// this.map.addControl(ipAddr)
				})
				.catch((err) => {
					console.log(err)
					reject()
				})
		})

		return promise
	}

	private searchCountry(country: string): Promise<Place> {
		const promise = new Promise<Place>((resolve, reject) => {
			// console.log(this.searchOptions)
			Geo.searchByText(country, this.searchOptions)
				.then((results: Place[]) => {
					// console.log(results)
					resolve(results[0])
				})
				.catch((err) => {
					console.log(err)
					reject()
				})
		})
		return promise
	}

	//cerco l'adress inserito dall'utente potrebbe essere anche solo trattini, verrá usata all'inizio al primo accesso, poi basta
	private searchInsertedAddress(adress: string): Promise<Place[]> {
		const promise = new Promise<Place[]>((resolve, reject) => {
			Geo.searchByText(adress, this.searchOptions)
				.then((results: Place[]) => {
					// console.log(results)
					resolve(results)
				})
				.catch((err) => {
					console.log(err)
					reject()
				})
		})
		return promise
	}

	public search() {
		Util.debug('LocationElement - search ')
		this.removeAddressListMarker()
		this.isSearchingLocation = true
		this.addressListReady = false
		this.addressList = []
		let value = this.input.nativeElement.value

		if (value.length > 3) {
			Geo.searchByText(value, this.searchOptions).then((results: Place[]) => {
				// console.log(results)

				let streetType = this.filterPlaces(results)

				if (streetType.length > 0) {
					this.createAddressList(streetType).then((res) => {
						this.seachString = this.translator.instant('MAP_EL.ADDRESS_FIND') + res.length
						// console.log(this.addressList)
						this.addressListReady = true
						this.isSearchingLocation = false
					})
				} else {
					this.seachString = this.translator.instant('MAP_EL.ADDRESS_NOT_FOUND')
					this.addressListReady = true
					this.isSearchingLocation = false
				}
			})
		} else {
			this.addressListReady = false
			this.isSearchingLocation = false
		}
	}

	public closeSearch() {
		this.removeAddressListMarker()
		this.changeAddress = true
		this.addressListReady = false
		this.isSearchingLocation = false
		this.addressList = []
		this.waitSave = false
	}

	private filterPlaces(list): Place[] {
		Util.debug('LocationElement - filterPlaces ')
		// console.log(list)
		// il tipo Place non ha categories, bug da segnalare, ma nella risposta c'é ed é un array di stringhe che definisce che tipo di risultato é, e a noi servno solo indirizzi
		let filtered: Place[] = []
		// puó capitare che categories non ci sia in qualche oggetto e qui vado a gestirlo
		for (let el of list) {
			if (el.categories) {
				if (el.categories.includes('StreetType') || el.categories.includes('AddressType')) {
					filtered.push(el)
				}
			}
		}

		return filtered
	}

	private createAddressList(list: Place[]): Promise<location[]> {
		Util.debug('LocationElement - createAddressList ')
		this.addressListReady = false

		const promise = new Promise<location[]>((resolve, reject) => {
			for (let el of list) {
				let loc: location = new location()

				loc.place = el

				let mark = this.createMarker(el.geometry!.point[0], el.geometry!.point[1], el.label!)
				loc.marker = mark

				this.addressList.push(loc)
			}

			resolve(this.addressList)
		})

		return promise
	}

	private createMarker(longitude: number, latitude: number, label: string): maplibregl.Marker {
		let mark: maplibregl.Marker = new maplibregl.Marker({
			color: '#ff2424',
		})

		mark.setLngLat([longitude, latitude])

		let popUpOptions: maplibregl.PopupOptions
		popUpOptions = { closeButton: false }

		mark.setPopup(
			new maplibregl.Popup(popUpOptions)
				.setHTML(label)

				.on('close', () => {
					//il close non funziona bene, viene sempre chiamato anche all'open
					Util.debug('popup was closed')
					if (!this.isProfile) {
						this.deselectMarkers()
					}
				})
				.on('open', () => {
					Util.debug('popup was opened')
				})
		)

		return mark
	}

	private addressCompose(address: Address): string {
		let str: string = ''

		if (address.address_line1) {
			str += address.address_line1
		}
		if (address.zip) {
			str += ', ' + address.zip
		}
		if (address.city) {
			str += ', ' + address.city
		}
		if (address.province) {
			str += ', ' + address.province
		}
		if (address.country) {
			str += ', ' + address.country
		}

		return str
	}

	public addLocation(loc: location) {
		this.infoString = ''

		// nella pagina locations
		if (!this.isProfile) {
			if (loc.label.length == 0 || loc.label.length > 60) {
				Util.debug('wrong label')
				return
			}

			//al primo click abilito la modifica, al secondo salvo
			if (loc.saved) {
				loc.saved = false
				loc.edit = true
				this.oldLabel = loc.address.label.slice()
				return
			}
			let isEdit = loc.haveAddress()
			let msg = ''

			if (isEdit) {
				msg = this.translator.instant('MAP_EL.EDIT_LOCATION')
			} else {
				msg = this.translator.instant('MAP_EL.ADD_LOCATION')
			}

			this.confirmEdit(msg)
				.result.then((res) => {
					// console.log(res)
					if (res) {
						//la lista che abbiamo come clinic admin, arriva dal server dove non abbiamo l'ogetto Place, per cui ses andiamo ad editare dobbiamo prenderci i campi dall'address, mentre nella profile, quandi si cerca un nuovo indirizzo e si aggiunge direttamente con l'oggetto PLace che ha tutto
						let address: Address = new Address()
						let lngLat = loc.marker.getLngLat()

						address.address_label = loc.address.address_label
						address.id = loc.address.id // se é un nuovo indirizzo, non avrá id quindi ne crea uno nuovo

						if (isEdit) {
							address.address_line1 = loc.address.address_line1
							address.city = loc.address.city
							address.province = loc.address.province
							address.zip = loc.address.zip
						} else {
							let addrNum = loc.place.addressNumber ? ', ' + loc.place.addressNumber : ''
							address.address_line1 = loc.place.street + addrNum
							address.city = loc.place.municipality
							address.province = loc.place.subRegion
							address.zip = loc.place.postalCode
						}

						address.country = this.session.user.getCountry()
						address.latitude = lngLat.lat
						address.longitude = lngLat.lng
						address.role = Config.CLINIC
						address.label = loc.label

						// fix per classe Place che non prevede la timezone
						let place: any = loc.place
						address.timezone = place.timeZone.name
						address.gmt_offset_sec = place.timeZone.offset
						// console.log(address)

						this.mapService
							.updateProfileAddress(address)
							.then((res) => {
								this.waitSave = false
								loc.saved = true
								loc.edit = false

								this.changeAddress = true
								this.centerMap()

								let header = this.translator.instant('TOAST.HEADER.SUCCESS')
								let body = this.translator.instant('MESSAGE.OK_SAVED')
								let options = new ToastOptions('success')
								this.toastService.show(header, body, false, options, 'bottom-right')
							})
							.catch((err) => {
								console.log(err)

								let header = this.translator.instant('TOAST.HEADER.ERROR')
								let body = err.error.error
								let options = new ToastOptions('error')
								this.toastService.show(header, body, false, options, 'center')
							})
					} else {
						return
					}
				})
				.catch(() => {
					Util.debug('dismissed')
					return
				})

			//nella profile
		} else {
			let isEdit = this.session.user.mainAddress.isAddressValid()
			let msg = ''

			if (isEdit) {
				msg = this.translator.instant('MAP_EL.EDIT_MAIN_ADDR')
			} else {
				msg = this.translator.instant('MAP_EL.ADD_MAIN_ADDR')
			}

			this.confirmEdit(msg)
				.result.then((res) => {
					if (this.marker) {
						this.marker.remove()
					}
					this.marker = loc.marker
					this.marker.addTo(this.map)

					this.flyToLocation(loc.place.geometry!.point[0], loc.place.geometry!.point[1], 12)
					this.addressListReady = false
					this.addressList = []
					this.input.nativeElement.value = '' //resetto la ricerca

					let addrNum = loc.place.addressNumber ? ', ' + loc.place.addressNumber : ''
					this.mainAddress.address_line1 = loc.place.street + addrNum
					this.mainAddress.city = loc.place.municipality
					this.mainAddress.province = loc.place.subRegion
					this.mainAddress.zip = loc.place.postalCode
					this.mainAddress.id = this.session.user.getMainAddressId()
					this.mainAddress.country = this.session.user.getCountry()
					this.mainAddress.vat = this.session.user.getVat()
					this.mainAddress.latitude = loc.place.geometry!.point[1]
					this.mainAddress.longitude = loc.place.geometry!.point[0]

					let place: any = loc.place
					this.mainAddress.timezone = place.timeZone.name
					this.mainAddress.gmt_offset_sec = place.timeZone.offset

					if (this.session.isOptician()) {
						this.mainAddress.role = Config.SHOP
						this.mainAddress.label = this.mainAddress.organization + ' - ' + Config.SHOP
					} else if (this.session.isSpecialist()) {
						this.mainAddress.role = Config.CLINIC
						this.mainAddress.label = this.session.user.display_name + ' - ' + Config.CLINIC
					} else if (this.session.isClinicAdmin() || this.session.isSuperB()) {
						this.mainAddress.role = this.session.user.getMainAddressRole()
						this.mainAddress.label = this.mainAddress.organization
					}

					// console.log(this.mainAddress)

					this.mapService
						.updateProfileAddress(this.mainAddress)
						.then((res) => {
							// console.log(res)

							this.latLng = this.session.user.getMainAddressCoordinates()
							this.changeAddress = true

							let header = this.translator.instant('TOAST.HEADER.SUCCESS')
							let body = this.translator.instant('MESSAGE.OK_SAVED')
							let options = new ToastOptions('success')
							this.toastService.show(header, body, false, options, 'bottom-right')
						})
						.catch((err) => {
							console.log(err)

							let header = this.translator.instant('TOAST.HEADER.ERROR')
							let body = err.error.error
							let options = new ToastOptions('error')
							this.toastService.show(header, body, false, options, 'center')
						})
				})
				.catch(() => {
					Util.debug('dismissed')
					return
				})
		}
	}

	private flyToLocation(longitude: number, latitude: number, zoom: number) {
		this.map.flyTo({ center: [longitude, latitude], zoom: zoom })
	}

	// MENU LOCATIONS SECTION

	private startComponent() {
		const clinics = this.clinicAddresses.filter((el) => el.role == Config.CLINIC)

		this.selectedLocations = this.createLocations(clinics)

		let numLoc = this.selectedLocations.length

		this.loadMap().then(() => {
			if (numLoc > 0) {
				// console.log(this.selectedLocations)
				this.changeAddress = true

				for (let loc of this.selectedLocations) {
					loc.marker.addTo(this.map)
				}

				this.isSearchingLocation = false

				this.centerMap()
			} else {
				Util.debug('no clinics saved - center map on country')
				this.changeAddress = false

				this.searchCountry(this.session.user.getCountry()).then((res) => {
					// console.log(res)
					this.isSearchingLocation = false

					this.flyToLocation(res.geometry!.point[0], res.geometry!.point[1], 6)
				})
			}
		})
	}

	private centerMap() {
		if (this.selectedLocations.length == 1) {
			this.flyToLocation(this.selectedLocations[0].address.longitude, this.selectedLocations[0].address.latitude, 12)
		} else {
			// creo un array di coordinate di tutte le cliniche
			// non posso usare lng e lat dell'address in quanto le location appena aggiunte non hanno le coordinate salvate nell'address
			let coordinates = this.selectedLocations.map((el) => [el.marker.getLngLat().lng, el.marker.getLngLat().lat])

			// let centroid = coordinates.reduce((x, y) => [x[0] + y[0] / coordinates.length, x[1] + y[1] / coordinates.length], [0, 0])
			// this.map.setCenter(centroid as [number, number])

			//centro la mappa in base ai marker inseriti
			let bounds = coordinates.reduce(
				(bounds, coord) => bounds.extend(coord as [number, number]),
				new maplibregl.LngLatBounds(coordinates[0] as [number, number], coordinates[0] as [number, number])
			)
			// Adatta la mappa ai marker inseriti mantenendo almeno un padding all'esterno sui padding
			this.map.fitBounds(bounds, { padding: 100 })
		}
	}

	private createLocations(addresses: Address[]): location[] {
		let locations: location[] = []

		for (let addr of addresses) {
			let loc: location = new location()
			// mi creao anche la place.label nell'oggetto place anche se non c'é lho perché viene usato nell'html per visualizzare l'indirizzo salvato
			loc.place.label = addr.address_line1 + ', ' + addr.zip + ', ' + addr.city + ', ' + addr.province + ', ' + addr.country
			let mark = this.createMarker(addr.longitude, addr.latitude, loc.place.label)
			loc.marker = mark
			loc.address = addr
			loc.saved = true
			loc.label = addr.label
			loc.isMain = loc.isMainAddress(this.mainAddress)

			locations.push(loc)
		}

		return locations
	}

	// when click on ADD button
	public selectLocation(loc: location) {
		this.waitSave = true

		const lngLat = loc.marker.getLngLat()

		//controllo se l'inidirzzo era giá stato selezionato
		for (let location of this.selectedLocations) {
			let locLngLat = location.marker.getLngLat()

			if (locLngLat.lng == lngLat.lng && locLngLat.lat == lngLat.lat) {
				//same address
				let header = this.translator.instant('TOAST.HEADER.ERROR')
				let body = this.translator.instant('MAP_EL.LOCATION_DUPLICATED')

				let options = new ToastOptions('notification_s')
				this.toastService.show(header, body, false, options, 'center')

				this.waitSave = false

				return
			}
		}

		this.removeAddressListMarker(lngLat)

		this.deselectMarkers().then(() => {
			loc.selected = true
			loc.marker.addTo(this.map)
			this.flyToLocation(loc.place.geometry!.point[0], loc.place.geometry!.point[1], 12)
			loc.marker.togglePopup()

			loc.label = this.translator.instant('MAP_EL.ADDRESS_LABEL') + ' - ' + loc.place.municipality

			let addresses = this.getAddresses()
			loc.address.address_label += Math.max(...addresses.map((addr) => addr.address_label))

			this.addressListReady = false
			this.addressList = []
			this.input.nativeElement.value = ''
			this.selectedLocations.push(loc)

			setTimeout(() => {
				//scroll to bottom
				let element = document.getElementById('responsesClinic')
				element.scrollTop = element.scrollHeight
			}, 500)
		})
	}

	private removeAddressListMarker(lngLat?: maplibregl.LngLat) {
		if (this.addressList.length > 0) {
			//rimuovo eventuali marker di altri indirizzi selezionati, ad esempio ne seleziono uno fra i risultati ma ne aggiungo un'altro senza prima selezionarlo
			for (let addr of this.addressList) {
				if (lngLat) {
					if (addr.selected && addr.marker.getLngLat() !== lngLat) {
						addr.marker.remove()
						addr.selected = false
					}
				} else {
					addr.marker.remove()
					addr.selected = false
				}
			}
		}
	}

	public viewPlace(marker: maplibregl.Marker) {
		let lngLat = marker.getLngLat()

		for (let loc of this.addressList) {
			if (loc.selected && loc.marker.getLngLat() !== lngLat) {
				loc.marker.remove()
				loc.selected = false
			}
		}
		marker.addTo(this.map)

		this.flyToLocation(lngLat.lng, lngLat.lat, 10)

		marker.togglePopup()
	}

	public selectMarker(location: location) {
		if (location.selected) {
			return
		} else {
			this.deselectMarkers().then(() => {
				if (!location.marker.getPopup().isOpen()) {
					location.marker.togglePopup()
				}
				let lngLat = location.marker.getLngLat()
				location.selected = true
				this.flyToLocation(lngLat.lng, lngLat.lat, 10)
			})
		}
	}

	private deselectMarkers(): Promise<boolean> {
		Util.debug('deselectMarkers')
		const promise = new Promise<boolean>((resolve, reject) => {
			for (let loc of this.selectedLocations) {
				if (loc.selected) {
					Util.debug('deselectMarkers - selected')
					loc.selected = false

					if (loc.marker.getPopup().isOpen()) {
						loc.marker.togglePopup()
					}
				}
			}

			resolve(true)
		})

		return promise
	}

	// remove locations on locations page
	public removeLocation(loc: location) {
		if (this.waitSave) {
			const lngLat = loc.marker.getLngLat()

			for (let location of this.selectedLocations) {
				let locLngLat = location.marker.getLngLat()

				if (locLngLat.lng == lngLat.lng && locLngLat.lat == lngLat.lat) {
					location.marker.remove()
					let index = this.selectedLocations.indexOf(location)
					this.selectedLocations.splice(index, 1)
				}
			}

			this.waitSave = false
			this.changeAddress = true
			this.centerMap()
			return
		}

		// if edit the button is discard
		if (loc.edit) {
			loc.edit = false
			loc.saved = true
			loc.label = this.oldLabel
			return
		}

		if (this.canDeleteClinic()) {
			let msg = this.translator.instant('MAP_EL.DELETE_LOCATION')
			this.confirmEdit(msg)
				.result.then((res) => {
					if (res) {
						this.mapService
							.removeAddress(loc.address.id)
							.then((res) => {
								this.selectedLocations = this.selectedLocations.filter((el) => el != loc)
								loc.marker.remove()
							})
							.catch((err) => {
								console.log(err)
							})
					} else {
						return
					}
				})
				.catch(() => {
					Util.debug('dismissed')
					return
				})
		} else {
			let header = this.translator.instant('TOAST.HEADER.ERROR')
			let body = this.translator.instant('MAP_EL.LOCATION_MANDATORY')

			let options = new ToastOptions('notification_s')
			this.toastService.show(header, body, false, options, 'center')
		}
	}

	private canDeleteClinic(): boolean {
		return this.session.user.canDeleteClinicAddress()
	}

	private getAddresses(): Address[] {
		return this.session.user.getAddresses()
	}

	private updateLocationsId(addresses: Address[]) {
		for (let addr of addresses) {
			for (let loc of this.selectedLocations) {
				let lngLat = loc.marker.getLngLat()

				if (lngLat.lng == addr.longitude && lngLat.lat == addr.latitude) {
					loc.address.id = addr.id
				}
			}
		}
	}

	private confirmEdit(message?: string): NgbModalRef {
		var msg = this.translator.instant('PROFILE.MODAL_QUESTION')

		if (message) {
			msg = message
		}

		let confirmModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' }) //backdrop evita che cliccando al di fuori si chiuda automaticamente il modal

		confirmModal.componentInstance.isExit = false
		confirmModal.componentInstance.isQuest = true
		confirmModal.componentInstance.warnText = msg

		return confirmModal
	}

	ngOnDestroy(): void {
		if (!this.isProfile) {
			this.profileStateSubscription.unsubscribe()
		}
	}
}
