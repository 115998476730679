<div class="panel panel-modal panel-default ng-cloak position-relative" style="overflow: hidden; min-height: 20vh">
	<!-- loader -->

	<loader [activateSpinner]="!loaded"></loader>

	<!-- <div class="loader-container" [ngClass]="loaded ? '' : 'show' ">
		<div [hidden]="false" class="lds-ring">
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div> -->
	<!-- close vbutton -->
	<div class="btn-close-container-pos">
		<button type="button" class="btn-close" aria-label="Close" (click)="dismiss()"></button>
	</div>
	<!-- anamnesi element -->
	<div class="container-fluid">
		<div class="row">
			<div class="col-12">
				<div *ngIf="loaded">
					<app-pat-anamnesis
						(completed)="amnAccepted = $event"
						(out-anamnesis)="anamnesis = $event; amnAnswered = true"
						(out-va)="patientVa = $event; checkMandatoryVA($event)"
						[thisVa]="patientVa"
						[thisAnamnesis]="anamnesis"
						[currentStep]="currentStep"
						(stepsFetched)="onStepsFetched($event)"
						[addVaFlag]="true"></app-pat-anamnesis>
				</div>

				<div class="d-flex justify-content-between" *ngIf="loaded">
					<button type="button" [disabled]="isInFirstStep" (click)="goToPreviousStep()" class="btn btn-primary w-auto">{{ 'BUTTONS.BACK' | translate }}</button>
					<button type="button" *ngIf="!isInLastStep" (click)="goToNextStep()" class="btn btn-primary w-auto">{{ 'BUTTONS.NEXT' | translate }}</button>
					<button type="button" *ngIf="isInLastStep" class="btn btn-primary" [disabled]="!vaComplete || (currPatient.origin != 'SelfReg' && !amnAnswered)" (click)="saveAnamnesis()">{{ 'BUTTONS.SAVE' | translate }}</button>
				</div>
			</div>
		</div>
	</div>
</div>
