import { Component, OnInit, Input, ViewChild } from '@angular/core'
import { NgbActiveModal, NgbModal, NgbNav } from '@ng-bootstrap/ng-bootstrap'

import { faCheck, faArrowUpFromBracket } from '@fortawesome/free-solid-svg-icons'

import { TranslateService } from '@ngx-translate/core'
import { ActivatedRoute } from '@angular/router' // 16.12.2021

import { Config } from '../../../config'

import { AnamnPillsSection, Answer, VA, visus } from '../../models/anamnesis.model'

import { SessionService } from '../../service/session.service'
import { DataModelService, DataStatus } from '../../service/data-model.service'
import { VisitListComponent } from '../visits/visitList.component'

import { Patient } from '../../models/patient.model'
import { AiPatient } from '../../models/aiReport.model'
import { Report } from '../../models/report.model'

import { Util } from '../../models/util.model'
import { Category } from '../../models/category.model'
import { CategDiagnosis, Prescription, ICD, CategExam, SrvDiagnosis, followUp } from '../../models/diagnosis.model'
import { SaleInfo, SalePlan } from '../../models/salePlan.model'

import {
	Exam,
	ExamImage,
	ExamType,
	DryEyeExam,
	ExternalExam,
	FundusExam,
	Addition,
	PachyMultExam,
	TonoExam,
	TopoExam,
	LensmeterExam,
	PachyExam,
	RetroExam,
	SubjectiveExam,
	WfExam,
	RIGHT,
	LEFT,
} from '../../models/exam.model'

import { DiagnosisReport } from '../../models/pdf.model'
//import { Console } from 'console'

// 30.12.2021 sposto su config le costanti, usate anche dai report
/*  16.05.2022 commentate qui
  export var descrImgTopo = ["axial map", "elevation map", "tangential map"];
*/
import { Anamnesis } from '../../models/anamnesis.model'
import { User } from 'src/app/models/user.model'
import { ConfirmModal } from 'src/app/elements/confirm/confirm.modal'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'
import { Form, FormControl, FormGroup, Validators } from '@angular/forms'
import { AnamnesisService } from 'src/app/service/anamnesis.service'
import { DateParser } from 'src/app/models/dateParser.model'
import { pipeline } from 'stream'

export interface coupleExam {
	id: number
	exam_type: string
}

/* KO 2 templates, faccio dinamico su uno
@Component({
  selector: 'diagnosis',
  templateUrl: './diagnosis.modal.html',
  styleUrls: ['./categories.modal.scss']
})
*/

@Component({
	selector: 'categories',
	templateUrl: './categories.modal.html',
	styleUrls: ['./categories.modal.scss'],
})
export class CategoriesController implements OnInit {
	@Input() parent: VisitListComponent

	@ViewChild('nav') tabset: NgbNav

	// cat refraction
	@Input() topoR: TopoExam
	@Input() topoL: TopoExam

	@Input() wfR: WfExam
	@Input() wfL: WfExam
	@Input() sbjR: SubjectiveExam
	@Input() sbjL: SubjectiveExam
	@Input() sbjB: SubjectiveExam
	@Input() lsmR: LensmeterExam
	@Input() lsmL: LensmeterExam
	@Input() lsmB: LensmeterExam

	hasLoadedRefraction: boolean

	// cat anterior
	@Input() dryEyeR: DryEyeExam
	@Input() dryEyeL: DryEyeExam

	// categ cornea // uso var del refraction
	//@Input() topoR: TopoExam;
	//@Input() topoL: TopoExam;

	// categ glaucoma
	@Input() pachyR: PachyExam
	@Input() pachyL: PachyExam
	@Input() tonoR: TonoExam
	@Input() tonoL: TonoExam
	@Input() retroR: RetroExam
	@Input() retroL: RetroExam

	// categ fundus
	@Input() fundusR: FundusExam
	@Input() fundusL: FundusExam

	categRef: CategoriesController // 28.12.2021 x element refraction ?

	// 22.12.2021 usiamo il nome della categoria
	//13.05.20 set active tab programmatically
	//activeTab: number;
	activeTab: string

	END_TAB = 'END'

	//session : SessionService;
	location: any
	modal: any
	filter // per le traduzioni, lo passa al pdf (anche filter date ?)
	currentModal
	currentAction: string

	isWizard: boolean // 12.05.2022 ]="currentAction == 'diagnosis'
	isPdfDiagnosis: boolean // 04.01.2023

	doctorId: string // meglio number ?
	patientId: number
	visitId: string
	currentPatient: Patient

	currDevice: string // 15.04.2022 se tutti esami dallo stesso dev, disab alcune categ

	// 19.06.2020 andava bene per html, che ne mostra una alla volta, ma ko per il pdf, a cui servono tutte insieme [ls]
	//currentCategory: Category; // 17.04.2020
	currentCategories: Category[] //  19.06.2020

	currCategName: string //  19.06.2020

	currentCategoryExams: any // Exam[]; // 17.04.2020
	exmList: ExamType[]

	sectionsArray: AnamnPillsSection[]

	freeTextMax: number // 04.02.2022
	//icd10json;
	//icd_list: ICD[];  // 22.04.2022 qui non servono

	// 01.09.2020 non piu' usata [ls]
	// 29.05.2020
	//private tableDtEnabled: boolean;

	// per tutti 12 tipi  =======================

	corneaImagesRight: ExamImage[] // per album su cat. Cornea
	corneaImagesLeft: ExamImage[] // any[];

	// ************
	fundusImagesRight: ExamImage[] // per album su cat. Fundus
	fundusImagesLeft: ExamImage[]

	// *************************************************

	anteriorImagesRight: ExamImage[] // per album su cat. Anterior, external e dryEye insieme, su categ_diagn e su pdf 09.07.2020
	anteriorImagesLeft: ExamImage[]

	externalImagesRight: ExamImage[] // per album su cat. Anterior
	externalImagesLeft: ExamImage[]
	hasExternalImagesLeft: boolean // 25.08.2020 aggiunto per uniformita'
	hasExternalImagesRight: boolean

	// 26.06.2020
	dryEyeImagesRight: ExamImage[] // per secondo album su cat. Anterior
	dryEyeImagesLeft: ExamImage[]
	// 01.07.2020 patch
	hasDryEyeImageLeft: boolean
	hasDryEyeImageRight: boolean

	// *************************************************
	// 22.07.2020
	pachyImagesRight: ExamImage[] // per album su cat. Glaucoma
	pachyImagesLeft: ExamImage[]
	hasPachyImageLeft: boolean
	hasPachyImageRight: boolean

	// 17.08.2020
	wfImagesRight: ExamImage[] // per secondo album su cat. Glaucoma
	wfImagesLeft: ExamImage[]
	hasWfImageLeft: boolean
	hasWfImageRight: boolean

	// 21.08.2020
	retroImagesRight: ExamImage[] // per terzo album su cat. Glaucoma
	retroImagesLeft: ExamImage[]
	hasRetroImageLeft: boolean
	hasRetroImageRight: boolean

	//18.08.2020 tutte insieme, per pdf e diagn
	glcImagesRight: ExamImage[] // su cat. Glc, ordine fisso: retro, wf, pachy
	glcImagesLeft: ExamImage[]

	// *************************************************

	topoExams: TopoExam[] // 20.04.2020 globali per evitare loop angular
	pmExams: PachyMultExam[] // 22.04.2020
	externalExams: ExternalExam[] // 16.06.2020
	dryEyeExams: DryEyeExam[] // 22.06.2020
	pachyExams: PachyExam[] // 21.07.2020
	tonoExams: TonoExam[] // 28.07.2020
	wfExams: WfExam[] // 17.08.2020
	retroExams: RetroExam[] // 21.08.2020
	fundusExams: FundusExam[] // 22.09.2020
	subjectiveExams: SubjectiveExam[] // 05.10.2020
	lsmExams: LensmeterExam[] // 27.10.2020

	additionsR: Addition[] // 22.11.2022 solo per fundus da VX610
	additionsL: Addition[]

	iopc_note: string // 07.10.2022 sul Tono

	// gia' formattate giuste in base a lang di session [ls]
	topoExamsDate: string // 11.06.2020
	dryEyeExamsDate: string // 25.06.2020
	pachyExamsDate: string // 21.07.2020
	tonoExamsDate: string // 28.07.2020
	wfExamsDate: string // 18.08.2020
	retroExamsDate: string
	sbjExamsDate: string
	lsmExamsDate: string // 27.10.2020

	glcExamsDate: string // 28.07.2020 il mix tra pachy e tono
	refractionExamsDate: string // 18.08.2020 il mix tra topo, wf,

	fundusExamsDate: string // 22.09.2020

	// 09.10.2020 per refraction, flag per combinazione di esami
	refrCombData: boolean

	//  per diagnosi  **************

	// 28.05.2020 unico blocco di diagnosi per categoria, non per occhio
	diagnCornea: CategDiagnosis
	diagnAnterior: CategDiagnosis // 30.06.2020
	diagnGlaucoma: CategDiagnosis
	diagnRefraction: CategDiagnosis
	diagnFundus: CategDiagnosis

	// 14.10.2020 refraction ha anche 2 strutture aggiuntive, Final1 e Final2, per ciascun occhio
	rxFinal1R: Prescription
	rxFinal1L: Prescription
	rxFinal2R: Prescription
	rxFinal2L: Prescription

	//  10.11.2020 no, usiamo check unico
	// 28.10.2020 per diagnosi refraction, flag per esporre tabelle dati sui singoli esami
	/*
  lsmEnabled: boolean;
  sbjEnabled: boolean;
  wfEnabled: boolean;
  keratometryEnabled: boolean;
  */

	// 28.08.2022 aggiunto anche per final1
	final1RxEnabled: boolean
	// gravityType

	followUpArray: followUp[]
	followUpForm: FormGroup

	// 28.10.2020 per diagnosi refraction
	final2RxEnabled: boolean

	// 28.05.2020 corrisponde alla tabella categ_exams sul DB
	ctgExamsCornea: CategExam[] // un array con tanti elementi quanti sono gli esami che compongono la categoria
	ctgExamsAnterior: CategExam[] //30.06.2020
	ctgExamsGlaucoma: CategExam[] //25.08.2020
	ctgExamsRefraction: CategExam[]
	ctgExamsFundus: CategExam[]

	//KO, Test per poterli usare anche nel template html [ls]
	/*
  static CAT_REFRACTION = Config.CAT_REFRACTION;  
  */

	// array con gli esami che compongono ciascuna categ, solo coppie di valori id e tipo
	catAnterior: any[]
	catCornea: any[]
	catRefraction: any[]
	catFundus: any[]
	catGlaucoma: any[]
	catAnam: Anamnesis[]

	anamnesis: Anamnesis[]
	//anamnesisGroup: number
	amnAccepted: boolean
	amnAnswered: boolean
	currUser: User
	visitDate: Date

	//08.05.11
	btnSaveDiagnosis: string
	arrImagesR: any[]
	arrImagesL: any[]

	//12.06.20
	totEnabledCategories

	isAnamnesiComplete: boolean
	isRefractionComplete: boolean
	isAnteriorComplete: boolean
	isCorneaComplete: boolean
	isCatGlcComplete: boolean
	isFundusComplete: boolean

	totCatDone = 0
	allCatDone = false

	modalWaitPdfExam // 05.10.2020 cambio nome rispetto ad analogo su visitList [ls]
	isGeneratingPdf: boolean

	reportSent: boolean // 07.10.2021
	myParent: any // 07.10.2021 riferimento al controller chiamante, TODO trovare modo migliore

	saleInfo: SaleInfo // 25.11.2022
	// ********************

	// 28.10.2020
	//descrRxDesign = ["single", "bifocal", "office", "progressive", "other"]; // TODO estenderlo e tradurre ?
	// 09.11.2020 office al posto di trifocal, aggiunto other, usiamo json con translations

	rxDesign = [
		{ code: 1, descr: 'GLASS_DESIGN.1' },
		{ code: 2, descr: 'GLASS_DESIGN.2' },
		{ code: 3, descr: 'GLASS_DESIGN.3' },
		{ code: 4, descr: 'GLASS_DESIGN.4' },
		{ code: 5, descr: 'GLASS_DESIGN.5' },
		{ code: 9, descr: 'GLASS_DESIGN.9' }, // esteso con contact lens
	]

	/* gia' su exam ? 
  // 08.09.2021 costanti x gli occhi 
  RIGHT = "right";           
  LEFT = "left";
  BINO = "bino";
*/

	faCheck = faCheck // 05.04.2022
	faArrowUpFromBracket = faArrowUpFromBracket

	// *********************

	//static $inject = ["session", "$routeParams", "$location", "$uibModal", "$filter"];

	//28-02-23 disable button after send
	disableSend: boolean

	// test
	visusArray: visus[]
	vis: visus
	patientVa: VA
	haveVa: boolean

	constructor(
		public session: SessionService,
		public anamnesisService: AnamnesisService,
		public translator: TranslateService,
		public dataService: DataModelService,
		private activatedRoute: ActivatedRoute,
		public modalService: NgbModal,
		public activeModal: NgbActiveModal, // richiamato su html
		private toastService: AppToastService
	) {
		//this.patientId = $routeParams.patient;
		//this.visitId = $routeParams.visit;
		Util.debug('(Categories) - constructor')
		//test
		this.visusArray = []
		// this.vis = { name: 'Uncorrect', value: { right: '5', left: '0,5', bino: '2.9' } }
		// this.visusArray.push(this.vis)
		// this.vis = { name: 'Old correction', value: { right: '3', left: '1,5', bino: '1.9' } }
		// this.visusArray.push(this.vis)
		// this.vis = { name: 'New correction', value: { right: '1', left: '1,2', bino: '1.1' } }
		// this.visusArray.push(this.vis)

		// console.log(this.visusArray)

		this.patientVa = new VA()
		this.haveVa = false

		this.disableSend = false

		this.visitId = '0'
		this.patientId = 0
		this.doctorId = '0'
		this.visitDate = new Date()
		this.loadUrlParameters()

		this.followUpArray = []

		for (let val of Config.followUpMonths) {
			let followObj = new followUp()
			followObj.val = val
			followObj.description = this.translator.instant('FOLLOW_UP_MON.' + val)
			if (val == 12) {
				followObj.note_mandatory = false
			} else {
				followObj.note_mandatory = true
			}
			this.followUpArray.push(followObj)
		}

		this.followUpForm = new FormGroup({
			followUpMonth: new FormControl('', Validators.required),
			followUpNote: new FormControl(''),
		})

		// gia' caricato nella pg precedente

		this.currentPatient = this.session.getDtPatient()

		// console.log(this.currentPatient)

		this.currentAction = 'view' // default, poi verra' chiamato il setAction()

		this.isPdfDiagnosis = false

		this.resetCategory('*') // inizializza le globali
		this.initGlobali(true) // 07.07.2020

		//this.btnSaveDiagnosis = "Save & continue";  // salva in locale, su sessionStorage
		this.btnSaveDiagnosis = this.translator.instant('BUTTONS.SAVE_CONTINUE')

		this.allCatDone = false
		this.totEnabledCategories = 0
		this.reportSent = false // 07.10.2021

		this.isAnamnesiComplete = false
		this.isRefractionComplete = false
		this.isCorneaComplete = false
		this.isAnteriorComplete = false
		this.isCatGlcComplete = false
		this.isFundusComplete = false

		this.modalWaitPdfExam = null
		this.isGeneratingPdf = false

		this.refrCombData = false

		//this.activeTab = 9;   // 01.09.2021  NO
		// 26.05.2020 spostato sotto, dopo aver costruito le categ

		// 22.04.2022 la var icd_list non serve, ci basta assicurarci che sia stata caricata
		/*
    // 04.02.2022
    if(this.session.userIcdsEnabled()){

      this.icd_list = this.session.getIcdList();  // caricata alla login
      if(this.icd_list != null)
        Util.debug("(categ_Icds) tot: "+this.icd_list.length);
      else {
        Util.debug("(categ_Icds) ko list!");
        // la richiede ??
      }
    }
    */

		// 27.05.2022 sposto dentro loadUrlParameters
		/*
    // 20.01.2022 per gestire i reload della pg
    if(!this.session.isLevel1() && this.doctorId > 0){
      // 27.05.2022 dovrebbe averla gia' caricata dalla visits list... [ls]
			this.session.loadDoctorKey(this.doctorId);
    }
		*/

		this.categRef = this // 28.12.2021

		this.currUser = this.session.user
		this.amnAccepted = false
		this.amnAnswered = false
		//this.anamnesisGroup = this.currUser.getAnamnesisGroup()  // chi lo usa?
		this.catAnam = this.currentPatient.anamnesis
	}

	loadUrlParameters() {
		if (this.activatedRoute != null) {
			this.activatedRoute.queryParams.subscribe((params) => {
				let myParam = params['visit']
				if (myParam != null && parseInt(myParam) > 0)
					// 24.03.2022 aggiunto test
					this.visitId = myParam

				myParam = params['patient']
				if (myParam != null && parseInt(myParam) > 0) this.patientId = myParam

				myParam = params['doctor'] // 20.01.2022
				if (myParam != null && parseInt(myParam) > 0) {
					this.doctorId = myParam

					Util.debug('(categories) - loadUrlParams - doc: ' + this.doctorId + ' pat: ' + this.patientId)

					// 27.05.2022 spostato qui, dovrei avere docId valido
					// 20.01.2022 per gestire i reload della pg
					if (!this.session.isLevel1()) {
						// 27.05.2022 dovrebbe averla gia' caricata dalla visits list... [ls]
						this.session.loadDoctorKey(this.doctorId)
					}
				}
			})
		}
	}

	ngOnInit(): void {
		Util.debug('(categories) - onInit, active: ' + this.activeTab)

		//this.selectActiveTab();    // troppo presto, tabset nullo

		// qui e' presto ?
		let rc = this.session.checkIcdsAreLoaded() // senza param -> il gruppo dell'utente loggato
		Util.debug('(categ_Icds) loaded ? ' + rc)

		this.loadCategory(this.activeTab) // 22.12.2021 carico il contenuto della prima categ

		// 11.07.2023 fix test su array valido
		if (this.currentPatient.anamnesis != null && this.currentPatient.anamnesis.length > 0) {
			this.anamnesisService.getAnamnesisPills(this.currentPatient.id, this.session.getLanguage(), DateParser.formatSqlDateLong(this.visitDate)).then((res) => {
				res.anamn_sections.forEach(section => {
					const groupedArray = section.pills.reduce((acc, current) => {
						const existingItem = acc.find(item => item.question_id === current.question_id && current.display_descr.includes("%1"));
						if (existingItem) {
							const descr = current.display_descr.replace('%1 ', '').split('|')[1];
							existingItem.display_descr = existingItem.display_descr.replace('%1 ', '').replace("|", "") + `, ${descr}`;
						} else {
							current.display_descr = current.display_descr != null ? current.display_descr.replace('%1 ', '').replace("|", "") : '';
						  	acc.push(current);
						}
						return acc;
					  }, []);
					  section.pills = groupedArray;
					})
				this.sectionsArray = res.anamn_sections
			})
			this.anamnesisService
				.getPatientVA(this.currentPatient.id, DateParser.formatSqlDateLong(this.visitDate))
				.then((va) => {
					Util.debug('(categories) VA found ')

					this.patientVa = va

					// console.log(va)

					if (this.patientVa && this.patientVa.creation_date) {
						this.haveVa = true

						this.vis = {
							name: this.translator.instant('VA.UNCORRECT'),
							value: { right: this.patientVa.va_right, left: this.patientVa.va_left, bino: this.patientVa.va_bino },
						}
						this.visusArray.push(this.vis)
						this.vis = {
							name: this.translator.instant('VA.OLD_CORRECTION'),
							value: { right: this.patientVa.old_right, left: this.patientVa.old_left, bino: this.patientVa.old_bino },
						}
						this.visusArray.push(this.vis)
						this.vis = {
							name: this.translator.instant('VA.NEW_CORRECTION'),
							value: { right: this.patientVa.new_right, left: this.patientVa.new_left, bino: this.patientVa.new_bino },
						}
						this.visusArray.push(this.vis)

						// console.log(this.visusArray)
					} else {
						Util.debug('(categories) no VA for the patient ')
						this.haveVa = false
					}
				})
				.catch((err) => {
					console.log(err)
					this.patientVa = new VA()
				})
				let firstCat = this.getFirstActiveCategory()
				this.setActiveTab(firstCat)
		} else {
			this.visitDate = null
		}
	}

	public followUpSelected() {
		let followUpMonth: followUp = this.followUpForm.get('followUpMonth').value

		if (this.followUpForm.get('followUpMonth').valid) {
			if (followUpMonth.note_mandatory) {
				this.followUpForm.get('followUpNote').setValidators([Validators.required, Validators.minLength(20), Validators.maxLength(200)])
				this.followUpForm.get('followUpNote').updateValueAndValidity()
			} else {
				// this.followUpForm.get('followUpNote').setValidators([Validators.nullValidator])

				this.followUpForm.get('followUpNote').clearValidators()
				this.followUpForm.get('followUpNote').updateValueAndValidity()
			}
		}
		// console.log(this.followUpForm)
	}

	checkAnamnesisResp() {
		let num = 0

		// console.log(this.currentPatient.anamnesis)
		let questionsNum = this.currentPatient.anamnesis.length

		for (let i = 0; i < this.currentPatient.anamnesis.length; i++) {
			const element = this.currentPatient.anamnesis[i]

			let ansLen = element.answers

			//controllo se nell'oggetto che mi ritorna é giá stato risposto ad alcune domande, se si imposto true
			for (let a = 0; a < ansLen.length; a++) {
				const ans = ansLen[a]

				if (ans.checked) {
					num++
				}
			}

			if (num == questionsNum) {
				this.amnAccepted = true
			}
		}
	}

	// 19.06.2020 estesa con nome categoria, per il pdf deve tenerne piu' di una alla volta [ls]
	// da richiamare nel costruttore e al cambio tab
	resetCategory(categName?) {
		if (categName == '*') {
			this.currentCategories = [] // svuota tutto
			//this.currentCategory = null; // svuoto
		} else {
			this.currentCategories[categName] = null // annulla solo quella indicata
		}

		Util.debug('(resetCategory) name: ' + categName)

		this.currentCategoryExams = []
		this.currCategName = ''
		//this.hasImagesL = false;
		//this.hasImagesR = false

		// 19.06.2020
		if (categName == Config.CAT_CORNEA || categName == '*') {
			this.corneaImagesRight = null
			this.corneaImagesLeft = null
			this.topoExams = null
			this.pmExams = null

			this.topoExamsDate = '' // 30.07.2020
		}

		if (categName == Config.CAT_ANTERIOR || categName == '*') {
			this.externalExams = null
			this.dryEyeExams = null

			this.externalImagesRight = null
			this.externalImagesLeft = null
			this.hasExternalImagesLeft = false // 25.08.2020
			this.hasExternalImagesRight = false

			this.dryEyeImagesRight = null
			this.dryEyeImagesLeft = null
			this.hasDryEyeImageLeft = false
			this.hasDryEyeImageRight = false

			this.anteriorImagesRight = null // 09.07.2020
			this.anteriorImagesLeft = null

			this.dryEyeExamsDate = '' // 30.07.2020
		}

		// 30.07.2020
		if (categName == Config.CAT_GLC || categName == '*') {
			this.pachyImagesRight = null
			this.pachyImagesLeft = null
			this.hasPachyImageLeft = false
			this.hasPachyImageRight = false

			this.wfImagesRight = null
			this.wfImagesLeft = null
			this.hasWfImageLeft = false
			this.hasWfImageRight = false

			this.retroImagesRight = null // 21.08.2020
			this.retroImagesLeft = null
			this.hasRetroImageLeft = false
			this.hasRetroImageRight = false

			this.glcImagesRight = null // 18.08.2020
			this.glcImagesLeft = null

			this.pachyExams = null
			this.tonoExams = null // senza foto, solo dati
			this.retroExams = null

			this.wfExams = null // 08.09.2021 xche' anche questi qui ?
			this.subjectiveExams = null // 08.09.2021 xche' anche questi qui?

			this.pachyExamsDate = ''
			this.tonoExamsDate = ''
			this.retroExamsDate = ''

			this.glcExamsDate = ''
		}

		// 18.08.2020
		if (categName == Config.CAT_REFRACTION || categName == '*') {
			this.topoExams = null
			this.wfExams = null
			this.subjectiveExams = null
			this.lsmExams = null // 08.09.2021 fix, mancava

			this.topoExamsDate = ''
			this.wfExamsDate = ''
			this.sbjExamsDate = ''
			this.lsmExamsDate = ''

			this.refractionExamsDate = ''
			this.refrCombData = false

			/*  10.11.2020 no, usiamo check unico
      this.lsmEnabled = false;  // poi in base alla presenza o meno dei dati
      this.sbjEnabled = false;
      this.wfEnabled = false;
      this.keratometryEnabled = false;
      */

			this.final1RxEnabled = true // 29.08.2022
			this.final2RxEnabled = false // parto con una
		}

		// 22.09.2020
		if (categName == Config.CAT_FUNDUS || categName == '*') {
			this.fundusImagesRight = null
			this.fundusImagesLeft = null
			this.fundusExams = null
			this.additionsR = null
			this.additionsL = null

			// serve ? anche no, se esiste l'esame, ha immagini.
			//this.hasFundusImageLeft = false;
			//this.hasFundusImageRight = false;

			this.fundusExamsDate = ''
		}

		this.arrImagesR = []
		this.arrImagesL = []

		//this.hasMiddleInfo = false; // 12.06.2020

		if (this.isWizard) {
			this.resetDiagnosisObj(categName)
		}
	}

	// 18.05.2022 portata fuori dal resetCateg sopra
	private resetDiagnosisObj(categName: string) {
		Util.debug('(resetDiagnObj) for cat: ' + categName)

		if (categName == Config.CAT_CORNEA || categName == '*') {
			this.diagnCornea = new CategDiagnosis()
			this.diagnCornea.category = Config.CAT_CORNEA
			this.ctgExamsCornea = []
			//this.tableDtEnabled = true;
		}

		if (categName == Config.CAT_ANTERIOR || categName == '*') {
			this.diagnAnterior = new CategDiagnosis()
			this.diagnAnterior.category = Config.CAT_ANTERIOR
			this.ctgExamsAnterior = []
			//this.tableDtEnabled = true;

			//Util.debug("(resetDiagnObj) A "+categName+" icds: "+this.diagnAnterior.getMyIcds());
		}

		if (categName == Config.CAT_GLC || categName == '*') {
			this.diagnGlaucoma = new CategDiagnosis()
			this.diagnGlaucoma.category = Config.CAT_GLC
			this.ctgExamsGlaucoma = []
			//this.tableDtEnabled = true;
		}

		if (categName == Config.CAT_FUNDUS || categName == '*') {
			this.diagnFundus = new CategDiagnosis()
			this.diagnFundus.category = Config.CAT_FUNDUS
			this.ctgExamsFundus = []
		}

		if (categName == Config.CAT_REFRACTION || categName == '*') {
			this.diagnRefraction = new CategDiagnosis()
			this.diagnRefraction.category = Config.CAT_REFRACTION
			this.ctgExamsRefraction = []

			this.diagnRefraction.dtEnabled = true // 29.08.2022 dati sempre presenti, tolto checkbox

			/* 14.10.2020 non qui, vd initRxFinal
			this.rxFinal1R = new Prescription();
			this.rxFinal1L= new Prescription();
			*/
		}
	}

	// 22.12.2021 passiamo da number a string
	setActiveTab(n: string) {
		//if(n>=0) // 27.05.2020    07.10.2021 anche lo zero, e' il primo
		this.activeTab = n
	}

	setModal(myModal) {
		this.currentModal = myModal
	}

	setVisit(visId) {
		this.visitId = visId
	}

	// 25.08.2022 required per Vistel AI
	// 15.04.2022 per disabilitare eventuali categs, se mono-device
	setDevice(devModel) {
		this.currDevice = devModel
	}

	// 21.12.2021
	setPatientId(patId) {
		this.patientId = patId
	}

	setAction(action) {
		this.currentAction = action

		// 25.08.2020 valutare se giusto qui [ls]
		if (action == 'diagnosis') {
			this.isWizard = true // 12.05.2022 serve poi passarlo agli album
			this.checkPatientStorage()
		}

		// 04.01.2023 serviranno le funzioni x il report pdf di diagnosi, anche se non e' wizard
		if (action == 'printHgReport') {
			this.isPdfDiagnosis = true
		}
	}

	setVisitDate(date: Date) {
		this.visitDate = date
	}

	// 07.10.2021 patch x comunicare...
	setParent(rif) {
		this.myParent = rif
	}

	// richiamata da VisitList
	// per i livelli 1, sono tutti quelli della visita,
	// per i livelli 2 e' da scelta dinamica
	setExamChoice(myExamList: ExamType[]) {
		if (myExamList != null) {
			this.exmList = myExamList
			Util.debug('(categories) got ' + this.exmList.length + ' exams.')

			// suddivido in categorie
			this.initGlobali(true)

			// per livelli1 qui, dalla lista esami della visita selezionata
			// per refertatori fare in base alla selection (TODO)
			this.buildCategories() // locale, con soli gli id degli esami

			// 03.06.2020 tolta da qui e spostata sulla chiamante, per compatibilita' con report
			// svuota precedenti array e prepara per nuova visualizzazione
			//this.session.initCategories();

			/*
      // 25.08.2020 no, leghiamo solo al paziente e alle categorie [ls]      
      // 24.08.2020 controllo sessionStorage: 
      //se ci sono gli stessi esami e/o una diagnosi fatta, 
      // chiedere se si vuole pre-caricare.
      // TODO: Capire se va fatto qui o su visitList            
      [...]  
      */
		}
	}

	// 27.08.2020 aggiunto patientId alle key delle diagnosi, non serve piu' fare questo ?
	// eventualmente solo controllo della size ? max 5 MB

	// 25.08.2020 controllo sessionStorage: se e' sullo stesso paziente, ok,
	// altrimenti cancello le diagnosi salvate
	checkPatientStorage() {
		if (!window.sessionStorage)
			// non abilitato
			return

		let toSave = false
		let key = 'patientId'
		let oldPat = sessionStorage.getItem(key)
		if (oldPat != null) {
			if (oldPat != '' + this.patientId) {
				Util.debug('(checkPatientStorage) patient changed! prev:' + oldPat + ' curr:' + this.patientId)

				// 27.08.2020 TODO, valutare
				//this.session.clearSessionStorage(oldPat);

				toSave = true
			} else {
				Util.debug('(checkPatientStorage) same patient:' + oldPat)
				toSave = false
			}
		} else {
			Util.debug('(checkPatientStorage) no previous pat saved.')
			toSave = true
		}

		if (toSave) {
			// salvo pat. attuale su sessionStorage
			sessionStorage.setItem(key, '' + this.patientId)
		}
	}

	// 07.07.2020 per il pdf su multi-categ, richiamo questa, non la setExamChoice
	addExamChoice(myExamList: ExamType[]) {
		if (this.exmList == null) {
			this.exmList = []
		}

		if (myExamList != null) {
			for (let i = 0; i < myExamList.length; i++) {
				this.exmList.push(myExamList[i])
				//Util.debug("(categories) added "+this.exmList.length+" exams.");

				// 16.11.2020 fix, qui serve per il pdf
				if (myExamList[i].exam_type == Config.EXM_SBJ) {
					this.refrCombData = true
				}
			}
		}

		// suddivido in categorie, usa gia' il push
		this.buildCategories() // locale, con soli gli id degli esami
	}

	// 17.04.2020
	//getVisitDescr(){
	getPanelTitle() {
		let ret = ''

		// 14.05.2020 distingue se wizard per diagnosi o di view
		if (this.isWizard) {
			ret = this.translator.instant('MISC.REP_WIZARD') // "Diagnosis Wizard";   // 17.06.2022
		} else {
			ret = this.translator.instant('MISC.VISIT')

			if (this.visitId) {
				// solo per la view
				// se utente livello uno: esporre anche il nome e data ?
				let currVisit = this.session.getDtVisit(this.visitId)
				if (currVisit != null) {
					ret += ': ' + currVisit.name

					if (currVisit.device != '') {
						// 22.04.2022
						ret += ' - ' + currVisit.device
					}
				} else {
					ret += 'id: ' + this.visitId
				}
			}
		}

		return ret
	}

	// 23.04.2020 per le categorie attive, verifica se ci sono gia' o le richiede ora
	getActiveCategories() {
		let currentActiveCats = []
		let totEnabled = 0

		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			let catName = Config.CATEG_NAMES[i]

			if (this.isCatEnabled(catName)) {
				currentActiveCats[catName] = true
				totEnabled++
			} else {
				currentActiveCats[catName] = false
			}
		}

		Util.debug('(getActiveCategories) tot ' + totEnabled)

		return currentActiveCats
	}

	// 23.04.2020 la prima nell'ordine in cui sono su Config.CATEG_NAMES
	private NUMgetFirstActiveCategory(): number {
		let ret = ''
		let catName = ''
		let indexCateg = 0 // 14.05.2020
		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			catName = Config.CATEG_NAMES[i]
			if (this.isCatEnabled(catName)) {
				ret = catName
				indexCateg = i
				break
			}
		}

		//return ret;
		Util.debug('(getFirstActiveCategory) ret:' + indexCateg)
		return indexCateg
		//return catName;
	}

	// 22.12.2021 versione string
	// la prima nell'ordine in cui sono su Config.CATEG_NAMES
	getFirstActiveCategory(): string {
		let ret = 'END'

		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			let catName = Config.CATEG_NAMES[i]
			if (this.isCatEnabled(catName)) {
				ret = catName
				break
			}
		}
		Util.debug('(getFirstActiveCategory) ret:' + ret)

		return ret
	}

	// 08.07.2020 la prima non ancora esitata (nell'ordine in cui sono su Config.CATEG_NAMES)
	private NUMgetNextCategoryToReview() {
		let indexCateg = 9 // nessuna

		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			let catName = Config.CATEG_NAMES[i]
			if (this.isCatEnabled(catName)) {
				Util.debug('(getNextCategoryToReview) ' + i + ' enabled ' + catName)

				// gia' vista ma non esitata
				let flag1 = this.currentCategories[catName] != null && !this.currentCategories[catName].hasReportDone

				// non ancora vista/caricata
				let flag2 = this.currentCategories[catName] == null

				if (flag1 || flag2) {
					indexCateg = i
					break
				}
			}
		}
		Util.debug('(getNextCategoryToReview) ret:' + indexCateg)
		return indexCateg
	}

	// 22.12.2021 versione string
	// la prima non ancora esitata (nell'ordine in cui sono su Config.CATEG_NAMES)
	getNextCategoryToReview() {
		let categName = 'END' // nessuna
		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			let catName = Config.CATEG_NAMES[i]
			if (this.isCatEnabled(catName)) {
				//Util.debug("(getNextCategoryToReview) "+i+" enabled "+catName);

				// gia' vista ma non esitata
				let flag1 = this.currentCategories[catName] != null && !this.currentCategories[catName].hasReportDone

				// non ancora vista/caricata
				let flag2 = this.currentCategories[catName] == null

				Util.debug('(getNextCategoryToReview) ' + i + ' enabled ' + catName + ' flag1:' + flag1 + ' flag2: ' + flag2)

				if (flag1 || flag2) {
					categName = catName
					break
				}
			}
		}
		Util.debug('(getNextCategoryToReview) ret:' + categName)
		return categName
	}

	// 07.07.2020
	private initGlobali(force?) {
		if (force) {
			this.catAnterior = []
			this.catCornea = []
			// this.catAnam = []
			this.catRefraction = []
			this.catFundus = []
			this.catGlaucoma = []

			this.hasLoadedRefraction = false // 28.12.2021
		} else {
			// solo se mancano

			if (this.catAnterior == null) this.catAnterior = []
			if (this.catCornea == null) this.catCornea = []
			// if (this.catAnam == null) this.catAnam = []

			if (this.catRefraction == null) {
				this.catRefraction = []
				this.hasLoadedRefraction = false // 28.12.2021
			}

			if (this.catFundus == null) this.catFundus = []
			if (this.catGlaucoma == null) this.catGlaucoma = []
		}
	}

	// 14.04.2020
	// basato sul doc del 2020-03-05_exam_selection
	private buildCategories() {
		if (this.exmList == null) {
			return
		}

		let couple: coupleExam // 31.01.2022

		for (let i = 0; i < this.exmList.length; i++) {
			let currExam = this.exmList[i]
			couple = {
				id: currExam.id,
				exam_type: currExam.exam_type,
			}

			this.initGlobali() // 07.07.2020

			// tutti 12
			switch (
				currExam.exam_type // vd valori su DB, tabella exam_types
			) {
				case Config.EXM_DRYEYE:
					this.catAnterior.push(couple)
					break

				case Config.EXM_EXT:
					this.catAnterior.push(couple)
					break

				case Config.EXM_FUNDUS:
					this.catFundus.push(couple)
					break

				case Config.EXM_LM:
					this.catRefraction.push(couple)
					break

				case Config.EXM_PACHY:
					this.catGlaucoma.push(couple)
					break

				case Config.EXM_PACHYMULT:
					this.catCornea.push(couple)
					break

				case 'pupil':
					// nessuna categoria, non viene visualizzato
					break

				case Config.EXM_RETRO:
					this.catGlaucoma.push(couple)
					break

				case Config.EXM_SBJ:
					this.catRefraction.push(couple)
					break

				case Config.EXM_TONO:
					this.catGlaucoma.push(couple)
					break

				case Config.EXM_TOPO:
					this.catRefraction.push(couple) // parte di dati
					this.catCornea.push(couple)
					break

				case Config.EXM_WF:
					this.catRefraction.push(couple) // per dati

					// 15.09.2022 patch, vedo dopo, solo se ha immagini
					//this.catGlaucoma.push(couple);  // per l'immagine, non sempre c'e'
					//if(currExam.hasImages){
					//this.catGlaucoma.push(couple);  // per l'immagine, non sempre c'e'
					//this.disableCateg(Config.CAT_GLC);
					//}

					break

				default:
					Util.debug('(buildCategories) not managed yet ' + currExam.exam_type)
			}
		}

		Util.debug('(buildCategories) device: ' + this.currDevice)

		// 15.04.2022 patch
		//if(this.currDevice != null && this.currDevice == "ER"){
		if (this.currDevice != null && this.currDevice.trim() == 'ER') {
			// 09.05.2022
			this.disableCateg(Config.CAT_CORNEA)
			this.disableCateg(Config.CAT_GLC)
		}

		// 07.07.2020 conto le categ abilitate
		this.totEnabledCategories = this.countEnabledCategories()

		// 26.05.2020 spostato qui dal costruttore
		Util.debug('(buildCategories) setting active tab...')
		let firstCat = this.getFirstActiveCategory()
		this.setActiveTab(firstCat)

		//this.selectActiveTab(); // 22.12.2021  troppo presto, tabset nullo
	}

	// 14.04.2020
	getCategoryList(catName) {
		let myList

		switch (catName) {
			case Config.CAT_ANAMNESIS:
				myList = this.catAnam
				break

			case Config.CAT_REFRACTION:
				myList = this.catRefraction
				break

			case Config.CAT_ANTERIOR:
				myList = this.catAnterior
				break

			case Config.CAT_CORNEA:
				myList = this.catCornea
				break

			case Config.CAT_FUNDUS:
				myList = this.catFundus
				break

			case Config.CAT_GLC:
				myList = this.catGlaucoma
				break

			default:
				Util.debug('(getCategoryList) not managed ' + catName)
		}

		//Util.debug("(getCategoryList) ret: "+myList);
		return myList
	}

	isCatEnabled(catName) {
		let flag = false
		switch (catName) {
			case Config.CAT_ANAMNESIS:
				flag = this.catAnam && this.catAnam.length > 0
				break

			case Config.CAT_REFRACTION:
				flag = this.catRefraction && this.catRefraction.length > 0
				break

			case Config.CAT_ANTERIOR:
				flag = this.catAnterior && this.catAnterior.length > 0
				break

			case Config.CAT_CORNEA:
				flag = this.catCornea && this.catCornea.length > 0
				break

			case Config.CAT_FUNDUS:
				flag = this.catFundus && this.catFundus.length > 0
				break

			case Config.CAT_GLC:
				flag = this.catGlaucoma && this.catGlaucoma.length > 0

				// 12.10.2020: FIXME se solo esame WF senza immagini -> categ vuota
				//var hasImg = (this.currentCategories[Config.CAT_GLC].hasImagesR || this.currentCategories[Config.CAT_GLC].hasImagesL);// ko, troppo presto
				//Util.debug("(isCatEnabled) GLC len "+this.catGlaucoma.length+" has imgs ? "+hasImg);
				//Util.debug(this.catGlaucoma); // solo id e tipo

				break

			default:
				Util.debug('(isCatEnabled) not managed ' + catName)
		}

		// per test
		//Util.debug("(isCatEnabled) cat "+catName+" "+flag);

		return flag
	}

	// 07.07.2020
	private countEnabledCategories() {
		let totEnabled = 0
		let maxCateg = 5 // Config.CATEG_NAMES.length(); KO ?
		for (let i = 0; i < maxCateg; i++) {
			if (this.isCatEnabled(Config.CATEG_NAMES[i])) {
				totEnabled++
			}
		}
		return totEnabled
	}

	// 21.03.2022 fix (bug 136)
	// per ora succede solo sulla Cat/Glc con ER che ha il WF senza immagini
	private disableCateg(catName) {
		switch (catName) {
			/*
      case Config.CAT_REFRACTION:
      	this.catRefraction = null;
        break;

      case Config.CAT_ANTERIOR:
        this.catAnterior =null;
        break;

      case Config.CAT_FUNDUS:
        this.catFundus = null;
        break;
			*/
			case Config.CAT_ANAMNESIS:
				this.catAnam = null
				Util.debug('(disableCateg) disabled empty Anamnesis')
				break

			case Config.CAT_CORNEA:
				this.catCornea = null
				Util.debug('(disableCateg) disabled empty Cornea for ER')
				break

			case Config.CAT_GLC:
				this.catGlaucoma = null
				Util.debug('(disableCateg) disabled empty GLC for ER')
				break

			default:
				Util.debug('(disableCateg) ignored ' + catName)
		}

		// aggiorna
		this.totEnabledCategories = this.countEnabledCategories()
	}

	// 14.04.2020
	// modal che mostra tutti e soli gli esami su questa category
	//
	loadCategory(catName) {
		Util.debug('(loadCategory) start - ' + catName) // 22.12.2021

		// 10.01.2023 patch  se torno indietro per controllare, non cambio nulla, poi vado alla fine "a mano"
		if (catName == this.END_TAB) {
			if (this.totCatDone) {
				Util.debug('(loadCategory) end tab, go on...')
				this.btnSaveDiagnosis = this.translator.instant('BUTTONS.GENERATE_REPORT')
			}
			return
		}

		this.currCategName = catName // 01.02.2022 serve qui x diagnosi

		// console.log(this.currCategName)

		let myList = this.getCategoryList(catName)
		if (myList == null || myList.length == 0) {
			//alert("Empty exam list in category "+catName);
			Util.debug('(loadCategory) Empty exam list in category ' + catName)
			return
		}

		// 19.08.2021 se ritorno su una categ dopo aver finito, per rivedere, forzo bottone (fix bug 86)
		//  "Save & continue";
		this.btnSaveDiagnosis = this.translator.instant('BUTTONS.SAVE_CONTINUE')

		Util.debug('(loadCategory) cat ' + catName + ' tot exams: ' + myList.length)

		if (this.dataService.hasLoadedCategory(catName)) {
			// 19.06.2020 uso array di categorie
			Util.debug("(loadCategory) gia' caricati prima " + catName) // 30.06.2020
			// gia' caricati prima, basta mostrarli
			this.loadCurrentCateg(catName)
		} else {
			// avvia la richiesta di download degli esami per questa categoria
			//Util.debug("(loadCategory) svuoto e avvio nuova richiesta per "+catName);  // 30.06.2020
			Util.debug('(loadCategory) reset and reload categ:' + catName) // 01.09.2021
			//this.resetCategory();  // svuoto
			this.resetCategory(catName) // 19.06.2020 svuoto solo per questa categ

			if (this.isWizard) {
				// 24.08.2020 pre-carico diagnosi salvata su sessionStorage
				this.loadPrevDiagnosi(catName)
			}

			// la myList contiene coppie id ed exam_type, quello che serve per la get singolo esame
			if (catName != Config.CAT_ANAMNESIS) {
				this.session
					.loadCategoryExams(myList, catName)
					.then(() => {
						this.loadCurrentCateg(catName)

						// 28.07.2020 FIX, valorizzato hasMiddleInfo su dataModel
						Util.debug('(loadCategory) middle data for ' + catName + ': ' + this.currentCategories[catName].hasMiddleInfo)
						//this.initCategoryDataTime(catName); // 30.07.2020 troppo presto qui ?

						// 01.09.2020 se non ci sono i dati, nel form di diagnosi disabilito la tabella
						// nota: c'e' refresh lento, prima compare checked e poi cambia!
						if (this.isWizard && !this.currentCategories[catName].hasMiddleInfo) {
							this.enableDtOnDiagnosi(catName, false)
						}

						/*
					// 14.10.2020
					if(this.isWizard && catName == Config.CAT_REFRACTION){
						this.initRxFinal();  // inizializza con valori di SBJ o LSM, se presenti
					}
					*/
					})
					.catch((myErr) => {
						if (!this.session.isExpired(myErr)) {
							// 11.02.2022 uniformata gestione errori

							// 20.09.2022 meglio dare un alert
							//let msg = this.session.parseErrorMessage(myErr, 'trace');
							let msg = this.session.parseErrorMessage(myErr, 'alert')
							Util.debug('(loadCategory) ' + msg)
							Util.debug(myErr)
							alert(msg)

							// 20.09.2022 cosi' blocca lo spinner
							this.dataService.setCategoryStatus(catName, DataStatus.LOAD_FAILED)

							// 20.05.2022 se ko key, chiudo il modal, ko decrypt...
							if (msg == 'ko key') {
								// migliorare test, usare un codice!
								this.dismissModal()
							}
						} else {
							// expired
							// 02.05.2022 closes the modal
							this.dismissModal()
						}
					})
			} else {
				Util.debug('(loadCategory) - Anamnesi')
				this.loadCurrentCateg(catName)
			}
		}
	}

	// 22.12.2021 portata fuori
	private loadCurrentCateg(catName) {
		let currentCategory = this.dataService.getCategory(catName) // qui c'e' anche il nome
		if (currentCategory != null) {
			this.currentCategories[catName] = currentCategory
			this.currentCategoryExams = currentCategory.examList
			this.currCategName = catName

			// 03.09.2020 se non ci sono i dati, nel form di diagnosi disabilito la tabella
			if (this.isWizard && !this.currentCategories[catName].hasMiddleInfo) {
				this.enableDtOnDiagnosi(catName, false)
			}
		} else {
			Util.debug('(loadCurrentCateg) got null per ' + catName) // 30.06.2020
		}

		// 27.12.2021 init inputs for html
		// quello che su angularjs era fatto con ng-init su pg html
		this.initCategsInput(catName)
	}

	// 27.12.2021
	private initCategsInput(catName: string) {
		switch (catName) {
			case Config.CAT_REFRACTION:
				//se non gia' fatto ?!
				this.getTopoExams()
				this.getWfExams()
				this.getSubjectiveExams()
				this.getLsmExams()

				this.topoR = this.getCategoryExam(Config.CAT_REFRACTION, 'topo', RIGHT, true)
				this.topoL = this.getCategoryExam(Config.CAT_REFRACTION, 'topo', LEFT, true)
				this.wfR = this.getCategoryExam(Config.CAT_REFRACTION, 'wf', RIGHT, true)
				this.wfL = this.getCategoryExam(Config.CAT_REFRACTION, 'wf', LEFT, true)
				this.sbjR = this.getCategoryExam(Config.CAT_REFRACTION, 'subjective', RIGHT, true)
				this.sbjL = this.getCategoryExam(Config.CAT_REFRACTION, 'subjective', LEFT, true)
				this.sbjB = this.getCategoryExam(Config.CAT_REFRACTION, 'subjective', 'bino', true)
				this.lsmR = this.getCategoryExam(Config.CAT_REFRACTION, 'lensmeter', RIGHT, true)
				this.lsmL = this.getCategoryExam(Config.CAT_REFRACTION, 'lensmeter', LEFT, true)
				this.lsmB = this.getCategoryExam(Config.CAT_REFRACTION, 'lensmeter', 'bino', true)

				// no images
				this.hasLoadedRefraction = true

				// 04.02.2022
				if (this.isWizard) {
					this.initRxFinal() // inizializza con valori di SBJ o LSM, se presenti
				}

				// 15.09.2022 patch per WF senza immagini
				if ((this.wfR && this.wfR.hasImages) || (this.wfL && this.wfL.hasImages)) {
					// abilitare anche categ GLC, se non c'e' gia'

					let couple: coupleExam

					// 18.10.2022 fix bug 224
					if (this.catGlaucoma == null) this.catGlaucoma = []

					// 18.10.2022 fix bug 224
					if (this.catGlaucoma == null) this.catGlaucoma = []

					if (this.wfL && this.wfL.hasImages) {
						couple = {
							id: this.wfL.id,
							exam_type: this.wfL.exam_type,
						}
						this.catGlaucoma.push(couple)
					}

					if (this.wfR && this.wfR.hasImages) {
						couple = {
							id: this.wfR.id,
							exam_type: this.wfR.exam_type,
						}
						this.catGlaucoma.push(couple)
					}

					Util.debug('forced WF on GLC categ')
				}

				break

			case Config.CAT_ANTERIOR:
				this.dryEyeR = this.getCategoryExam(Config.CAT_ANTERIOR, 'dryeye', RIGHT)
				this.dryEyeL = this.getCategoryExam(Config.CAT_ANTERIOR, 'dryeye', LEFT)

				// per html..
				if (this.dryEyeR == null) this.dryEyeR = new DryEyeExam(null)
				if (this.dryEyeL == null) this.dryEyeL = new DryEyeExam(null)

				this.initAnteriorImages(RIGHT)
				this.initAnteriorImages(LEFT)

				break

			case Config.CAT_CORNEA:
				//se non gia' fatto ?!
				this.getTopoExams()
				this.getPachyMultExams()

				this.topoR = this.getCategoryExam(Config.CAT_CORNEA, 'topo', RIGHT, true)
				this.topoL = this.getCategoryExam(Config.CAT_CORNEA, 'topo', LEFT, true)

				// per html..
				if (this.topoR == null) this.topoR = new TopoExam(null)
				if (this.topoL == null) this.topoL = new TopoExam(null)

				this.initCorneaImages(RIGHT)
				this.initCorneaImages(LEFT)

				break

			case Config.CAT_GLC:
				this.getPachyExams()
				this.getWfExams()
				this.getTonoExams()
				this.getRetroExams()

				this.pachyR = this.getCategoryExam(Config.CAT_GLC, 'pachy', RIGHT)
				this.pachyL = this.getCategoryExam(Config.CAT_GLC, 'pachy', LEFT)
				this.tonoR = this.getCategoryExam(Config.CAT_GLC, 'tono', RIGHT)
				this.tonoL = this.getCategoryExam(Config.CAT_GLC, 'tono', LEFT)

				this.retroR = this.getCategoryExam(Config.CAT_GLC, 'retro', RIGHT)
				this.retroL = this.getCategoryExam(Config.CAT_GLC, 'retro', LEFT)

				// per parte dati su html..
				if (this.pachyR == null) this.pachyR = new PachyExam(null)
				if (this.pachyL == null) this.pachyL = new PachyExam(null)
				if (this.tonoR == null) this.tonoR = new TonoExam(null)
				if (this.tonoL == null) this.tonoL = new TonoExam(null)

				this.initGlcImages(RIGHT)
				this.initGlcImages(LEFT)

				// 30.05.2022 ok anche per il wizard ?!
				//this.getGlcImages(RIGHT);
				//var imagesL = this.getGlcImages(LEFT);

				// 07.10.2022
				this.iopc_note = ''
				if (this.tonoR || this.tonoL) {
					// assumiamo che sia uguale tra i due occhi
					let ct = this.tonoR != null ? this.tonoR.correction_type : this.tonoL.correction_type
					this.iopc_note = this.translator.instant('TONO.IOPc_NOTE', { v1: ct })
				}

				Util.debug('(initCategsInput) done ' + catName)

				break

			case Config.CAT_FUNDUS:
				// inizializza, se non gia' fatto ?!
				//if(this.currentCategories[Config.CAT_FUNDUS] == null){
				//if(!this.dataService.hasLoadedCategory(catName)){  // 01.04.2022 tolto if
				this.getFundusExams() // valorizza la examDate
				//}

				// 01.04.2022 il true finale viene ignorato, a cosa serviva ?? [ls]
				this.fundusR = this.getCategoryExam(Config.CAT_FUNDUS, Config.EXM_FUNDUS, RIGHT, true)
				this.fundusL = this.getCategoryExam(Config.CAT_FUNDUS, Config.EXM_FUNDUS, LEFT, true)

				// per html..
				if (this.fundusR == null) this.fundusR = new FundusExam(null)
				if (this.fundusL == null) this.fundusL = new FundusExam(null)

				this.initFundusImages(RIGHT)
				this.initFundusImages(LEFT)

				break

			default:
				Util.debug('(initCategsInput) not managed ' + catName)
		}

		// 10.01.2023 FIXME se avevo gia' refertato questo tab, carico le img selected
		if (this.isWizard) {
			let alreadyGraded = this.currentCategories[catName].hasReportDone
			//Util.debug("(initCategsInput) wizard, categ "+catName+" already graded? "+alreadyGraded+".");

			// serve farlo su Anterior e su Fundus,
			if (alreadyGraded) {
				Util.debug('(initCategsInput) wizard, categ ' + catName + ' already graded.')

				if (catName == Config.CAT_ANTERIOR) {
					let totL = this.anteriorImagesLeft ? this.anteriorImagesLeft.length : 0
					let totR = this.anteriorImagesRight ? this.anteriorImagesRight.length : 0

					// solo se ne hanno piu' di 4
					if (totL > 4 || totR > 4) {
						this.setSelectedImages(this.ctgExamsAnterior, this.externalImagesRight, this.externalImagesLeft)
					} else {
						Util.debug('(initCategsInput) few images')
					}
				} else if (catName == Config.CAT_FUNDUS) {
					let totL = this.fundusImagesLeft ? this.fundusImagesLeft.length : 0
					let totR = this.fundusImagesRight ? this.fundusImagesRight.length : 0

					// solo se ne hanno piu' di 4
					if (totL > 4 || totR > 4) {
						this.setSelectedImages(this.ctgExamsFundus, this.fundusImagesRight, this.fundusImagesLeft)
					} else {
						Util.debug('(initCategsInput) few images')
					}
				}
			}
		}

		Util.debug('(initCategsInput) done ' + catName)
	}

	// 01.09.2020 nel caso non ci siano middleData,
	// metto a false il flag per mostrare la tabella centrale
	private enableDtOnDiagnosi(catName, flag) {
		switch (catName) {
			case Config.CAT_REFRACTION:
				this.diagnRefraction.dtEnabled = flag // potrei voler stampare solo la prescrizione, senza dati
				break

			case Config.CAT_ANTERIOR:
				this.diagnAnterior.dtEnabled = flag
				break

			case Config.CAT_CORNEA:
				this.diagnCornea.dtEnabled = flag
				break

			case Config.CAT_GLC:
				this.diagnGlaucoma.dtEnabled = flag
				break

			case Config.CAT_FUNDUS:
				this.diagnFundus.dtEnabled = flag
				break

			default:
				Util.debug('(enableDtOnDiagnosi) not managed ' + catName)
		}
	}

	// 24.08.2020
	private loadPrevDiagnosi(catName: string) {
		//key = "P"+ usrId + "_"+this.patientId + "_ctg_"+categ;
		let key = this.getStorageKey(catName)

		//let item = JSON.parse(sessionStorage.getItem(key));
		let tmp = sessionStorage.getItem(key)
		if (tmp != null) {
			let prevDiagn = JSON.parse(tmp) // e' gia' di tipo SrvDiagnosis
			//let srvCategSaved = new SrvDiagnosis(prevDiagn);

			// console.log(prevDiagn)

			//let msg = "Do you want to keep previous diagnosis values ?";
			//Do you want to keep the values of the previous diagnosis?
			//let msg = this.translator.instant('DIAGNOSI.QUESTION_KEEP');

			// 27.08.2020 senza chiedere, teniamo di default, semmai mettere un bottone "clear form"
			//if(confirm(msg)){
			//if(true){
			Util.debug('(loadPrevDiagn) ' + catName + ' OK keep prev')
			this.initDiagnosisForm(catName, prevDiagn)
			//} else {
			//Util.debug("(loadPrevDiagn) "+catName+" NO, delete previous");
			// TODO cancella salvataggio
			//sessionStorage.removeItem(key);
			//}

			// troppo lento che appaiano i valori sul form ?!
			//Util.debug("(loadPrevDiagn) "+catName+" OK done, "+tmp);
		} else {
			Util.debug('(loadPrevDiagn) nothing to pre-load for ' + catName)
		}

		// TODO: potrebbe risultare noioso l'alert, se ci sono tante categorie salvate,
		// perche' lo chiede ogni volta che si cambia tab...
	}

	private getStorageKey(categ) {
		// 20.01.2022 aggiunto userId
		let usrId = this.session.getUserId()
		// 27.08.2020 aggiunto patientId, per maggior sicurezza
		let key = 'P' + usrId + '_' + this.patientId + '_ctg_' + categ

		return key
	}

	// deve ritornare una globale per evitare loop
	getTopoExams() {
		if (this.topoExams == null) {
			Util.debug('(getTopoExams) calculating... ')
			this.topoExams = this.getCategoryExams(Config.CAT_CORNEA, null, Config.EXM_TOPO)

			let device = ''

			if (this.topoExams != null && this.topoExams.length > 0) {
				// 28.07.2020 spostato, valorizzato hasMiddleInfo su dataModel
				//if(this.currentCategories[Config.CAT_CORNEA])
				//  this.currentCategories[Config.CAT_CORNEA].hasMiddleInfo = true;

				Util.debug('(getTopoExams) got ' + this.topoExams.length + ' topo exams.')
				this.topoExamsDate = this.session.mergeExamDate(this.topoExams[0], this.topoExams[1])

				//this.keratometryEnabled = true;  // per diagnosi su refraction  28.10.2020

				// 08.04.2022 patch
				for (let i = 0; i < this.topoExams.length; i++) {
					let myExam = this.topoExams[i]
					device = myExam.device
					if (device != null && device != '') break
				}
			} else {
				Util.debug('(getTopoExams) no topo exams!')

				// 28.07.2020 spostato, valorizzato hasMiddleInfo su dataModel
				//if(this.currentCategories[Config.CAT_CORNEA])
				//  this.currentCategories[Config.CAT_CORNEA].hasMiddleInfo = false;

				if (this.diagnCornea != null)
					// solo nella diagnosi
					this.diagnCornea.dtEnabled = false // 12.06.2020 gli unici dati provengono dal topo, che qui manca
			}

			// 08.04.2022 patch..
			if (device == 'ER' && !this.isWizard) {
				// this.currentAction != "diagnosis"
				this.disableCateg(Config.CAT_CORNEA)
			}
		}
		return this.topoExams
	}

	// 22.04.2020
	getPachyMultExams() {
		if (this.pmExams == null) {
			Util.debug('(getPMExams) calculating... ')
			this.pmExams = this.getCategoryExams(Config.CAT_CORNEA, null, Config.EXM_PACHYMULT)
		}
		return this.pmExams
	}

	// 16.06.2020 deve ritornare una globale per evitare loop
	getExternalExams() {
		if (this.externalExams == null) {
			Util.debug('(getExternalExams) calculating... ')
			this.externalExams = this.getCategoryExams(Config.CAT_ANTERIOR, null, Config.EXM_EXT)
		}

		return this.externalExams
	}

	// 22.06.2020
	getDryEyeExams() {
		if (this.dryEyeExams == null) {
			Util.debug('(getDryEyeExams) calculating... ')
			this.dryEyeExams = this.getCategoryExams(Config.CAT_ANTERIOR, null, Config.EXM_DRYEYE)
		}

		if (this.dryEyeExams != null && this.dryEyeExams.length > 0) {
			this.dryEyeExamsDate = this.session.mergeExamDate(this.dryEyeExams[0], this.dryEyeExams[1])

			// 28.07.2020 spostato, valorizzato hasMiddleInfo su dataModel
			//this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo = true;

			Util.debug('(getDryEyeExams) middle data: ' + this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo)
		}

		return this.dryEyeExams
	}

	// 21.07.2020
	getPachyExams() {
		if (this.pachyExams == null) {
			Util.debug('(getpachyExams) calculating... ')
			this.pachyExams = this.getCategoryExams(Config.CAT_GLC, null, Config.EXM_PACHY)
		}

		if (this.pachyExams != null && this.pachyExams.length > 0) {
			this.pachyExamsDate = this.session.mergeExamDate(this.pachyExams[0], this.pachyExams[1])

			// 28.07.2020 spostato, valorizzato hasMiddleInfo su dataModel
			//this.currentCategories[Config.CAT_GLC].hasMiddleInfo = true;

			Util.debug('(getpachyExams) middle data: ' + this.currentCategories[Config.CAT_GLC].hasMiddleInfo)
		}

		return this.pachyExams
	}

	// 28.07.2020
	getTonoExams() {
		if (this.tonoExams == null) {
			Util.debug('(getTonoExams) calculating... ')
			this.tonoExams = this.getCategoryExams(Config.CAT_GLC, null, Config.EXM_TONO)
		}

		if (this.tonoExams != null && this.tonoExams.length > 0) {
			this.tonoExamsDate = this.session.mergeExamDate(this.tonoExams[0], this.tonoExams[1])
			// 28.07.2020 spostato, valorizzato hasMiddleInfo su dataModel
			//this.currentCategories[Config.CAT_GLC].hasMiddleInfo = true;
			Util.debug('(getTonoExams) middle data: ' + this.currentCategories[Config.CAT_GLC].hasMiddleInfo)
		}

		return this.tonoExams
	}

	// 17.08.2020
	getWfExams() {
		if (this.wfExams == null) {
			Util.debug('(getWfExams) calculating... ')
			this.wfExams = this.getCategoryExams(Config.CAT_GLC, null, Config.EXM_WF)
		}

		if (this.wfExams != null && this.wfExams.length > 0) {
			// 17.08.2020 dati su categ Refraction, immagini sulla GLC
			this.wfExamsDate = this.session.mergeExamDate(this.wfExams[0], this.wfExams[1])
			Util.debug('(getWfExams) merged date: ' + this.wfExamsDate)
			// valorizzato hasMiddleInfo su dataModel

			// 24.03.2022
			let hasImages = false
			let device = ''
			for (let i = 0; i < this.wfExams.length; i++) {
				let myExam = this.wfExams[i]
				device = myExam.device
				if (myExam.hasImages) {
					hasImages = true
					break
				}
			}
			// 24.03.2022 patch..
			if (!hasImages && device == 'ER' && this.currentAction != 'diagnosis') {
				this.disableCateg(Config.CAT_GLC)
			}

			//this.wfEnabled = true;
		}

		return this.wfExams
	}

	// 21.08.2020
	getRetroExams() {
		if (this.retroExams == null) {
			Util.debug('(getRetroExams) calculating... ')
			this.retroExams = this.getCategoryExams(Config.CAT_GLC, null, Config.EXM_RETRO)
		}

		if (this.retroExams != null && this.retroExams.length > 0) {
			this.retroExamsDate = this.session.mergeExamDate(this.retroExams[0], this.retroExams[1])
			Util.debug('(getRetroExams) merged date: ' + this.retroExamsDate)
			// valorizzato hasMiddleInfo su dataModel
		}

		return this.retroExams
	}

	// 05.10.2020
	getSubjectiveExams() {
		if (this.subjectiveExams == null) {
			Util.debug('(getSbjExams) calculating... ')
			this.subjectiveExams = this.getCategoryExams(Config.CAT_REFRACTION, null, Config.EXM_SBJ)
		}

		if (this.subjectiveExams != null && this.subjectiveExams.length > 0) {
			this.sbjExamsDate = this.session.mergeExamDate(this.subjectiveExams[0], this.subjectiveExams[1])
			Util.debug('(getSbjExams) merged date: ' + this.sbjExamsDate)
			// valorizzato hasMiddleInfo su dataModel

			//this.sbjEnabled = true;
		}

		return this.subjectiveExams
	}

	// 27.10.2020
	getLsmExams() {
		if (this.lsmExams == null) {
			Util.debug('(getLsmExams) calculating... ')
			this.lsmExams = this.getCategoryExams(Config.CAT_REFRACTION, null, Config.EXM_LM)
		}
		if (this.lsmExams != null && this.lsmExams.length > 0) {
			this.lsmExamsDate = this.session.mergeExamDate(this.lsmExams[0], this.lsmExams[1])
			Util.debug('(getLsmExams) merged date: ' + this.lsmExamsDate)
			// valorizzato hasMiddleInfo su dataModel

			//this.lsmEnabled = true;
		}
		return this.lsmExamsDate
	}

	// 22.09.2020
	getFundusExams() {
		if (this.fundusExams == null || this.fundusExams.length == 0) {
			Util.debug('(getFundusExams) calculating... ')
			this.fundusExams = this.getCategoryExams(Config.CAT_FUNDUS, null, Config.EXM_FUNDUS)
		}

		if (this.fundusExams != null && this.fundusExams.length > 0) {
			this.fundusExamsDate = this.session.mergeExamDate(this.fundusExams[0], this.fundusExams[1])
			Util.debug('(getFundusExams) merged date: ' + this.fundusExamsDate)
			// valorizzato hasMiddleInfo su dataModel
		} else {
			Util.debug('(getFundusExams) ko merge date!!!') // 01.04.2022
		}

		return this.fundusExams
	}

	// 16.11.2020 per il pdf richiama questo ma valorizza il flag altrove
	// 09.10.2020
	refrCombinedData() {
		// devono esserci SBJ, LSM e WF tutti non nulli, ma il piu' importante e' sbj
		if (this.subjectiveExams != null && this.subjectiveExams.length > 0) {
			this.refrCombData = true // 09.10.2020 il sbj ha i dati DVA, NVA anche di LSM e WF
		}
		//Util.debug("(refrCombinedData) "+this.refrCombData);
		return this.refrCombData
	}

	// TODO spostarla su class category
	// per categoria glc ci sono diversi esami che forniscono dati, fare un merge? o le mostriamo separate ?
	// 28.07.2020 ritorna la data dei dati
	//getCategoryDataTime(catName){
	// 30.07.2020 fatto solo per il glc, TODO generalizzare
	initCategoryDataTime(catName) {
		let ret = ''

		switch (catName) {
			case Config.CAT_REFRACTION:
				// anche subjective e lsm, mix di 4
				let tmpR = Util.mergeStringDates(this.wfExamsDate, this.topoExamsDate)
				let tmp2R = Util.mergeStringDates(this.sbjExamsDate, this.lsmExamsDate)
				this.refractionExamsDate = Util.mergeStringDates(tmpR, tmp2R)
				ret = this.refractionExamsDate
				break

			case Config.CAT_ANTERIOR:
				ret = this.topoExamsDate
				break

			case Config.CAT_CORNEA:
				ret = this.dryEyeExamsDate
				break

			case Config.CAT_FUNDUS:
				break

			case Config.CAT_GLC:
				// anche il retro
				//this.glcExamsDate = Util.mergeStringDates(this.pachyExamsDate, this.tonoExamsDate);
				let tmp = Util.mergeStringDates(this.pachyExamsDate, this.tonoExamsDate)
				this.glcExamsDate = Util.mergeStringDates(this.retroExamsDate, tmp)
				ret = this.glcExamsDate
				break

			default:
				Util.debug('(CategoryDataTime) not managed ' + catName)
		}

		// 27.11.2020 troppe trace, basta uno degli esami
		// deve esserci, ricalcolo ?
		/*if(this.currentCategories[catName].hasMiddleInfo && ret.length == 0){
      Util.debug("(CategoryDataTime) err! ricalcolare per "+catName);
    }*/

		// 27.08.2020 tolta trace
		//Util.debug("(CategoryDataTime) per "+catName+" "+ret+".");

		return ret
	}

	// 20.04.2020 aggiunti filtri eye ed exam-type, opzionali e in alternativa
	private getCategoryExams(catName, eye?, examType?) {
		let myList

		// 19.06.2020
		let currentCategory = this.currentCategories[catName]

		if (currentCategory != null) {
			//this.currentCategoryExams = this.currentCategory.examList;
			this.currentCategoryExams = currentCategory.examList
		} else if (this.dataService.hasLoadedCategory(catName)) {
			let tmp = this.dataService.getCategory(catName) // qui c'e' anche il nome
			if (tmp != null) {
				this.currentCategories[catName] = tmp
				this.currentCategoryExams = tmp.examList
				this.currCategName = catName // 19.06.2020

				Util.debug('(getCategoryExams) GOT them! ' + catName) // 03.06.2020

				// 14.10.2020
				if (this.isWizard && catName == Config.CAT_REFRACTION) {
					this.initRxFinal() // inizializza con valori di SBJ o LSM, se presenti

					// 04.02.2022 lasciamo lo stesso
					// 09.11.2020 default diverso, disab dati
					//this.diagnRefraction.dtEnabled = false;
				}
			}
		}

		if (eye != null) {
			myList = []
			Util.debug('(getCategoryExams) filtering on eye ' + eye)
			for (let i = 0; i < this.currentCategoryExams.length; i++) {
				let exm = this.currentCategoryExams[i]
				if (exm.eye == eye) {
					myList.push(exm)
				}
			}
		} else if (examType != null) {
			myList = []
			Util.debug('(getCategoryExams) filtering on type ' + examType)
			for (let i = 0; i < this.currentCategoryExams.length; i++) {
				let exm = this.currentCategoryExams[i]
				if (exm.exam_type == examType) {
					myList.push(exm)
				}
			}
		} else {
			myList = this.currentCategoryExams
		}

		// 11.10.2021 loop per capire se ho esami mono-occhio
		if (this.currentCategories[catName] != null) {
			for (let i = 0; i < myList.length; i++) {
				let exm = myList[i]

				if (exm.eye == RIGHT) {
					Util.debug('(getCategoryExams) CATEG ' + catName + ' has right exam')
					this.currentCategories[catName].hasDataR = true
				} else if (exm.eye == LEFT) {
					Util.debug('(getCategoryExams) CATEG ' + catName + ' has left exam')
					this.currentCategories[catName].hasDataL = true
				}

				// gia' trovati 2 occhi
				if (this.currentCategories[catName].hasDataR && this.currentCategories[catName].hasDataL) {
					break
				}
			}
		} else {
			Util.debug('(getCategoryExams) CATEG nulla ' + catName)
			// 14.12.2021 TODO disabilitarla,
			// succede per esempio sulla Cat/Glc con ER che ha il WF senza immagini
			// 24.03.2022 ko, qui troppo presto
			//this.disableCateg(catName);
		}

		// 22.11.2022
		if (catName == Config.CAT_FUNDUS && (this.additionsR == null || this.additionsL == null) && this.currDevice == 'VX610') {
			this.initAdditionsList(Config.EXM_FUNDUS)
		}

		return myList
	}

	private getCategoryExam(catName: string, examType: string, eye: string, force?) {
		let myExam = null

		// 19.06.2020
		let currentCategory = this.currentCategories[catName]

		if (currentCategory == null) {
			this.getCategoryExams(catName) // valorizza this.currentCategoryExams
		} else {
			// 26.06.2020 FIX !!
			// quando arrivo qui per il pdf, currentCategoryExams non corriponde al currentCategory!
			this.currentCategoryExams = currentCategory.examList

			// 25.08.2020 tolte trace
			//Util.debug("(getCategoryExam) gia' caricati per "+catName);
			//Util.debug(this.currentCategoryExams);
		}

		if (this.currentCategoryExams != null) {
			for (let i = 0; i < this.currentCategoryExams.length; i++) {
				let exm = this.currentCategoryExams[i]
				if (exm.exam_type == examType && exm.eye == eye) {
					myExam = exm
					break
				}
			}
		}

		// 16.06.2020 quando serve ? xche' solo topo ? [ls]
		// ritorno elemento vuoto, non nullo, per html
		/*
    if(force && myExam == null){        
      if(examType == Config.EXM_TOPO){
        Util.debug("(getCategoryExam) forzare elemento vuoto? per "+examType);
        // myExam = new TopoExam(); // 15.07.2020 per test NO
      }
    }
    */

		// 30.07.2020 per forzare calcolo della data info, per gli esami con dati

		if (examType == Config.EXM_TONO) {
			if (this.tonoExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getTonoExams()
			}
		}

		if (examType == Config.EXM_PACHY) {
			if (this.pachyExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getPachyExams()
			}
		}

		if (examType == Config.EXM_TOPO) {
			if (this.topoExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getTopoExams()
			}
		}

		if (examType == Config.EXM_DRYEYE) {
			if (this.dryEyeExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getDryEyeExams()
			}
		}

		// 18.08.2020
		if (examType == Config.EXM_WF) {
			if (this.wfExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getWfExams()
			}
		}

		if (examType == Config.EXM_RETRO) {
			if (this.retroExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getRetroExams()
			}
		}

		if (examType == Config.EXM_SBJ) {
			// 09.10.2020
			if (this.sbjExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getSubjectiveExams()
			}
		}

		if (examType == Config.EXM_LM) {
			// 27.10.2020
			if (this.lsmExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getLsmExams()
			}
		}

		if (examType == Config.EXM_FUNDUS) {
			// 01.04.2022
			if (this.fundusExamsDate == '' && myExam != null) {
				Util.debug('(getCategoryExam) forzo ricalcolo data per ' + examType)
				this.getFundusExams()
			}
		}

		// 30.07.2020 qui lo fa troppe volte, FIXME?
		// deve combinarne due
		if (catName == Config.CAT_GLC || catName == Config.CAT_REFRACTION) {
			this.initCategoryDataTime(catName)
		}

		// 08.01.2021 tolta trace
		/*
    // 23.06.2020 test 
    let msg = "(getCategoryExam) "+catName+" type:"+examType+" eye:"+eye;
    if(myExam != null){
      msg += " ok exam "+myExam.id;
    } else {
      msg += " KO, exam is null";
    }
    Util.debug(msg);
    */

		return myExam
	}

	// 21.09.2020 FIX, sbagliato chiamare la generica xche' mette insieme le external e la dryEye [ls]
	// questa serve per esporre tutte le external sull'album html
	// 15.06.2020 gestire i 2 album separati, external e dryEye
	getExternalImages(eye): ExamImage[] {
		// 21.09.2020 FIX
		//return this.getCategImages(eye, Config.CAT_ANTERIOR);

		let ret = null
		if (eye == RIGHT && this.externalImagesRight != null) {
			ret = this.externalImagesRight // gia' calcolato
		} else if (eye == LEFT && this.externalImagesLeft != null) {
			ret = this.externalImagesLeft
		} else {
			//array nullo, lo (ri)calcola
			ret = this.initAnteriorImages(eye)
		}
		return ret
	}

	// 26.06.2020 anche se singola, ritorna un array per usarlo con l'album
	getDryEyeImages(eye): ExamImage[] {
		if (this.dryEyeExams == null) {
			Util.debug('(getDryEyeImages) forzo ricalcolo dryeye ')
			this.getDryEyeExams()
		}
		// 01.07.2020 anticipato qui FIX
		else if (this.dryEyeImagesLeft != null && eye == LEFT) {
			return this.dryEyeImagesLeft
		} else if (this.dryEyeImagesRight != null && eye == RIGHT) {
			return this.dryEyeImagesRight
		}

		let dryeyeList: ExamImage[]
		dryeyeList = []

		if (this.dryEyeExams != null) {
			// estraggo l'immagine per questo eye

			// 01.07.2020 loop
			//let exm = this.getCategoryExam(Config.CAT_ANTERIOR, Config.EXM_DRYEYE, eye);

			let exm = null
			for (let i = 0; i < this.dryEyeExams.length; i++) {
				exm = this.dryEyeExams[i]
				if (exm != null && exm.eye == eye) {
					break
				} else {
					exm = null
				}
			}

			if (exm != null) {
				let dryeye = new ExamImage()
				dryeye.eye = eye
				dryeye.date = exm.exam_date
				dryeye.examId = exm.id
				dryeye.image = exm.image
				dryeye.descr = Config.descrImgDryEye[0] // "dry eye";

				//dryeye.position = 7; // dopo le external  30.09.2020
				// no, poi non la trova, xche' per dryeye e' l'unica, pos 0
				dryeye.position = 0 // e' l'unica tra le sue

				//dryeye.type = exm.exam_type;  // 07.07.2020
				dryeye.type = Config.EXM_DRYEYE // 24.07.2020 dovrebbe essere uguale ?!

				dryeyeList.push(dryeye)
			}

			/*
      // 30.06.2020 aggiunto anche qui, dipende dall'ordine delle chiamate ? [ls]
      if(this.dryEyeExams.length>0 && !this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo){
        this.dryEyeExamsDate = this.session.mergeExamDate(this.dryEyeExams[0], this.dryEyeExams[1]);
        this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo = true;
        Util.debug("(getDryEyeImages) middle data: "+this.dryEyeExamsDate);
      }
      */
		}

		// 01.07.2020 aggiunto test
		if (dryeyeList.length > 0) {
			Util.debug('(getDryEyeImages) valorizzo ' + eye)
			if (eye == LEFT) {
				this.dryEyeImagesLeft = dryeyeList
				this.hasDryEyeImageLeft = true
				//return this.dryEyeImagesLeft;
			} else if (eye == RIGHT) {
				this.dryEyeImagesRight = dryeyeList
				this.hasDryEyeImageRight = true
				//return this.dryEyeImagesRight;
			}
		}

		// 01.07.2020 ritorno cmq la globale, anche se nulla, patch ?
		if (eye == LEFT) {
			return this.dryEyeImagesLeft
		} else {
			// (eye ==RIGHT){
			return this.dryEyeImagesRight
		}
	}

	// solo nella vista esame si chiama questa, durante ilwizard invece c'e' la getGlcImages
	// 22.07.2020 mostriamo entrambe, anche se poi forse la image andra' tolta [ls]
	getPachyImages(eye): ExamImage[] {
		if (this.pachyExams == null) {
			Util.debug('(getPachyImages) forzo ricalcolo pachy ')
			this.getPachyExams()
		}
		// 01.07.2020 anticipato qui FIX
		else if (this.pachyImagesLeft != null && eye == LEFT) {
			return this.pachyImagesLeft
		} else if (this.pachyImagesRight != null && eye == RIGHT) {
			return this.pachyImagesRight
		}

		let pachyList: ExamImage[]
		pachyList = []

		if (this.pachyExams != null) {
			// estraggo le immagini per questo eye

			let exm = null
			for (let i = 0; i < this.pachyExams.length; i++) {
				exm = this.pachyExams[i]
				if (exm != null && exm.eye == eye) {
					break
				} else {
					exm = null
				}
			}

			if (exm != null) {
				let pachy = new ExamImage()
				pachy.eye = eye
				pachy.date = exm.exam_date
				pachy.examId = exm.id
				pachy.type = exm.exam_type

				// 30.05.2022 per prima la pachy with data

				if (exm.image_with_data != null) {
					let rich = new ExamImage(pachy)
					rich.image = exm.image_with_data
					rich.descr = Config.descrImgPachyS[1] // "pachy with data";
					pachyList.push(rich)
				}

				// 30.05.2022 TODO: precedenza alla pachy_with_data: se manca, mostra quella plain
				//else {

				if (exm.image != null) {
					let basic = new ExamImage(pachy)
					basic.image = exm.image
					basic.descr = Config.descrImgPachyS[0] // "pachy";
					pachyList.push(basic)
				}

				//}
			}
		}

		// aggiunto test
		if (pachyList.length > 0) {
			Util.debug('(getPachyImages) valorizzo ' + eye)
			if (eye == LEFT) {
				this.pachyImagesLeft = pachyList
				this.hasPachyImageLeft = true
			} else if (eye == RIGHT) {
				this.pachyImagesRight = pachyList
				this.hasPachyImageRight = true
			}
		}

		// ritorno cmq la globale, anche se nulla
		if (eye == LEFT) {
			return this.pachyImagesLeft
		} else if (eye == RIGHT) {
			return this.pachyImagesRight
		} else {
			return null
		}
	}

	// 17.08.2020
	getWfImages(eye, imgType?): ExamImage[] {
		if (this.wfExams == null) {
			Util.debug('(getWfImages) forzo ricalcolo wf ')
			this.getWfExams()
		} else if (this.wfImagesLeft != null && eye == LEFT) {
			return this.wfImagesLeft
		} else if (this.wfImagesRight != null && eye == RIGHT) {
			return this.wfImagesRight
		}

		let wfList: ExamImage[]
		wfList = []

		if (this.wfExams != null) {
			// estraggo le immagini per questo eye

			let exm = null
			for (let i = 0; i < this.wfExams.length; i++) {
				exm = this.wfExams[i]
				if (exm != null && exm.eye == eye) {
					break
				} else {
					exm = null
				}
			}

			if (exm != null) {
				let wf = new ExamImage()
				wf.eye = eye
				wf.date = exm.exam_date
				wf.examId = exm.id
				wf.type = exm.exam_type

				if (imgType == null || imgType == 'grid') {
					if (exm.image_grid != null) {
						let grid = new ExamImage(wf)
						grid.image = exm.image_grid
						grid.descr = Config.descrImgWf[0] // "wf grid";
						wfList.push(grid)
					}
				}

				if (imgType == null || imgType == 'meso') {
					if (exm.image_meso != null) {
						let meso = new ExamImage(wf)
						meso.image = exm.image_meso
						meso.descr = Config.descrImgWf[1] // "wf meso";
						wfList.push(meso)
					}
				}
			}
		} else {
			Util.debug('(getWfImages) ko! ' + eye)
		}

		// aggiunto test
		if (wfList.length > 0) {
			Util.debug('(getWfImages) valorizzo ' + eye)
			if (eye == LEFT) {
				this.wfImagesLeft = wfList
				this.hasWfImageLeft = true
			} else if (eye == RIGHT) {
				this.wfImagesRight = wfList
				this.hasWfImageRight = true
			}
		} //else {  // 25.08.2020 tolte [ls]
		//Util.debug("(getWfImages) ko bis ! "+eye);
		//}

		// ritorno cmq la globale, anche se nulla
		if (eye == LEFT) {
			return this.wfImagesLeft
		} else if (eye == RIGHT) {
			return this.wfImagesRight
		} else {
			return null
		}
	}

	// 21.08.2020
	getRetroImages(eye, imgType?): ExamImage[] {
		if (this.retroExams == null) {
			Util.debug('(getRetroImages) forzo ricalcolo retro ')
			this.getRetroExams()
		} else if (this.retroImagesLeft != null && eye == LEFT) {
			//Util.debug("(getRetroImages) ok for Eye: "+eye+", tot: "+this.retroImagesLeft.length);
			return this.retroImagesLeft
		} else if (this.retroImagesRight != null && eye == RIGHT) {
			//Util.debug("(getRetroImages) ok for Eye: "+eye+", tot: "+this.retroImagesRight.length);
			return this.retroImagesRight
		}

		let retroList: ExamImage[]
		retroList = []

		if (this.retroExams != null) {
			// estraggo le immagini per questo eye

			let exm = null
			for (let i = 0; i < this.retroExams.length; i++) {
				exm = this.retroExams[i]
				if (exm != null && exm.eye == eye) {
					break
				} else {
					exm = null
				}
			}

			if (exm != null) {
				let retro = new ExamImage()
				retro.eye = eye
				retro.date = exm.exam_date
				retro.examId = exm.id
				retro.type = exm.exam_type

				// 31.08.2020 se ne mostra solo una, quella che c'e' [ls]

				if (exm.image_high != null) {
					let myEI = new ExamImage(retro)
					myEI.image = exm.image_high
					myEI.descr = Config.descrImgRetro[0] // "retro high";
					retroList.push(myEI)
				} else if (exm.image_low != null) {
					let myEI2 = new ExamImage(retro)
					myEI2.image = exm.image_low
					myEI2.descr = Config.descrImgRetro[1] //"retro low";
					retroList.push(myEI2)
				}
			}
		} else {
			Util.debug('(getRetroImages) ko! ' + eye)
		}

		// aggiunto test
		if (retroList.length > 0) {
			Util.debug('(getRetroImages) valorizzo ' + eye)
			if (eye == LEFT) {
				this.retroImagesLeft = retroList
				this.hasRetroImageLeft = true
			} else if (eye == RIGHT) {
				this.retroImagesRight = retroList
				this.hasRetroImageRight = true
			}
		} //else {   // 25.08.2020 tolte [ls]
		//Util.debug("(getRetroImages) ko bis ! "+eye);
		//}

		// ritorno cmq la globale, anche se nulla
		if (eye == LEFT) {
			return this.retroImagesLeft
		} else if (eye == RIGHT) {
			return this.retroImagesRight
		} else {
			return null
		}
	}

	// 25.08.2020 viene chiamata anche per la categories.html
	// 25.06.2020 questa serve per il pdf,
	// per pdf visit ritorna la external automatica e la dryeye [ls]
	// per pdf diagnosi ritorna tutte le external + la dryEye // 09.07.2020
	getAnteriorImages(eye): ExamImage[] {
		let ret = null

		// 09.07.2020
		if (eye == RIGHT && this.anteriorImagesRight != null) {
			//Util.debug("(getAnteriorImages) [diagn] gia' ok right");
			return this.anteriorImagesRight
		} else if (eye == LEFT && this.anteriorImagesLeft != null) {
			return this.anteriorImagesLeft
		}

		let externals: ExamImage[] // array di immagini
		let dryeye: ExamImage // immagine singola
		//let extAuto: ExamImage;  // immagine singola, la external automatica

		if (eye == RIGHT && this.externalImagesRight != null) {
			externals = this.externalImagesRight // gia' calcolato
		} else if (eye == LEFT && this.externalImagesLeft != null) {
			externals = this.externalImagesLeft
		} else {
			// 25.08.2020 verificare, init dovrebbe ritornare anche la dryeye ?
			//array nullo, lo (ri)calcola
			externals = this.initAnteriorImages(eye)
		}

		// 25.06.2020 verificare se serve ?! [ls]
		if (this.dryEyeExams == null) {
			Util.debug('(getAnteriorImages) [patch] forzo ricalcolo dryeye ') // non passa mai
			this.getDryEyeExams()
		}

		if (this.dryEyeExams != null) {
			// estraggo l'immagine per questo eye
			let list = this.getDryEyeImages(eye)
			if (list != null) {
				Util.debug('(getAnteriorImages) [test] ok singola DryEye')
				dryeye = list[0]
			}
		}

		// costruisco array con la risposta
		ret = []

		// 09.07.2020 su pdf della visita ok una sola external, su quello del report tutte, filtrate dopo [ls]
		// NB ordine delle push, prima external poi dryeye
		ret = this.setFullAnterior(eye, externals, dryeye)

		return ret
	}

	// 15.06.2020 generalizzato il metodo, valutare se spostarli [ls]
	getCorneaImages(eye): ExamImage[] {
		return this.getCategImages(eye, Config.CAT_CORNEA)
	}

	// per il pdf e il wizard, mentre per la view della visita si usa getPachyImages, che le mostra splittate
	getGlcImages(eye): ExamImage[] {
		return this.getCategImages(eye, Config.CAT_GLC)
	}

	// 22.09.2020 qui img degli esami e della categoria coincidono
	getFundusImages(eye): ExamImage[] {
		return this.getCategImages(eye, Config.CAT_FUNDUS)
	}

	//ritorna tutte le immagini su un array, anziche' axial_map, ecc. per album
	private getCategImages(eye, catName): ExamImage[] {
		let ret = null

		switch (catName) {
			case Config.CAT_ANTERIOR:
				// 18.08.2020 ci sono due album, external e singola dryEye, FIXME integrare e uniformare!
				ret = this.getAnteriorImages(eye)
				/* KO
        if(eye == RIGHT && this.externalImagesRight != null){
          ret = this.externalImagesRight; // gia' calcolato
        } else if(eye == LEFT && this.externalImagesLeft != null) {
          ret = this.externalImagesLeft;
        } else {
          //array nullo, lo (ri)calcola
          ret = this.initAnteriorImages(eye);
        }
      */
				break

			case Config.CAT_CORNEA:
				if (eye == RIGHT && this.corneaImagesRight != null) {
					ret = this.corneaImagesRight // gia' calcolato
				} else if (eye == LEFT && this.corneaImagesLeft != null) {
					ret = this.corneaImagesLeft
				} else {
					//array nullo, lo (ri)calcola
					ret = this.initCorneaImages(eye)
				}
				break

			case Config.CAT_GLC:
				if (eye == RIGHT && this.glcImagesRight != null) {
					//Util.debug("(getCtgImages) gia' ok right");
					return this.glcImagesRight
				} else if (eye == LEFT && this.glcImagesLeft != null) {
					return this.glcImagesLeft
				} else {
					//array nullo, lo (ri)calcola
					ret = this.initGlcImages(eye)
				}
				break

			case Config.CAT_FUNDUS:
				if (eye == RIGHT && this.fundusImagesRight != null) {
					ret = this.fundusImagesRight // gia' calcolato
				} else if (eye == LEFT && this.fundusImagesLeft != null) {
					ret = this.fundusImagesLeft
				} else {
					//array nullo, lo (ri)calcola
					ret = this.initFundusImages(eye)
				}
				break

			default:
				Util.debug('(getCategImages) not managed ' + catName)
		}
		return ret
	}

	// 15.06.2020 richiamata su ng-init di categ_diagn.html
	private initAnteriorImages(eye): ExamImage[] {
		let myImages = []

		// inizializza, se non gia' fatto
		if (this.currentCategories[Config.CAT_ANTERIOR] == null) {
			this.getExternalExams()
			this.getDryEyeExams() // 22.06.2020
		}

		if (this.currentCategories[Config.CAT_ANTERIOR] == null) {
			Util.debug('(initAnteriorImages) ko category!')
			return myImages
		}

		if (eye == null) {
			// 25.08.2020 quando succede ?
			Util.debug('(initAnteriorImages) missing eye param!')
			return myImages
		}

		Util.debug('(initAnteriorImages) calc externals for eye ' + eye)

		let exm: ExternalExam
		exm = this.getCategoryExam(Config.CAT_ANTERIOR, Config.EXM_EXT, eye)
		if (exm != null) {
			// 24.04.2020
			let examImg = new ExamImage()
			examImg.eye = eye
			examImg.date = exm.exam_date
			examImg.examId = exm.id // 28.05.2020
			examImg.type = Config.EXM_EXT // 07.07.2020

			if (exm.image_auto != null) {
				let ex1 = new ExamImage(examImg) // copia
				ex1.image = exm.image_auto
				ex1.descr = Config.descrImgExternal[0] // "automatic";
				ex1.position = 0 // 28.09.2020
				myImages.push(ex1)
				//Util.debug("(initAnteriorImages) added "+ex1.descr);
			}

			let manuals = exm.getManualImages() // da 0 a 6
			for (let i = 0; i < manuals.length; i++) {
				let img = manuals[i]
				if (img != null) {
					let ex2 = new ExamImage(examImg)
					ex2.image = img
					let pos = i + 1
					ex2.descr = Config.descrImgExternal[pos] // "manual "+(i+1);
					ex2.position = pos // 28.09.2020
					ex2.selected = false // 12.01.2023 lasciamo solo la automatica
					myImages.push(ex2)
					//Util.debug("(initAnteriorImages) added "+ex2.descr);
				}
			}
		} else {
			Util.debug('(initAnteriorImages) null exam external, eye ' + eye)
		}

		if (eye == RIGHT) {
			this.externalImagesRight = myImages
			if (myImages.length > 0) {
				this.hasExternalImagesRight = true // 25.08.2020
				// 25.08.2020 discutibile che sia solo qui, rivedere [ls]
				this.currentCategories[Config.CAT_ANTERIOR].hasImagesR = true
			}
		} else if (eye == LEFT) {
			this.externalImagesLeft = myImages
			if (myImages.length > 0) {
				this.hasExternalImagesLeft = true // 25.08.2020
				this.currentCategories[Config.CAT_ANTERIOR].hasImagesL = true
			}
		}

		// 23.06.2020 dryEye
		// 01.07.2020 esteso test
		// 25.06.2020 fix, se e' la prima volta e non ha ancora calcolata,
		// con questa funzione valorizza anche la data
		//if(!this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo){
		if (this.dryEyeExams == null || !this.currentCategories[Config.CAT_ANTERIOR].hasMiddleInfo) {
			Util.debug('(initAnteriorImages) init dryeye...')
			this.getDryEyeExams()
		}

		// 09.07.2020 patch per il pdf e la diagnosi ***********
		// passa sempre di qui
		if (!this.anteriorImagesRight || !this.anteriorImagesLeft) {
			let dryeye = null
			if (this.dryEyeExams != null) {
				// estraggo l'immagine per questo eye
				let list = this.getDryEyeImages(eye)
				if (list != null) {
					Util.debug('(initAnteriorImages) ok singola DryEye ' + eye)
					dryeye = list[0]
				}
			}
			this.setFullAnterior(eye, myImages, dryeye) // valorizza anteriorImages
		}
		// fine patch ********************

		// 25.08.2020 questo array contiene solo le externals ?! VERIF
		return myImages
	}

	// 09.07.2020
	private setFullAnterior(eye, externals, dryeye) {
		let ret = []
		// 09.07.2020 su pdf della visita ok una sola external, su quello del report tutte, filtrate dopo [ls]
		//if (this.isWizard) {
		if (this.isWizard || this.isPdfDiagnosis) {
			// 04.01.2023 FIX bug 290
			if (externals != null) {
				for (let j = 0; j < externals.length; j++) {
					if (externals[j]) ret.push(externals[j])
				}
				Util.debug('(setFullAnterior) [diagn] eye: ' + eye + ' tot external: ' + ret.length)
			}
		} else {
			// per la visita, solo la automatica
			// 26.06.2020
			if (externals != null && externals[0] != null) {
				ret.push(externals[0])
			}
		}

		if (dryeye != null) {
			ret.push(dryeye)
		}

		// 09.07.2020
		if (eye == RIGHT) {
			this.anteriorImagesRight = ret
			if (ret.length > 0)
				// 25.08.2020
				this.currentCategories[Config.CAT_ANTERIOR].hasImagesR = true
		} else if (eye == LEFT) {
			this.anteriorImagesLeft = ret
			if (ret.length > 0)
				// 25.08.2020
				this.currentCategories[Config.CAT_ANTERIOR].hasImagesL = true
		}

		Util.debug('(setFullAnterior) eye: ' + eye + ' tot anterior pro pdf: ' + ret.length)

		return ret
	}

	//18.08.2020 - ordine fisso: retro, wf, pachy
	// cfr con hasWfImageRight ?
	private initGlcImages(eye): ExamImage[] {
		let myImages = []

		// inizializza, se non gia' fatto
		if (this.currentCategories[Config.CAT_GLC] == null) {
			this.getPachyExams()
			this.getWfExams()
			this.getRetroExams() // 21.08.2020
		}

		if (this.currentCategories[Config.CAT_GLC] == null) {
			Util.debug('(initGlcImages) ko category!')
			return myImages
		}

		if (eye == null) {
			// quando succede ?
			Util.debug('(initGlcImages) missing eye param!')
			return myImages
		}

		Util.debug('(initGlcImages) calc pachy for eye ' + eye)

		// 24.08.2020 att. all'ordine: retro, wf e pachy

		//singola retro high **********************************************
		if (this.retroExams == null) {
			Util.debug('(initGlcImages) init retro...')
			this.getRetroExams()
		}

		Util.debug('(initGlcImages) calc retro for eye ' + eye)

		let retroExm: RetroExam
		retroExm = this.getCategoryExam(Config.CAT_GLC, Config.EXM_RETRO, eye)
		if (retroExm != null) {
			// modello
			let examImg = new ExamImage()
			examImg.eye = eye
			examImg.date = retroExm.exam_date
			examImg.examId = retroExm.id
			examImg.type = Config.EXM_RETRO

			// 31.08.2020 cambiamento, mettiamo quella che c'e', stessa POS !
			// mettiamo solo image_high
			if (retroExm.image_high != null) {
				let exH = new ExamImage(examImg)
				exH.image = retroExm.image_high
				exH.descr = Config.descrImgRetro[0] //"retro high";
				exH.position = 0 // 28.09.2020
				myImages.push(exH)
			} else if (retroExm.image_low != null) {
				let exL = new ExamImage(examImg)
				exL.image = retroExm.image_low
				exL.descr = Config.descrImgRetro[1] //"retro low";
				exL.position = 1 // 28.09.2020
				myImages.push(exL)
			}

			// 31.01.2022 serve per i tabs nelle categories
			if (myImages.length > 0) {
				if (eye == RIGHT) {
					this.hasRetroImageRight = true
				} else if (eye == LEFT) {
					this.hasRetroImageLeft = true
				}
			}
		}

		//singola wf grid  ****************************************************
		if (this.wfExams == null) {
			Util.debug('(initGlcImages) init wf...')
			this.getWfExams()
		}

		Util.debug('(initGlcImages) calc wf for eye ' + eye)

		let wfExm: WfExam
		wfExm = this.getCategoryExam(Config.CAT_GLC, Config.EXM_WF, eye)
		if (wfExm != null) {
			// modello
			let examImg = new ExamImage()
			examImg.eye = eye
			examImg.date = wfExm.exam_date
			examImg.examId = wfExm.id
			examImg.type = Config.EXM_WF

			// 18.08.2020 mettiamo solo image_grid
			if (wfExm.image_grid != null) {
				let ex2 = new ExamImage(examImg)
				ex2.image = wfExm.image_grid
				ex2.descr = Config.descrImgWf[0] // "wf grid";
				ex2.position = 0 // 30.09.2020 cmq pos 0, del suo tipo, e' singola
				myImages.push(ex2)

				// 31.01.2022 serve per i tabs nelle categories
				if (eye == RIGHT) {
					this.hasWfImageRight = true
				} else if (eye == LEFT) {
					this.hasWfImageLeft = true
				}
			}
		}

		// **********************************************

		// 01.09.2020 come per gli altri, serve ? [ls]
		if (this.pachyExams == null) {
			Util.debug('(initGlcImages) init pachy...')
			this.getPachyExams()
		}

		Util.debug('(initGlcImages) calc pachy for eye ' + eye)

		let exm: PachyExam
		exm = this.getCategoryExam(Config.CAT_GLC, Config.EXM_PACHY, eye)
		if (exm != null) {
			// modello
			let examImg = new ExamImage()
			examImg.eye = eye
			examImg.date = exm.exam_date
			examImg.examId = exm.id
			examImg.type = Config.EXM_PACHY

			let ex2 = null

			// 18.08.2020 mettiamo solo image_with_data
			if (exm.image_with_data != null) {
				ex2 = new ExamImage(examImg)
				ex2.image = exm.image_with_data
				ex2.descr = Config.descrImgPachyS[1] // "pachy with data";
				ex2.position = 1 // 30.09.2020 relativa a questo esame
				myImages.push(ex2)

				// se mancano i dati, forse c'e' l'altra ?
			} else if (exm.image != null) {
				ex2 = new ExamImage(examImg)
				ex2.image = exm.image
				ex2.descr = Config.descrImgPachyS[0] // "pachy";
				ex2.position = 0 // 30.09.2020 relativa a questo esame
				myImages.push(ex2)
			}

			// 31.01.2022 serve per i tabs nelle categories
			if (ex2 != null) {
				if (eye == RIGHT) {
					this.hasPachyImageRight = true
				} else if (eye == LEFT) {
					this.hasPachyImageLeft = true
				}
			}
		} //else {
		//Util.debug("(initGlcImages) null exam pachy, eye "+eye);
		//}

		// 26.08.2020 serve per la categ_diagnosi.html
		if (eye == RIGHT) {
			this.glcImagesRight = myImages

			if (myImages.length > 0) {
				this.currentCategories[Config.CAT_GLC].hasImagesR = true
				Util.debug('(initGlcImages) tot right ' + this.glcImagesRight.length)
			}
		} else if (eye == LEFT) {
			this.glcImagesLeft = myImages
			if (myImages.length > 0) {
				this.currentCategories[Config.CAT_GLC].hasImagesL = true
				Util.debug('(initGlcImages) tot left ' + this.glcImagesRight.length)
			}
		}

		// **********************************************

		/*  ????
    // 09.07.2020 patch per il pdf e la diagnosi 
    if(!this.pachyImagesRight || !this.pachyImagesLeft){
      let dryeye = null;
      if(this.dryEyeExams != null) { // estraggo l'immagine per questo eye
        let list = this.getDryEyeImages(eye);
        if(list != null){
          Util.debug("(initGlcImages) ok singola DryEye "+eye);
          dryeye = list[0];
        }
      }
      this.setFullGlc(eye, myImages, dryeye);
    }
    */

		/*
    // ATT: non sono tutte!    
    if(eye == RIGHT)
      this.wfImagesRight = myImages;
    else if(eye == LEFT)
      this.wfImagesLeft = myImages;
    */

		return myImages
	}

	// 28.05.2020 separata funzione di init da quella di get [ls]
	private initCorneaImages(eye): ExamImage[] {
		let myImages = []

		// 19.06.2020
		// inizializza, se non gia' fatto
		//if (this.currentCategory == null){
		if (this.currentCategories[Config.CAT_CORNEA] == null) {
			this.getTopoExams()
			this.getPachyMultExams() // 28.05.2020 servono entrambi
		}

		// 03.06.2020 test dannoso x report diagnosi ? [ls]
		//if (this.currentCategory == null){
		if (this.currentCategories[Config.CAT_CORNEA] == null) {
			Util.debug('(initCorneaImages) ko category!')
			return myImages
		}

		if (eye != null) {
			Util.debug('(initCorneaImages) calc for eye ' + eye)

			let exm: TopoExam
			exm = this.getCategoryExam(Config.CAT_CORNEA, Config.EXM_TOPO, eye)
			if (exm != null) {
				// 20.04.2020 NB. questa non va mostrata. va aggiunta invece quella della pachyMult
				// if(exm.image != null) myImages.push(exm.image);

				// 24.04.2020
				let examImg = new ExamImage()
				examImg.eye = eye
				examImg.date = exm.exam_date
				examImg.examId = exm.id // 28.05.2020
				examImg.type = Config.EXM_TOPO // 07.07.2020

				if (exm.axial_map != null) {
					let ex1 = new ExamImage(examImg) // copia
					ex1.image = exm.axial_map
					ex1.descr = Config.descrImgTopo[0] // "axial map";
					ex1.position = 0 // 28.09.2020
					myImages.push(ex1)
					//Util.debug("(initCorneaImages) added "+ex1.descr);
				}
				if (exm.elevation_map != null) {
					let ex2 = new ExamImage(examImg)
					ex2.image = exm.elevation_map
					ex2.descr = Config.descrImgTopo[1] //"elevation map";
					ex2.position = 1 // 28.09.2020
					myImages.push(ex2)
					//Util.debug("(initCorneaImages) added "+ex2.descr);
				}
				if (exm.tangential_map != null) {
					let ex3 = new ExamImage(examImg)
					ex3.image = exm.tangential_map
					ex3.descr = Config.descrImgTopo[2] //"tangential map";
					ex3.position = 2 // 28.09.2020
					myImages.push(ex3)
					//Util.debug("(initCorneaImages) added "+ex3.descr);
				}
			} else {
				Util.debug('(initCorneaImages) null exam topo, eye ' + eye)
			}

			let exmP: PachyMultExam
			exmP = this.getCategoryExam(Config.CAT_CORNEA, Config.EXM_PACHYMULT, eye)
			if (exmP != null) {
				if (exmP.image != null) {
					let examImg = new ExamImage()

					examImg.eye = eye
					examImg.date = exmP.exam_date
					examImg.examId = exmP.id // 28.05.2020
					examImg.type = exmP.exam_type // 07.07.2020

					examImg.image = exmP.image
					examImg.descr = Config.descrImgPachyMult[0] // "pachy multi";

					//examImg.position = 3;  // 28.09.2020
					examImg.position = 0 // 30.09.2020 anche se viene dopo le img del topo, e' la sua prima img [ls]

					myImages.push(examImg)
					//Util.debug("(initCorneaImages) added "+examImg.descr);
				} else Util.debug('(initCorneaImages) missing pachymult image!, id ' + exmP.id)
			} else {
				Util.debug('(initCorneaImages) null exam pachy, eye ' + eye)
			}
		}

		if (eye == RIGHT) {
			this.corneaImagesRight = myImages
			//this.hasImagesR = (myImages.length >0);
			// 20.08.2020 fix!
			//this.currentCategories[Config.CAT_CORNEA].hasImagesR = true;
			this.currentCategories[Config.CAT_CORNEA].hasImagesR = myImages.length > 0
			return this.corneaImagesRight
		} else if (eye == LEFT) {
			this.corneaImagesLeft = myImages
			//this.hasImagesL = (myImages.length >0);
			// 20.08.2020 fix!
			//this.currentCategories[Config.CAT_CORNEA].hasImagesL = true;
			this.currentCategories[Config.CAT_CORNEA].hasImagesL = myImages.length > 0
			return this.corneaImagesLeft
		} else {
			return myImages
		}
	}

	// 22.09.2020
	private initFundusImages(eye): ExamImage[] {
		let myImages = []

		// inizializza, se non gia' fatto
		if (this.currentCategories[Config.CAT_FUNDUS] == null) {
			this.getFundusExams()
		}

		// 03.06.2020 test dannoso x report diagnosi ? [ls]
		if (this.currentCategories[Config.CAT_FUNDUS] == null) {
			Util.debug('(initFundusImages) ko category!')
			return myImages
		}

		if (eye != null) {
			Util.debug('(initFundusImages) calc for eye ' + eye)

			let exm: FundusExam
			exm = this.getCategoryExam(Config.CAT_FUNDUS, Config.EXM_FUNDUS, eye)
			if (exm != null) {
				let examImg = new ExamImage()
				examImg.eye = eye
				examImg.date = exm.exam_date
				examImg.examId = exm.id
				examImg.type = Config.EXM_FUNDUS

				// tutte 8 le fissazioni, ordine che c'e' sul nexy

				// 25.08.2022 - 12.07.2022 uso un loop sulle fissazioni -> nome campo
				for (let i = 0; i < Config.descrImgFundus.length; i++) {
					let fix = Config.descrImgFundus[i]
					let key = 'image_' + fix

					//Util.debug(i+"(initFundusImages) looking for "+key);

					if (exm.hasOwnProperty(key)) {
						let myImg = exm[key]
						if (myImg) {
							//Util.debug(i+"(initFundusImages) found img "+key);
							let exF = new ExamImage(examImg) // copia
							exF.image = myImg
							exF.descr = fix // 0 = "central";
							exF.position = i
							if (exm.extras) {
								// 12.07.2022
								let extraF = exm.getExtraByFixation(exF.descr) // fissazione
								if (extraF) {
									exF.quality = extraF.quality
									exF.imgId = extraF.id
								}
							}
							myImages.push(exF)
						}
					}
				}

				/* 12.07.2022 usato loop, vd sopra [ls]

        if(exm.image_central != null) {
          let exF = new ExamImage(examImg); // copia
          exF.image = exm.image_central;
          exF.descr = Config.descrImgFundus[0];  // "central";
          exF.position = 0;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_nasal != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_nasal;
          //exF.descr = "nasal";
          exF.descr = Config.descrImgFundus[1];   // 01.04.2022 centralizzato
          exF.position = 1;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_supero_nasal != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_supero_nasal;
          //exF.descr = "supero-nasal";
          exF.descr = Config.descrImgFundus[2];   // 01.04.2022 centralizzato
          exF.position = 2;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_supero_temporal != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_supero_temporal;
          //exF.descr = "supero-temporal";
          exF.descr = Config.descrImgFundus[3];   // 01.04.2022 centralizzato
          exF.position = 3;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_temporal != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_temporal;
          //exF.descr = "temporal";
          exF.descr = Config.descrImgFundus[4];   // 01.04.2022 centralizzato
          exF.position = 4;  // 28.09.2020
          myImages.push(exF);
        }


        if(exm.image_inferior != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_inferior;
          //exF.descr = "inferior";
          exF.descr = Config.descrImgFundus[5];   // 01.04.2022 centralizzato
          exF.position = 5;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_external != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_external;
          //exF.descr = "external";
          exF.descr = Config.descrImgFundus[6];   // 01.04.2022 centralizzato
          exF.position = 6;  // 28.09.2020
          myImages.push(exF);
        }

        if(exm.image_central_nasal != null) {
          let exF = new ExamImage(examImg);
          exF.image = exm.image_central_nasal;
          //exF.descr = "central-nasal";
          exF.descr = Config.descrImgFundus[7];   // 01.04.2022 centralizzato
          exF.position = 7;  // 28.09.2020
          myImages.push(exF);
        }

        */
			} else {
				Util.debug('(initFundusImages) null exam, eye ' + eye)
			}
		}

		Util.debug('(initFundusImages) eye ' + eye + ' tot ' + myImages.length)

		if (eye == RIGHT) {
			this.fundusImagesRight = myImages
			this.currentCategories[Config.CAT_FUNDUS].hasImagesR = myImages.length > 0
			return this.fundusImagesRight
		} else if (eye == LEFT) {
			this.fundusImagesLeft = myImages
			this.currentCategories[Config.CAT_FUNDUS].hasImagesL = myImages.length > 0
			return this.fundusImagesLeft
		} else {
			return myImages
		}
	}

	// 23.06.2020 sostituita globale con funzione su array [ls]
	hasMiddleInfo(categName) {
		let ret = false
		if (this.currentCategories[categName] != null) {
			ret = this.currentCategories[categName].hasMiddleInfo

			// 19.04.2022 patch ?
			if (!ret && this.isWizard) {
				this.enableDtOnDiagnosi(categName, false)
			}
		} else {
			Util.debug('(hasMiddleInfo) ko categ ' + categName)
		}
		return ret
	}

	// 23.06.2020 sostituite globali hasImagesR ed L con funzione su array [ls]
	//per categoria
	hasImages(categName, eye?) {
		let ret = false

		// 26.08.2020 patch
		if (!this.currentCategories[categName]) return false

		if (eye == null) {
			ret = this.currentCategories[categName].hasImagesL || this.currentCategories[categName].hasImagesR
		} else if (eye == LEFT) ret = this.currentCategories[categName].hasImagesL
		else if (eye == RIGHT) ret = this.currentCategories[categName].hasImagesR

		// solo per test
		//Util.debug("(hasImages) "+categName+" "+eye+" : "+ret);

		return ret
	}

	// 01.07.2020 PATCH per DRYEYE
	hasDryEyeImage(eye) {
		let ret = false
		if (eye == LEFT) ret = this.hasDryEyeImageLeft
		else if (eye == RIGHT) ret = this.hasDryEyeImageRight

		return ret
	}

	// 22.07.2020
	hasPachyImage(eye) {
		let ret = false
		if (eye == LEFT) ret = this.hasPachyImageLeft
		else if (eye == RIGHT) ret = this.hasPachyImageRight

		return ret
	}

	// 17.08.2020 uso la globale direttamente [ls]
	hasWfImage(eye) {
		let ret = false
		if (eye == LEFT) ret = this.hasWfImageLeft
		else if (eye == RIGHT) ret = this.hasWfImageRight

		return ret
	}

	// passa di qui in chiusura del wizard di diagnosi e di se stesso in view esami
	dismissModal(repId?) {
		//if(this.currentModal){
		Util.debug('(categories) dismiss') // ok, anche dopo report sent
		// console.log(repId)
		let esito = false

		// 21.10.2021 se era una diagnosi, faccio unlock
		if (this.isWizard) {
			Util.debug('(categories) wizard, rep sent ? ' + this.reportSent)
			esito = this.reportSent

			if (!this.reportSent) {
				let msg = this.translator.instant('REPORT.DISMISS_MSG')
				this.confirmModal(msg, esito)
			} else {
				this.activeModal.close(esito)
				this.session.unlockPatient('' + this.currentPatient.id, repId)
			}
			// this.session.unlockPatient('' + this.currentPatient.id)
		} else {
			this.activeModal.close(esito)
		}

		// 25.03.2022 uso close per gestire esito
		//this.activeModal.dismiss(this.reportSent);
		//this.activeModal.close(this.reportSent);

		//}
	}
	confirmModal(msg: string, esito: boolean) {
		let confirmModal = this.modalService.open(ConfirmModal, { size: 'l', keyboard: false, backdrop: 'static' })

		let repId = 0

		confirmModal.componentInstance.isExit = false
		confirmModal.componentInstance.isQuest = true
		confirmModal.componentInstance.warnText = msg

		confirmModal.result
			.then((result) => {
				// console.log(result)

				if (result) {
					// do nothing, rimango dentro
				} else {
					// exit
					this.activeModal.close(esito)
					return
				}
			})
			.catch(() => {
				this.session.unlockPatient('' + this.currentPatient.id, repId)
				this.activeModal.close(esito)
				Util.debug('(categories) closing confirm modal, discard changes')
				return
			})
	}

	// genera il pdf della visita con gli esami (senza diagnosi)
	generatePdf() {
		// 06.07.20 non funziona da sistemare
		this.openWaitPdfModalExm()

		// usiamo la stessa classe anche se non e' di diagnosi
		let reportPdf = new DiagnosisReport(this.session, this.dataService, this.translator, this.currentPatient)

		reportPdf.setReportType('exams') // 24.08.2022

		// 23.04.2020 imposta le categorie attive
		let currentActiveCats = this.getActiveCategories()

		// console.log(currentActiveCats)

		reportPdf.setActiveCategories(currentActiveCats)

		Util.debug('(generatePdf) step1 - categories set')

		if (this.visitId != null) {
			let currVisit = this.session.getDtVisit(this.visitId)
			if (currVisit != null) {
				reportPdf.setVisitDate(currVisit.date)

				// 24.08.2022 aggiunto anche il nome del device
				//let visInfo = currVisit.name;
				let visInfo = currVisit.name + ' ' + currVisit.device
				reportPdf.setVisitName(visInfo) // 06.10.2021

				Util.debug('(generatePdf) step2 ' + currVisit.date)
			}
		}

		// chiamare il pdf solo quando ha ricevuto tutte le categorie
		// verifica se ci sono gia' o le richiede ora
		this.loadAllActiveCategories().then(() => {
			// 03.01.2022 init inputs for html - serve anche per il pdf ?
			// scrive errori sulla console, poi pdf ok lo stesso
			// quello che su angularjs era fatto con ng-init su pg html

			// dopo che le ha caricate, le passo al pdf
			Util.debug('(generatePdf) step3 caricate tutte, imposto le images...')

			if (this.isCatEnabled(Config.CAT_ANTERIOR)) {
				this.initCategsInput(Config.CAT_ANTERIOR) // 03.01.2022 serve anche per il pdf ?

				// 25.06.2020 per il pdf passo solo la external-automatica e quella del dryeye [ls]
				let imagesR = this.getAnteriorImages(RIGHT)
				let imagesL = this.getAnteriorImages(LEFT)
				reportPdf.setImagesAnterior(imagesR, imagesL)
			}

			if (this.isCatEnabled(Config.CAT_CORNEA)) {
				this.initCategsInput(Config.CAT_CORNEA) // 03.01.2022 serve anche per il pdf ?

				let imagesR = this.getCorneaImages(RIGHT)
				let imagesL = this.getCorneaImages(LEFT)
				reportPdf.setImagesCornea(imagesR, imagesL)
			}

			// 22.07.2020
			if (this.isCatEnabled(Config.CAT_GLC)) {
				this.initCategsInput(Config.CAT_GLC) // 03.01.2022 serve anche per il pdf ?

				// pachy, la grid del wf, la retro
				let imagesR = this.getGlcImages(RIGHT)
				let imagesL = this.getGlcImages(LEFT)
				reportPdf.setImagesGlaucoma(imagesR, imagesL)
			}

			// 23.09.2020
			if (this.isCatEnabled(Config.CAT_FUNDUS)) {
				this.initCategsInput(Config.CAT_FUNDUS) // 03.01.2022 serve anche per il pdf ?

				let imagesR = this.getFundusImages(RIGHT)
				let imagesL = this.getFundusImages(LEFT)
				reportPdf.setImagesFundus(imagesR, imagesL)
			}

			// 13.10.2020
			if (this.isCatEnabled(Config.CAT_REFRACTION)) {
				this.initCategsInput(Config.CAT_REFRACTION) // 03.01.2022 serve anche per il pdf ?

				let flag = this.refrCombinedData()
				Util.debug('(generatePdf) set flag combi: ' + flag)
				reportPdf.setCombinedRefraction(flag)
			}

			Util.debug('(generatePdf) step4, setting the patient')
			if (!this.currentPatient) {
				this.currentPatient = this.session.getDtPatient()
			}
			reportPdf.printPdf(this.currentPatient)
			// if (this.followUpForm.valid) {

			// } else {
			// 	reportPdf.printPdf(this.currentPatient, false)
			// }

			/*
      // 05.10.2020 portato dentro 
      // 06.07.20 non funziona da sistemare
      if(this.modalWaitPdfExam != null){
        Util.debug("(generatePdf) close the loader.");
        this.modalWaitPdfExam.dismiss();
      }*/
			this.isGeneratingPdf = false // il printPdf avvia anche il download
		})
	}

	// per il pdf
	loadAllActiveCategories() {
		let allDone = []
		for (let i = 0; i < Config.CATEG_NAMES.length; i++) {
			let catName = Config.CATEG_NAMES[i]

			// console.log(catName)

			if (this.isCatEnabled(catName)) {
				Util.debug('(loadAllActiveCategories) ' + i + ' cat ' + catName) // 19.06.2020

				if (catName != Config.CAT_ANAMNESIS) {
					if (!this.dataService.hasLoadedCategory(catName)) {
						// avvia la richiesta di download degli esami per questa categoria
						let myList = this.getCategoryList(catName)

						// console.log(myList)
						allDone.push(this.session.loadCategoryExams(myList, catName))
					}
				}
			}
		}
		return Promise.all(allDone)
	}

	// 28.05.2020 modifiche: tutte le info su struttura ExamImage [ls]
	// 08.05.20 set images to display on report, called by album.ts
	//selectImgForPdf(img, checked, imgPos) {
	private selectImgForPdf(img: ExamImage, imgPos) {
		if (img.eye == RIGHT) {
			// 28.05.2020
			for (let i = 0; i < this.corneaImagesRight.length; i++) {
				Util.debug('(selImgForPdf) (right) ' + imgPos + ' ind ' + i + ' sel? ' + this.corneaImagesRight[i].selected)
			}

			/*
      if (checked)       
        this.arrImagesR[imgPos] = img;
       else
        this.arrImagesR[imgPos] = null;
      */
		}

		if (img.eye == LEFT) {
			//if (checked)
			if (img.selected) this.arrImagesL[imgPos] = img
			else this.arrImagesL[imgPos] = null
		}

		Util.debug('(selImgForPdf) SELECTED RIGHT: ' + this.arrImagesR)
		Util.debug('(selImgForPdf) SELECTED LEFT: ' + this.arrImagesL)
	}

	// 08.05.20 save category diagnosis
	saveDiagnosis() {
		this.disableSend = true // disabilito subito il pulsante
		// 01.09.2021 quando si e' nella 9, qui this.activeTab e' nullo ?! su currCategName ho l'ultima
		Util.debug('(saveDiagnosis) categ: ' + this.currCategName + ' ative tab: ' + this.activeTab)
		let rc = false

		// 24.08.2020 salviamo anche su sessionStorage
		let currSrvCateg = null
		// console.log(this.currCategName)
		// 19.06.2020
		//switch (this.currentCategory.name) {
		switch (this.currCategName) {
			case Config.CAT_ANAMNESIS:
				this.isAnamnesiComplete = true

				this.totCatDone++

				this.disableSend = false

				rc = true

				break

			case Config.CAT_REFRACTION:
				rc = this.saveRefractionDiagnosis()

				if (rc == true) {
					this.isRefractionComplete = true

					this.disableSend = false

					currSrvCateg = new SrvDiagnosis(this.diagnRefraction)
					currSrvCateg.setExamList(this.ctgExamsRefraction) // TODO

					// console.log(currSrvCateg)
				}
				break

			case Config.CAT_ANTERIOR:
				rc = this.saveAnteriorDiagnosis()
				if (rc == true) {
					this.isAnteriorComplete = true

					this.disableSend = false

					// 24.08.2020 anticipato qui x salvare in locale
					currSrvCateg = new SrvDiagnosis(this.diagnAnterior)
					currSrvCateg.setExamList(this.ctgExamsAnterior)

					// console.log(currSrvCateg)
				}
				break

			case Config.CAT_CORNEA:
				rc = this.saveCorneaDiagnosis()
				if (rc == true) {
					this.isCorneaComplete = true

					this.disableSend = false

					// 24.08.2020 anticipato qui
					currSrvCateg = new SrvDiagnosis(this.diagnCornea)
					currSrvCateg.setExamList(this.ctgExamsCornea)
					//Util.debug("(saveDiagnosis): cornea, icd1: "+srvCategC.icd1); // ok

					// console.log(currSrvCateg)
				}
				break

			case Config.CAT_GLC:
				rc = this.saveCatGlcDiagnosis()
				if (rc == true) {
					this.isCatGlcComplete = true

					this.disableSend = false

					currSrvCateg = new SrvDiagnosis(this.diagnGlaucoma)
					currSrvCateg.setExamList(this.ctgExamsGlaucoma)

					// console.log(currSrvCateg)
				}
				break

			case Config.CAT_FUNDUS:
				rc = this.saveFundusDiagnosis()
				if (rc == true) {
					this.isFundusComplete = true

					this.disableSend = false

					currSrvCateg = new SrvDiagnosis(this.diagnFundus)
					currSrvCateg.setExamList(this.ctgExamsFundus)

					// console.log(currSrvCateg)
				}
				break
		}

		// 07.07.2020
		Util.debug('(saveDiagnosis) completed ' + this.totCatDone + ' over ' + this.totEnabledCategories + ', currTab: ' + this.activeTab)

		// 07.07.2020
		if (rc == true) {
			this.currentCategories[this.currCategName].hasReportDone = true

			// 01.09.2021 sposto sotto
			// se completate tutte, ritorna 9

			// 24.08.2020 salvo anche su local storage
			if (window.sessionStorage) {
				// abilitato

				//key = "P"+ usrId + "_"+this.patientId + "_ctg_"+categ;
				let key = this.getStorageKey(this.currCategName)

				if (currSrvCateg) {
					// 08.04.2022 add current diagnosis_group
					currSrvCateg.diagn_group = this.session.userDiagnosisGroup()

					sessionStorage.setItem(key, JSON.stringify(currSrvCateg))
				}

				// NB: limite di circa 5 MB
				// valutare se tenere per tutti i pazienti della sessione

				//24.08.2020 TEST Read item
				//let item = JSON.parse(sessionStorage.getItem(key));
				//Util.debug("sessionStorage: ["+key+"] got: ");
				//Util.debug(item);  // qui ok
				// ok
				//let srvCategTest = new SrvDiagnosis(item);
				//Util.debug("sessionStorage: ["+key+"] obj "+srvCategTest.category+" "+srvCategTest.details);
			}
		} else if (rc == false) {
			// 10.01.2023 PATCH: se finito tutto riguardo i tab, senza salvare, e poi torno alla fine, arrivo qui con rc = false
			if (this.totCatDone >= this.totEnabledCategories && this.activeTab == this.END_TAB) {
				Util.debug('(saveDiagnosis) continue, just checked already saved tabs')
			} else {
				this.disableSend = false
				// 12.05.2022 patch
				console.log('(saveDiagnosis) something went wrong...')
				return
			}
		} else {
			// ??
			Util.debug('(saveDiagnosis) ?! ' + rc)
		}

		// 01.09.2021 FIX
		if (this.totCatDone < this.totEnabledCategories) {
			// 01.09.2021 sposto qui calcolo prox tab
			let nextTodo = this.getNextCategoryToReview() // se completate tutte, ritorna 9

			// 18.05.2022 meglio simulare l'evento del tab ?! no
			//if(this.tabset != null){
			//Util.debug("(saveDiagnosis) using select tab...");
			//this.tabset.select(nextTodo);  // ko, pg bianca...
			//}	else {
			this.setActiveTab(nextTodo)
			this.loadCategory(nextTodo) // 05.04.2022 fix, altrimenti compare pg bianca
			//}

			this.allCatDone = false

			return // 07.10.2021 uscire? [ls]

			// 19.08.2021 test con maggiore uguale xche' se rivedo una gia' fatta, mi aumenta...
			// check if all categories are filled
			//if (this.totCatDone == 1) {  // 07.07.2020
			//if (this.totCatDone >= this.totEnabledCategories) {
		} else {
			this.btnSaveDiagnosis = this.translator.instant('BUTTONS.GENERATE_REPORT') // 22.0.1.2021 "Generate Report";
			this.allCatDone = true

			// 01.09.2021 FIX, per qualche motivo il 9 non lo tiene...
			//if(this.activeTab == null || this.activeTab == 9){
			if (this.activeTab == null || this.activeTab === 'END') {
				// 22.12.2021
				// siamo gia' alla fine, non esco
				Util.debug('(saveDiagnosis) going to save on server...') // 01.09.2021 solo per test
			} else {
				// 01.09.2021 sposto qui calcolo prox tab
				// se completate tutte, ritorna 9
				let nextTodo = this.getNextCategoryToReview()
				Util.debug('(saveDiagnosis) ended, from: ' + this.activeTab + ' next: ' + nextTodo + ' allDone? ' + this.allCatDone)
				this.setActiveTab(nextTodo)

				// 13.05.2022 patch
				if (nextTodo != 'END') {
					this.loadCategory(nextTodo) // 13.05.2022 fix, altrimenti compare pg bianca
				}

				return // 08.07.2020 uscire [ls]
			}
		}

		// console.log(this.currentCategories)

		// send to the server ***************
		if (this.allCatDone) {
			this.disableSend = true

			Util.debug('(saveDiagnosis) all done! final step') // 01.09.2021 solo per test

			let fullDiagn = []
			let completedNames = ''
			let finalRx: Prescription[] // 26.10.2020 solo se c'e' la refraction
			finalRx = null

			// ATT: ci sono 2 strutture dati simili ma diverse: una per il FE, una per spedire al server
			// CategDiagnosis e SrvDiagnosis , la prima ha la struttura ICD completa, la seconda ha solo gli id, senza le descr.
			// il costruttore copia la diagnCornea prendo solo i codici degli icd, poi qui aggiungo gli esami

			if (this.currentCategories[Config.CAT_CORNEA] != null && this.currentCategories[Config.CAT_CORNEA].hasReportDone) {
				let srvCategC = new SrvDiagnosis(this.diagnCornea)
				srvCategC.setExamList(this.ctgExamsCornea)
				fullDiagn.push(srvCategC)
				completedNames += Config.CAT_CORNEA + '; '
			}

			// 07.07.2020
			if (this.currentCategories[Config.CAT_ANTERIOR] != null && this.currentCategories[Config.CAT_ANTERIOR].hasReportDone) {
				let srvCategA = new SrvDiagnosis(this.diagnAnterior)

				srvCategA.setExamList(this.ctgExamsAnterior)

				fullDiagn.push(srvCategA)
				completedNames += Config.CAT_ANTERIOR + '; '
			}

			// 26.08.2020
			if (this.currentCategories[Config.CAT_GLC] != null && this.currentCategories[Config.CAT_GLC].hasReportDone) {
				let srvCategG = new SrvDiagnosis(this.diagnGlaucoma)
				srvCategG.setExamList(this.ctgExamsGlaucoma)
				fullDiagn.push(srvCategG)
				completedNames += Config.CAT_GLC + '; '
			}

			// 25.09.2020
			if (this.currentCategories[Config.CAT_FUNDUS] != null && this.currentCategories[Config.CAT_FUNDUS].hasReportDone) {
				let srvCategF = new SrvDiagnosis(this.diagnFundus)
				srvCategF.setExamList(this.ctgExamsFundus)

				fullDiagn.push(srvCategF)
				completedNames += Config.CAT_FUNDUS + '; '
			}

			// 26.10.2020
			if (this.currentCategories[Config.CAT_REFRACTION] != null && this.currentCategories[Config.CAT_REFRACTION].hasReportDone) {
				let srvCategR = new SrvDiagnosis(this.diagnRefraction)
				srvCategR.setExamList(this.ctgExamsRefraction)
				fullDiagn.push(srvCategR)
				completedNames += Config.CAT_REFRACTION + '; '

				// TODO validazione ? solo campi numerici

				finalRx = [] // nuovo

				// 29.08.2022 aggiunto option anche per la num 1, la 2 solo se esiste la 1
				if (this.final1RxEnabled) {
					// 02.09.2022 FIXME, si perde i prime 3 valori, dove abbiamo usato value x esporre la unit
					/*
          <td><input type="text"  (ngModel)="rxFinalR.sphere" [value]="rxFinalR.getSphere()" /></td>
          <td><input type="text"  (ngModel)="rxFinalR.cylinder" [value]="rxFinalR.getCylinder()" /></td>
          <td><input type="text"  (ngModel)="rxFinalR.axis"     [value]="rxFinalR.getAxis()" /></td> 
          */

					finalRx.push(this.rxFinal1L)
					finalRx.push(this.rxFinal1R)

					// 28.10.2020
					if (this.final2RxEnabled) {
						finalRx.push(this.rxFinal2L)
						finalRx.push(this.rxFinal2R)
					}
				}
			}

			// 23.09.2020 tolta richiesta
			/*
			let msg = "Are you sure the report is completed and want to save on the server ? \r\n";
			msg += "Categories done: "+completedNames;  // 08.07.2020 per test [ls]

			if(confirm(msg)){
			*/

			let string = this.followUpForm.get('followUpNote').value
			let followUpMonth: followUp = this.followUpForm.get('followUpMonth').value

			this.session
				.postReport(this.patientId, fullDiagn, finalRx, followUpMonth.val, string)
				.then((resp) => {
					//Util.debug("[categs] ok report posted:");
					//Util.debug(resp);  // dovrebbe contenere l'id del nuovo report

					// console.log(resp)

					this.deleteSessionStorageDiagnosis()

					//if(resp.status == 200 || resp.status == 201) {
					if (resp != null) {
						// 08.02.2022

						let newId = ''

						//if(resp.data && resp.report)
						if (resp.report) {
							newId = resp.report
						}
						Util.debug('ok report sent, id: ' + newId)

						// valutare se togliere il pop-up
						//let msg = "OK report sent!";
						// let msg = this.translator.instant('REPORT.MSG_OK_SENT')
						// alert(msg)

						let header = this.translator.instant('TOAST.HEADER.SUCCESS')
						let body = this.translator.instant('REPORT.MSG_OK_SENT')
						let options = new ToastOptions('success')
						this.toastService.show(header, body, false, options, 'bottom-right')

						this.reportSent = true // 07.10.2021

						// 03.09.2020 TODO mettere a true il visitList.hasReports
						//$parent.hasReports = true;
						// 01.09.2021 TODO fare anche un refresh della visitList, x indicare quelli aggiornati

						//this.currentModal.dismiss();
						this.dismissModal(newId) // 28.10.2021 cosi' fa anche unlock
					}
				})
				.catch((err) => {
					Util.debug('[categs] rep. error:')
					// 11.02.2022 uniformata gestione errori
					let msg = this.session.parseErrorMessage(err, 'alert')
					alert(msg)

					// 02.05.2022 closes the modal
					if (this.session.isExpired(err)) {
						//this.dismissModal();  // 20.05.2022 no, farebbe un'altra richiesta di conferma
						this.activeModal.close(this.reportSent)
					}
				})

			/*  
			} else {
				Util.debug("(saveDiagnosis) not confirmed, close");
				return;
			}
			*/
		}
	}

	private deleteSessionStorageDiagnosis() {
		Util.debug('remove session storrage exams')

		sessionStorage.removeItem('patientId')

		for (let cat of Config.CATEG_NAMES) {
			if (cat != 'anamnesis' && this.currentCategories[cat]) {
				sessionStorage.removeItem(this.getStorageKey(cat))
			}
		}
	}

	// 16.05.2022 patch xche' viene perso passando su element diagnosi ?!
	private setFullICD(diagn: CategDiagnosis, catName: string) {
		// ci sono gli icd senza descrizioni, le forzo
		let tmpIcd: ICD

		let lang = this.session.getLanguage()
		let grp = this.session.userDiagnosisGroup()

		if (diagn.icd1 != null && !diagn.icd1.isEmpty()) {
			tmpIcd = this.dataService.getICDFromCode(diagn.icd1.code, grp, lang, catName)
			if (tmpIcd != null && !tmpIcd.isEmpty()) {
				// potrebbe non trovarlo piu'
				//fullIcd.push(tmpIcd);
				Util.debug('(getFullICD) [pre] icd1: ' + diagn.icd1.code + ' eye: ' + diagn.icd1.eye + ' displ_descr: ' + diagn.icd1.displDescr)
				diagn.icd1 = tmpIcd // sovrascrivo con oggetto completo
				Util.debug('(getFullICD) [post] icd1: ' + diagn.icd1.code + ' eye: ' + diagn.icd1.eye + ' displ_descr: ' + diagn.icd1.displDescr)
			}
		}
		if (diagn.icd2 != null && diagn.icd2.code != '') {
			tmpIcd = this.dataService.getICDFromCode(diagn.icd2.code, grp, lang, catName)
			if (tmpIcd != null && !tmpIcd.isEmpty()) {
				//fullIcd.push(tmpIcd);
				diagn.icd2 = tmpIcd // sovrascrivo con oggetto completo
			}
		}
		if (diagn.icd3 != null && diagn.icd3.code != '') {
			tmpIcd = this.dataService.getICDFromCode(diagn.icd3.code, grp, lang, catName)
			if (tmpIcd != null && !tmpIcd.isEmpty()) {
				diagn.icd3 = tmpIcd // sovrascrivo con oggetto completo
			}
		}

		return
	}

	// 14.10.2020 per refraction, inizializza con valori di SBJ o LSM, se presenti
	initRxFinal() {
		// 28.12.2021 si possono usare le nuove globali, TODO
		let sbjR = this.getCategoryExam(Config.CAT_REFRACTION, Config.EXM_SBJ, RIGHT, true) // 'subjective'
		let sbjL = this.getCategoryExam(Config.CAT_REFRACTION, Config.EXM_SBJ, LEFT, true)

		let lsmR = this.getCategoryExam(Config.CAT_REFRACTION, Config.EXM_LM, RIGHT, true) // 'lensmeter'
		let lsmL = this.getCategoryExam(Config.CAT_REFRACTION, Config.EXM_LM, LEFT, true)

		if (sbjR != null) {
			this.rxFinal1R = new Prescription(sbjR)
		} else if (lsmR) {
			this.rxFinal1R = new Prescription(lsmR)
		} else {
			this.rxFinal1R = new Prescription()
		}

		if (sbjL != null) {
			this.rxFinal1L = new Prescription(sbjL)
		} else if (lsmL) {
			this.rxFinal1L = new Prescription(lsmL)
		} else {
			this.rxFinal1L = new Prescription()
		}

		// forzo cmq eye
		this.rxFinal1R.eye = RIGHT
		this.rxFinal1L.eye = LEFT

		//Util.debug("(initRxFinal) final1");

		// 05.09.2022 PATCH forzo qui le units, poi li tratto come stringhe
		this.rxFinal1R.sphere = this.rxFinal1R.getSphere()
		this.rxFinal1R.cylinder = this.rxFinal1R.getCylinder()
		this.rxFinal1R.axis = this.rxFinal1R.getAxis()

		this.rxFinal1L.sphere = this.rxFinal1L.getSphere()
		this.rxFinal1L.cylinder = this.rxFinal1L.getCylinder()
		this.rxFinal1L.axis = this.rxFinal1L.getAxis()

		// 28.10.2020 final2 uguale ?
		this.rxFinal2L = new Prescription(this.rxFinal1L)
		this.rxFinal2R = new Prescription(this.rxFinal1R)
		this.rxFinal2R.eye = RIGHT
		this.rxFinal2L.eye = LEFT

		// 11.10.2021 se occhio singolo ?
	}

	// 19.05.2022 per refraction, svuota tutto
	clearRxFinal() {
		this.rxFinal1R = new Prescription()
		this.rxFinal1L = new Prescription()
		// forzo cmq eye
		this.rxFinal1R.eye = RIGHT
		this.rxFinal1L.eye = LEFT

		this.rxFinal2L = new Prescription()
		this.rxFinal2R = new Prescription()

		this.rxFinal2R.eye = RIGHT
		this.rxFinal2L.eye = LEFT
	}

	// SAVE REFRACTION DIAGNOSIS
	saveRefractionDiagnosis(): boolean {
		// globale gia' valorizzata dal modal/directive
		Util.debug('(saveRefrDiagnosis) icds: ' + this.diagnRefraction.getMyIcds()) // ha i codici icd, perde il campo eye ?!
		Util.debug('(saveRefrDiagnosis) details:' + this.diagnRefraction.details)
		Util.debug('(saveRefrDiagnosis) treatment:' + this.diagnRefraction.treatment)

		// 05.09.2022
		//Util.debug("(saveRefrDiagnosis) FinalRx 1 R: ");
		//Util.debug(this.rxFinal1R);

		// portati fuori i controlli
		let rc = this.checkTextArea(this.diagnRefraction.details, 'diagnosis details')
		if (!rc) return false

		rc = this.checkTextArea(this.diagnRefraction.treatment, 'treatment')
		if (!rc) return false

		this.setFullICD(this.diagnRefraction, Config.CAT_REFRACTION) // 16.05.2022 valorizza campi che mancavano ?! patch

		// moved on diagnosis element
		// rc = this.checkICDS(this.diagnRefraction)
		// if (!rc) return false

		// 12.05.2022  // 26.04.2022
		rc = this.checkSomething(this.diagnRefraction)
		if (!rc) return false

		// costruisco array degli esami, non ci sono immagini
		// va aggiunto array di prescriptions

		let sogliaMaxImages = 0 // non ci sono immagini ma uso la stessa funzione x valorizzare l'array
		this.ctgExamsRefraction = this.getSelectedImages(null, null, sogliaMaxImages)

		//let totExams = this.ctgExamsRefraction.length;  // 28.02.2022 aggiunto test
		let totExams = 0
		if (this.ctgExamsRefraction != null) {
			totExams = this.ctgExamsRefraction.length
		}
		Util.debug('(saveRefrDiagnosis) tot exam sel ' + totExams)

		let refrCateg = this.currentCategories[Config.CAT_REFRACTION] // 'refraction'

		if (refrCateg == null) {
			Util.debug('(saveRefrDiagnosis) strano, categ nulla?') // 12.10.2021 solo per test iniziale
		}

		/* 11.02.2022 tolto controllo 

    // 11.10.2021 validazione Final1
    // ATT: potrebbe essere su occhio singolo
    //let msg = "Please fill Visual Acuity field(s).";
    let msg = this.translator.instant('REFRACTION.WARN_MSG_DVA'); // 11.10.2021

    if((this.rxFinal1L.DVA == "" && refrCateg != null && refrCateg.hasDataL) ||
       (this.rxFinal1R.DVA == "" && refrCateg != null && refrCateg.hasDataR) ){
      alert(msg);
      return false;
    }

    // validazione Final2
    if(this.final2RxEnabled){
      if( (this.rxFinal2L.DVA == "" && refrCateg != null && refrCateg.hasDataL) ||
          (this.rxFinal2R.DVA == "" && refrCateg != null && refrCateg.hasDataR) ){
        alert("(RX2) "+msg);
        return false;
      }
    }
    */

		this.totCatDone++
		return true
	}

	// SAVE ANTERIOR DIAGNOSIS
	saveAnteriorDiagnosis(): boolean {
		if (this.diagnAnterior != null) {
			// globale gia' valorizzata dal modal/directive
			//Util.debug("(saveAnteriorDiagn) icds: "+this.diagnAnterior.getMyIcds());
			Util.debug('(saveAnteriorDiagn) details:' + this.diagnAnterior.details)
			Util.debug('(saveAnteriorDiagn) treatment:' + this.diagnAnterior.treatment)
		} else {
			Util.debug('(saveAnteriorDiagn) null obj ?!')
		}

		// 07.07.2020 portati fuori i controlli
		let rc = this.checkTextArea(this.diagnAnterior.details, 'diagnosis details')
		if (!rc) return false

		rc = this.checkTextArea(this.diagnAnterior.treatment, 'treatment')
		if (!rc) return false

		this.setFullICD(this.diagnAnterior, Config.CAT_ANTERIOR) // 16.05.2022 valorizza campi che mancavano ?! patch

		Util.debug('(saveAnteriorDiagn) full icds: ' + this.diagnAnterior.getMyIcds()) // 18.05.2022

		// moved on diagnosis element
		// rc = this.checkICDS(this.diagnAnterior)
		// if (!rc) return false

		// 12.05.2022  // 26.04.2022
		rc = this.checkSomething(this.diagnAnterior)
		if (!rc) return false

		// costruisco array degli esami, con scelta delle immagini
		// 07.07.2020 portata fuori la funzione
		// 19.08.2021 problema se ho esame solo parziale, su un unico occhio, crash del pdf
		let sogliaMaxImages = 4
		this.ctgExamsAnterior = this.getSelectedImages(this.anteriorImagesRight, this.anteriorImagesLeft, sogliaMaxImages)

		if (this.ctgExamsAnterior == null) {
			Util.debug('(saveAnteriorDiagn) ko su num. img selezionate')
			// alert su troppe immagini gestito dentro alla funzione sopra, spostare qui ?
			return false
		} else {
			let totExams = this.ctgExamsAnterior.length
			Util.debug('(saveAnteriorDiagn) tot exam sel ' + totExams)
		}

		this.totCatDone++
		return true
	}

	// SAVE CORNEA DIAGNOSIS
	saveCorneaDiagnosis(): boolean {
		// globale gia' valorizzata dal modal
		Util.debug('(saveCorneaDiagnosis) icds: ' + this.diagnCornea.getMyIcds())
		Util.debug('(saveCorneaDiagnosis) details:' + this.diagnCornea.details)
		Util.debug('(saveCorneaDiagnosis) treatment:' + this.diagnCornea.treatment)

		Util.debug('(saveCorneaDiagnosis) display dt table:' + this.diagnCornea.dtEnabled)

		// 07.07.2020 portati fuori i controlli
		let rc = this.checkTextArea(this.diagnCornea.details, 'diagnosis details')
		if (!rc) return false

		rc = this.checkTextArea(this.diagnCornea.treatment, 'treatment')
		if (!rc) return false

		this.setFullICD(this.diagnCornea, Config.CAT_CORNEA) // 16.05.2022 valorizza campi che mancavano ?! patch

		// moved on diagnosis element
		// 10.07.2020 aggiunto
		// rc = this.checkICDS(this.diagnCornea)
		// if (!rc) return false

		// 12.05.2022  // 26.04.2022
		rc = this.checkSomething(this.diagnCornea)
		if (!rc) return false

		//campi a testo libero vanno crittati con keyPhoto, fatto su session
		// costruisco array degli esami, con scelta delle immagini

		// 07.07.2020 portata fuori la funzione
		// 26.04.2022 manca il parametro -> non mandatory le immagini ? verificare
		// 12.05.2022 forzato [ls] - TOTEST
		let maxImg = 4
		this.ctgExamsCornea = this.getSelectedImages(this.corneaImagesRight, this.corneaImagesLeft, maxImg)

		if (this.ctgExamsCornea != null) {
			// 28.02.2022 aggiunto test
			Util.debug('(saveCorneaDiagnosis) tot sel ' + this.ctgExamsCornea.length)
		} else {
			// 26.04.2022 fix
			Util.debug('(saveCorneaDiagnosis) no images!')
			return false
		}

		this.totCatDone++

		return true
	}

	// SAVE CATARACT/GLAUCOMA DIAGNOSIS
	saveCatGlcDiagnosis(): boolean {
		let rc = this.checkTextArea(this.diagnGlaucoma.details, 'diagnosis details')
		if (!rc) return false

		rc = this.checkTextArea(this.diagnGlaucoma.treatment, 'treatment')
		if (!rc) return false

		this.setFullICD(this.diagnGlaucoma, Config.CAT_GLC) // 16.05.2022 valorizza campi che mancavano ?! patch

		// moved on diagnosis element
		// rc = this.checkICDS(this.diagnGlaucoma)
		// if (!rc) return false

		// 12.05.2022  // 26.04.2022
		rc = this.checkSomething(this.diagnGlaucoma)
		if (!rc) return false

		//campi a testo libero vanno crittati con keyPhoto, fatto su session
		// costruisco array degli esami, con scelta delle immagini
		let maxImg = 3
		this.ctgExamsGlaucoma = this.getSelectedImages(this.glcImagesRight, this.glcImagesLeft, maxImg)

		//Util.debug("(saveGlaucomaDiagnosis) tot exams: "+ this.ctgExamsGlaucoma.length);
		if (this.ctgExamsGlaucoma != null) {
			// 28.02.2022 aggiunto test
			Util.debug('(saveGlaucomaDiagnosis) tot exams: ' + this.ctgExamsGlaucoma.length)
		} else {
			Util.debug('(saveGlaucomaDiagnosis) no selected images')
			return false // 26.04.2022 fix
		}

		this.totCatDone++
		return true
	}

	// 25.09.2020
	// SAVE FUNDUS DIAGNOSIS
	saveFundusDiagnosis(): boolean {
		let rc = this.checkTextArea(this.diagnFundus.details, 'diagnosis details')
		if (!rc) return false

		rc = this.checkTextArea(this.diagnFundus.treatment, 'treatment')
		if (!rc) return false

		this.setFullICD(this.diagnFundus, Config.CAT_FUNDUS) // 16.05.2022 valorizza campi che mancavano ?! patch

		// moved on diagnosis element
		// rc = this.checkICDS(this.diagnFundus)
		// if (!rc) return false

		// 12.05.2022  // 26.04.2022
		rc = this.checkSomething(this.diagnFundus)
		if (!rc) return false

		//campi a testo libero vanno crittati con keyPhoto, fatto su session
		// costruisco array degli esami, con scelta delle immagini
		let maxImg = 4
		this.ctgExamsFundus = this.getSelectedImages(this.fundusImagesRight, this.fundusImagesLeft, maxImg)

		//if(this.ctgExamsFundus) // se supera la soglia, ritorna null
		//  Util.debug("(saveFundusDiagnosis) tot exams: "+ this.ctgExamsFundus.length);

		// 01.12.2020 fix
		if (this.ctgExamsFundus == null) {
			Util.debug('(saveFundusDiagnosis) ko, troppe img selezionate')
			// alert su troppe immagini gestito dentro alla funzione sopra
			return false
		} else {
			let totExams = this.ctgExamsFundus.length
			Util.debug('(saveFundusDiagnosis) tot exam sel ' + totExams)
		}

		this.totCatDone++
		return true
	}

	// richiamata al change dei textbox e tendine icd
	// da evento emesso dal diagnosi.component (child)
	// serve ad indicare che questa categ. e' in lavorazione [ls]
	//resetDiagnosis(catName){
	changedDiagnosis(event, catName) {
		//Util.debug("(changedDiagnosis) event: ");
		//Util.debug(event);
		//Util.debug("(resetDiagnosis) "+catName);  // troppe

		if (this.currentCategories[catName] == null) {
			Util.debug('(changedDiagnosis) KO! not found ' + catName)
		} else {
			// 08.07.2020 resetto solo se questa era gia' stata fatta [ls]
			Util.debug('(changedDiagnosis) in ' + catName) // troppe

			if (this.currentCategories[catName].hasReportDone) {
				if (this.totCatDone > 0) {
					this.totCatDone--
				}
			}
			this.currentCategories[catName].hasReportDone = false
		}

		// 15.07.2020 tolgo il flag "verde" dal tab
		if (catName == Config.CAT_ANTERIOR) this.isAnteriorComplete = false
		else if (catName == Config.CAT_CORNEA) this.isCorneaComplete = false
		else if (catName == Config.CAT_GLC) this.isCatGlcComplete = false
		else if (catName == Config.CAT_FUNDUS) this.isFundusComplete = false
		else if (catName == Config.CAT_REFRACTION) this.isRefractionComplete = false
	}

	// 24.08.2020 inizializza il form di diagnosi con i valori salvati in locale (sessionSorage) precedentemente.
	// serve per gestire ripensamenti o simili.
	initDiagnosisForm(catName: string, diagn: SrvDiagnosis) {
		if (diagn.category != catName) {
			Util.debug('(initDiagnForm) KO diverse categ: ' + catName + ' vs ' + diagn.category)
			return false
		}

		Util.debug('(initDiagnForm) categ: ' + catName)
		//Util.debug(diagn);  // icd1,icd2,icd3 sono solo i codici

		//ATT: non sono la stessa classe, il costruttore prende i campi opportunamente

		let myFullDiagn: CategDiagnosis
		myFullDiagn = new CategDiagnosis(diagn)

		Util.debug('(initDiagnForm) loaded icds: ' + myFullDiagn.getMyIcds())

		let lang = this.session.getLanguage()
		let grp = this.session.userDiagnosisGroup()
		let prevDiagnGroup = diagn.diagn_group

		// 12.05.2022 potrebbe esserci un cambio gruppo ?
		if (prevDiagnGroup && prevDiagnGroup != grp) {
			Util.debug('(initDiagnForm) different diagn_group! prev: ' + prevDiagnGroup + ' curr:' + grp)
		}

		let hideIcdsCodes = this.session.hideIcdsCodes(grp) // group 2
		if (hideIcdsCodes) {
			// nascondo anche il primo free text
			myFullDiagn.details = ''
		}

		// 13.05.2022 va in errore sul secondo tab, TODO capire...  ************ FIXME ************
		// 18.05.2022 ci sono gli icd senza descrizioni, le forzo

		let tmpIcd: ICD
		tmpIcd = null
		// new 19-05-2023
		if (diagn.icdLeft.length > 0) {
			for (let i = 0; i < diagn.icdLeft.length; i++) {
				const element = diagn.icdLeft[i]

				tmpIcd = this.dataService.getICDFromCode(element, grp, lang, catName)

				if (tmpIcd != null && !tmpIcd.isEmpty()) {
					// potrebbe non trovarlo piu'
					myFullDiagn.icdLeft[i] = tmpIcd // intero oggetto
				}
			}
		}

		if (diagn.icdRight.length > 0) {
			for (let i = 0; i < diagn.icdRight.length; i++) {
				const element = diagn.icdRight[i]

				tmpIcd = this.dataService.getICDFromCode(element, grp, lang, catName)

				if (tmpIcd != null && !tmpIcd.isEmpty()) {
					// potrebbe non trovarlo piu'
					myFullDiagn.icdRight[i] = tmpIcd // intero oggetto
				}
			}
		}

		//commented 19-05-2023
		// if (diagn.icd1 != null && diagn.icd1 != '') {
		// 	Util.debug('(initDiagnForm) icd1: ' + diagn.icd1)
		// 	tmpIcd = null
		// 	tmpIcd = this.dataService.getICDFromCode(diagn.icd1, grp, lang, catName)
		// 	if (tmpIcd != null && !tmpIcd.isEmpty()) {
		// 		// potrebbe non trovarlo piu'
		// 		myFullDiagn.icd1 = tmpIcd // intero oggetto
		// 	}
		// }
		// if (diagn.icd2 != null && diagn.icd2 != '') {
		// 	tmpIcd = null
		// 	tmpIcd = this.dataService.getICDFromCode(diagn.icd2, grp, lang, catName)
		// 	if (tmpIcd != null && !tmpIcd.isEmpty()) {
		// 		myFullDiagn.icd2 = tmpIcd
		// 	}
		// }
		// if (diagn.icd3 != null && diagn.icd3 != '') {
		// 	tmpIcd = null
		// 	tmpIcd = this.dataService.getICDFromCode(diagn.icd3, grp, lang, catName)
		// 	if (tmpIcd != null && !tmpIcd.isEmpty()) {
		// 		myFullDiagn.icd3 = tmpIcd
		// 	}
		// }

		// ripristina le immagini scelte,
		// 27.08.2020 vd funzione getSelectedImages, bisogna fare il contrario
		// att. che la scelta esami potrebbe esser cambiata, pur nella stessa categoria ?
		let savedExams: CategExam[]
		savedExams = diagn.exam_list // qui ci sono anche le immagini scelte

		//Util.debug("(initDiagnForm) esami salvati per "+catName);
		//Util.debug(savedExams); // ok

		// 31.08.2020 ripristino la scelta immagini, solo se stessi esami
		// fatto su funzione esterna cosi' lavora per riferimento sulle globali ? [ls]

		if (catName == Config.CAT_ANTERIOR) {
			// 18.05.2022 fare un deep clone ?
			this.diagnAnterior = myFullDiagn
			//this.diagnAnterior = JSON.parse(JSON.stringify(myFullDiagn));	// perde le funzioni ?

			// no, qui e' vuoto, l'album ha le anteriorImagesR+L
			//this.setSelectedImages(savedExams, this.ctgExamsAnterior);
			//this.setSelectedImages(savedExams, this.anteriorImages);
		} else if (catName == Config.CAT_CORNEA) {
			this.diagnCornea = myFullDiagn

			// 13.05.2022 intanto tutte uguali?
			// test, intanto solo questa categ
			//this.setSelectedImages(savedExams, this.corneaImagesRight, this.corneaImagesLeft);
		} else if (catName == Config.CAT_GLC) {
			this.diagnGlaucoma = myFullDiagn
		} else if (catName == Config.CAT_REFRACTION) {
			this.diagnRefraction = myFullDiagn
		} else if (catName == Config.CAT_FUNDUS) {
			this.diagnFundus = myFullDiagn
		}

		return true
	}

	//resetDiagnosisForm(){
	// 04.02.2022 per ripulire il form dai campi caricati dalla precedente diagn.
	resetFormDiagnosis() {
		let catName = this.currCategName

		//09-11-22
		// this.session.resetReportForms.emit(catName);

		Util.debug('C (resetFormDiagnosis) ' + catName)

		if (catName == Config.CAT_ANTERIOR) {
			if (this.diagnAnterior) {
				this.diagnAnterior.resetDiagnValues()
			}
		} else if (catName == Config.CAT_CORNEA) {
			if (this.diagnCornea) this.diagnCornea.resetDiagnValues()
		} else if (catName == Config.CAT_GLC) {
			if (this.diagnGlaucoma) this.diagnGlaucoma.resetDiagnValues()
		} else if (catName == Config.CAT_REFRACTION) {
			if (this.diagnRefraction) {
				//Util.debug(this.diagnRefraction);
				this.diagnRefraction.resetDiagnValues()
			}
			// 22.06.2022 meglio di no - Andrea - (bug 187)
			// 19.05.2022 anche le prescriptions
			//this.clearRxFinal();
		} else if (catName == Config.CAT_FUNDUS) {
			if (this.diagnFundus) this.diagnFundus.resetDiagnValues()
		}

		// 19.05.2022 se torno indietro, va tolta la spunta e anche il totDone
		this.changedDiagnosis(null, catName)
	}

	// 01.09.2021 FIXME verificare funzione, fatta solo su un occhio, solo per una categoria,
	// 13.05.2022 serve quando si carica da sessionStorage e quando si torna su un tab precedente ?!
	// 31.08.2020
	// sets the selected images from the savedExams array onto the currentExams, for same exam type and id
	private setSelectedImages(savedExams, currImgsR: ExamImage[], currImgsL: ExamImage[]) {
		if (savedExams != null && currImgsR != null) {
			for (let i = 0; i < savedExams.length; i++) {
				let prevExm = savedExams[i]
				if (prevExm != null) {
					// ciclo sul right
					for (let j = 0; j < currImgsR.length; j++) {
						if (prevExm.exam_id == currImgsR[j].examId) {
							if (prevExm.exam_type == currImgsR[j].type) {
								// trovato!
								let pos = currImgsR[j].position
								Util.debug('(setSelImages) ' + j + ' R same exam ' + prevExm.exam_id + ' ' + prevExm.exam_type + ' enabled ?' + prevExm.imgEnabled[pos] + ' pos:' + pos)

								// TODO! introdurre imgId o usare sequenza con description ?
								// 13.05.2022
								// forzo array di selected sul current
								currImgsR[j].selected = prevExm.imgEnabled[pos]
								if (prevExm.imgEnabled[pos] == true) {
									Util.debug('(setSelImages) R enabled ' + j + ' id: ' + prevExm.exam_id + ' pos:' + pos)
								}
							}
						}
					}

					// 10.01.2023 stesso ciclo sul left
					for (let j = 0; j < currImgsL.length; j++) {
						if (prevExm.exam_id == currImgsL[j].examId) {
							if (prevExm.exam_type == currImgsL[j].type) {
								// trovato!
								let pos = currImgsL[j].position
								Util.debug('(setSelImages) ' + j + ' L same exam ' + prevExm.exam_id + ' ' + prevExm.exam_type + ' enabled ?' + prevExm.imgEnabled[pos] + ' pos:' + pos)

								// forzo array di selected sul current
								currImgsL[j].selected = prevExm.imgEnabled[pos]
								if (prevExm.imgEnabled == true) {
									Util.debug('(setSelImages) L enabled ' + j + ' pos: ' + pos)
								}
							}
						}
					}
				}
			}
		}

		/*
  private setSelectedImages(savedExams, currentExams){

    if(savedExams != null && currentExams != null){
      for(let i=0; i<savedExams.length; i++){
        let prevExm = savedExams[i];
        if(prevExm != null){

          for(let j=0; j<currentExams.length; j++){
            if(prevExm.exam_id == currentExams[j].exam_id){
              if(prevExm.exam_type == currentExams[j].exam_type){
                // trovato!
                Util.debug("(setSelectedImages) same exam "+prevExm.exam_id+" "+prevExm.exam_type);
                // forzo array di selected sul current
                currentExams[j].setImgEnabled(prevExm.imgEnabled);
                Util.debug(currentExams[j].imgEnabled);  // solo per test
              }
            }
          }

        }
      }
    }
    */
	}

	// 07.07.2020
	private checkTextArea(elem, label) {
		let maxlenText = 1000
		// TODO tradurre su json
		// 09.06.2020 controllo maxlen, ko vincolo su html per firefox
		if (elem == null) {
			let msg = 'Please limit the ' + label + ' to ' + maxlenText + ' characters'
			alert(msg)
			return false
		} else {
			return true
		}
	}

	// moved on diagnosis element
	// 10.07.2020
	// controlla non ci siano duplicati
	// private checkICDS(myDiagn: CategDiagnosis) {
	// 	let flag = true

	// 	// 11.10.2021 se sono disab, non vanno validati
	// 	if (!this.session.userIcdsEnabled()) {
	// 		return flag
	// 	}

	// 	Util.debug('(checkICDS) icds: ' + myDiagn.getMyIcds())

	// 	// 28.09.2020 test per non chiedere due volte, anche alla fine
	// 	if (this.totCatDone < this.totEnabledCategories) {
	// 		if (myDiagn.icd1 != null && !myDiagn.icd1.isEmpty() && myDiagn.icd2 != null && !myDiagn.icd2.isEmpty() && myDiagn.icd1.code == myDiagn.icd2.code) {
	// 			flag = false
	// 		} else if (myDiagn.icd1 != null && !myDiagn.icd1.isEmpty() && myDiagn.icd3 != null && !myDiagn.icd3.isEmpty() && myDiagn.icd1.code == myDiagn.icd3.code) {
	// 			flag = false
	// 		} else if (myDiagn.icd2 != null && !myDiagn.icd2.isEmpty() && myDiagn.icd3 != null && !myDiagn.icd3.isEmpty() && myDiagn.icd2.code == myDiagn.icd3.code) {
	// 			flag = false
	// 		}

	// 		if (flag == false) {
	// 			Util.debug('(checkICDS) ko! two identical...')
	// 			//let msg = "There are two identical ICDS values: do you want to proceed anyway ?";
	// 			let msg = this.translator.instant('DIAGNOSI.IDENTICAL_ICDS')
	// 			if (confirm(msg)) flag = true
	// 		}

	// 		// 15.04.2022 controllo consistenza notGradable o Healthy
	// 		// 16.05.2022 qui gli oggetti icd hanno solo i codici, non eye o altro

	// 		let valid = myDiagn.validateIcdPerEye()

	// 		if (!valid && flag) {
	// 			Util.debug('(checkICDS) not valid per eye... ')
	// 			let msg = this.translator.instant('DIAGNOSI.CONFLICT_ICDS')
	// 			//let msg = "There are some choices apparently in conflict, per eye.\nDo you want to proceed anyway ?";
	// 			if (confirm(msg)) flag = true
	// 			else flag = false
	// 		}
	// 	} else {
	// 		Util.debug('(checkICDS) icds, chiesto prima...')
	// 	}

	// 	return flag
	// }

	// 12.05.2022 presa da angularjs
	// 26.04.2022 controlla che sia stato scritto qualcosa:
	// - o uno dei due free text, o almeno un ICD
	private checkSomething(myDiagn: CategDiagnosis) {
		let done = false
		let grp = this.session.userDiagnosisGroup()

		// almeno un icd
		if (this.session.userIcdsEnabled()) {
			// ok anche gruppo 2, sono solo nascosti
			if (
				// (myDiagn.icd1 != null && !myDiagn.icd1.isEmpty()) ||
				// (myDiagn.icd2 != null && !myDiagn.icd2.isEmpty()) ||
				// (myDiagn.icd3 != null && !myDiagn.icd3.isEmpty())

				myDiagn.icdLeft.length > 0 &&
				myDiagn.icdRight.length > 0
			) {
				done = true
				return done
			}
		}

		// per gruppi diversi dal 2, ok anche qlc sui testi liberi
		if (!this.session.hideIcdsCodes(grp)) {
			// almeno details
			if (myDiagn.details != null && myDiagn.details != '') {
				done = true
				return done
			}

			// almeno suggestions
			if (myDiagn.treatment != null && myDiagn.treatment != '') {
				done = true
				return done
			}
		}

		// 28.09.2020 test per non chiedere due volte, anche alla fine
		if (!done && this.totCatDone < this.totEnabledCategories) {
			let msg = this.translator.instant('DIAGNOSI.REQUIRED_FIELDS')
			//let msg = "Please fill one of the fields in the form";
			alert(msg)
		} else {
			Util.debug('(checkSomething) chiesto prima...')
		}

		return done
	}

	// 10.07.2020 aggiunto controllo su sogliaMax immagini selezionate x occhio
	// 07.07.2020 ritorna l'array degli esami, con la scelta delle immagini
	// oppure null se superano la sogliaMax (fa qui l'alert)
	private getSelectedImages(imagesRight, imagesLeft, sogliaMax?): CategExam[] {
		let ctgExams: CategExam[]
		ctgExams = []

		let totR = 0 // conta le img selezionate
		let totL = 0

		for (let i = 0; i < this.currentCategoryExams.length; i++) {
			let exm = this.currentCategoryExams[i]
			let currCategExam = new CategExam()
			currCategExam.exam_id = exm.id
			currCategExam.exam_type = exm.exam_type

			// testare
			// vd pat2, ha 2 esami con stesso id 6, uno topo e uno pachyMult
			currCategExam.imgEnabled = []

			// 29.09.2020 inizializzo a false su lunghezza fissa [ls]
			let maxImages = 8 // tenere allineato con colonne imgN su DB categ_exams

			for (let j = 0; j < maxImages; j++) {
				currCategExam.imgEnabled[j] = false
			}

			if (exm.eye == RIGHT && imagesRight != null) {
				for (let j = 0; j < imagesRight.length; j++) {
					// 07.07.2020 aggiunto test anche su exam_type
					if (imagesRight[j].examId == exm.id && imagesRight[j].type == exm.exam_type) {
						// ***  28.09.2020 ***********************
						// FIX: non faccio il push ma vado puntuale con il nuovo campo position [ls]
						// serve per es. con le fissazioni, se non le carico tutte, poi la sequenza cambia

						//currCategExam.imgEnabled.push(imagesRight[j].selected);
						let pos = imagesRight[j].position
						currCategExam.imgEnabled[pos] = imagesRight[j].selected
						Util.debug('(getSelectedImages) R [' + j + '] pos ' + pos + ' sel: ' + imagesRight[j].selected)
						// ***********************************

						if (imagesRight[j].selected) totR++ // 10.07.2020
					}
				}
			} else if (exm.eye == LEFT && imagesLeft != null) {
				for (let j = 0; j < imagesLeft.length; j++) {
					if (imagesLeft[j].examId == exm.id && imagesLeft[j].type == exm.exam_type) {
						// ***  28.09.2020 ***********************
						// FIX: non faccio il push ma vado puntuale con il campo position [ls]

						//currCategExam.imgEnabled.push(imagesLeft[j].selected);
						let pos = imagesLeft[j].position
						currCategExam.imgEnabled[pos] = imagesLeft[j].selected
						Util.debug('(getSelectedImages) L [' + j + '] pos ' + pos + ' sel: ' + imagesLeft[j].selected)
						// ***********************************

						if (imagesLeft[j].selected) totL++ // 10.07.2020
					}
				}
			}

			// 26.08.2021 tolta trace
			//Util.debug("(getSelectedImages) ["+i+"] exam:");
			//Util.debug(currCategExam);

			// 10.07.2020 alert qui o throw exception ??
			// controllo su max 4 immagini selezionate x occhio
			if (sogliaMax != null && sogliaMax > 0) {
				if (totR > sogliaMax || totL > sogliaMax) {
					Util.debug('(getSelectedImages) [' + i + '] KO, selected ' + totR + ' imgs right and ' + totL + ' left.')

					//let msg = "Please select at most "+sogliaMax+" images per eye.";
					let msg = '' // 16.05.2022 patch, trovare il modo di passare i parametri al json
					if (sogliaMax == 4) {
						msg = this.translator.instant('DIAGNOSI.TOO_MANY_IMG_4')
					} else {
						msg = 'Please select at most ' + sogliaMax + ' images per eye.'
					}
					alert(msg)

					return null
				}
			}

			ctgExams.push(currCategExam)
		}

		// 26.08.2021 FIX, gestione caso se non ci sono img
		if (this.currentCategories[this.currCategName].hasImagesR || this.currentCategories[this.currCategName].hasImagesL) {
			// almeno una img c'e'
			// 18.08.2021 aggiunto controllo su zero immagini
			if (sogliaMax != null && sogliaMax > 0) {
				if (totR == 0 && totL == 0) {
					// "Please select at least one image.";
					let msg2 = this.translator.instant('DIAGNOSI.WARNING_ZERO_IMG')
					alert(msg2)
					return null
				}
			}
		} else {
			Util.debug('(getSelectedImages) ctg ' + this.currCategName + ' senza immagini')
		}
		Util.debug('(getSelectedImages) ctg ' + this.currCategName + ' OK selected ' + totR + ' imgs right and ' + totL + ' left.')

		return ctgExams
	}

	// ativa solo un div sul modal, poi css
	public openWaitPdfModalExm() {
		Util.debug('(openWaitPdfModal) inizio') // qui arriva, poi fallisce su .open
		this.isGeneratingPdf = true

		//let title = "Generating PDF with exams...";
		//this.modalWaitPdfExam = this.currentModal.open({ ... }  // ko
	}

	onNavChange(event) {
		let nextCateg = event.nextId
		Util.debug('C (onNavChange) event, next categ: ' + nextCateg)
		//Util.debug(event);

		//this.selectActiveTab(); inutile, lo farebbe cmq,
		// ma ora ho this.tabset != null

		// prende tabChange da html solo se e' il primo load ?
		this.loadCategory(nextCateg)
	}

	/*
  // arriva qui dopo il onNavChange
  onNavActiveChange(event){
    Util.debug("(categories) - onNavActiveChange, active: "+this.activeTab); // gia' quello nuovo
  }
  */

	/*
  private selectActiveTab(){
    if(this.tabset!= null){
      Util.debug("(categories) - selectActiveTab - ok tabset ");
      this.tabset.select(this.activeTab);
    } else {
      Util.debug("(categories) - selectActiveTab - null tabset");
    }
  }
  */

	passBack(myTag) {
		Util.debug('C (passBack) modal closed! ' + myTag)
		if (myTag == 'pdf') {
			this.generatePdf() // Then close... TODO
		}

		// TODO
		//this.activeModal.close(changedData);
		//this.activeModal.close(myTag);
	}

	// ********** Additions *****************  22.11.2022 per ora solo fundus da VX610

	private initAdditionsList(catName: string) {
		// in questa categoria ho al piu' 2 esami fundus (uno per occhio)
		let examIdR = 0 // TODO
		let examIdL = 0
		let examType = Config.EXM_FUNDUS

		if (catName == Config.CAT_FUNDUS) {
			//if (this.currentCategories[catName] this.fundusExams != null && this.fundusExams.length > 0) {  // ko
			if (this.currentCategories[catName] && this.currentCategories[catName].examList.length > 0) {
				examType = Config.EXM_FUNDUS

				let myList: FundusExam[]
				myList = this.currentCategories[catName].examList

				if (myList[0].eye == RIGHT) {
					examIdR = myList[0].id
				} else {
					examIdL = myList[0].id
				}

				// se esiste il secondo occhio
				if (myList[1]) {
					if (myList[1].eye == RIGHT) {
						examIdR = myList[1].id
					} else if (myList[1].eye == LEFT) {
						examIdL = myList[1].id
					}
				}
				Util.debug('(initAdditionsList) RIGHT ' + examIdR + ' left: ' + examIdL)
			} else {
				Util.debug('(initAdditionsList) ko exam list!')
				return
			}
		} else {
			Util.debug('(initAdditionsList) not for categ ' + catName)
			return
		}

		Util.debug('(initAdditionsList) start request...')

		if (examIdR > 0) this.loadAdditionsList(examType, examIdR, RIGHT)
		if (examIdL > 0) this.loadAdditionsList(examType, examIdL, LEFT)
	}

	private loadAdditionsList(examType: string, examId: number, eye: string) {
		this.session
			.loadAdditions(examType, examId)
			.then((addsList) => {
				if (!addsList) {
					Util.debug('(getAddsList) got NULL additions list for ' + examType + ' id ' + examId)
				} else if (addsList.length == 0) {
					Util.debug('(getAddsList) got empty additions list for ' + examType + ' id ' + examId)
				} else {
					Util.debug('(getAddsList) got additions list: ' + addsList.length + ' for ' + examType + ' id ' + examId)

					if (eye == RIGHT) {
						this.additionsR = addsList
					} else if (eye == LEFT) {
						this.additionsL = addsList
					}
				}
				return true
			})
			.catch((err) => {
				Util.debug('(getAddsList) no additions for ' + examType + ' id ' + examId)
				return false
			})
	}

	// 23.11.2022
	hasAdditions(eye: string) {
		let flag = false

		if (eye == RIGHT) {
			flag = this.additionsR && this.additionsR.length > 0
		} else if (eye == LEFT) {
			flag = this.additionsL && this.additionsL.length > 0
		} else {
			// any eye...
			flag = (this.additionsL && this.additionsL.length > 0) || (this.additionsR && this.additionsR.length > 0)
		}

		return flag
	}

	getTotAdditions(eye: string) {
		let flag = false
		let tot = 0
		if (eye == RIGHT) {
			flag = this.additionsR && this.additionsR.length > 0
			if (flag) {
				tot = this.additionsR.length
			}
		} else if (eye == LEFT) {
			flag = this.additionsL && this.additionsL.length > 0
			if (flag) {
				tot = this.additionsL.length
			}
		}

		return tot
	}

	// 23.11.2022
	getAdditionImages(eye: string): ExamImage[] {
		//return this.getCategImages(eye, Config.CAT_FUNDUS)

		let imageList: ExamImage[]
		let exm: Addition
		let myList

		if (eye == RIGHT) {
			myList = this.additionsR
		} else if (eye == LEFT) {
			myList = this.additionsL
		}

		if (!myList || myList.length == 0) {
			return null
		}

		imageList = [] // creo vuoto

		for (let i = 0; i < myList.length; i++) {
			exm = myList[i]

			//console.log(exm);

			if (exm != null && exm.image) {
				let examImg = new ExamImage()
				examImg.eye = eye
				examImg.date = exm.creationDate
				examImg.examId = exm.id

				//examImg.type = Config.EXM_FUNDUS;  // TODO, altro?
				examImg.type = Config.EXM_ADDITION // 25.11.2022 poi su questo nasconde il display on reports

				examImg.image = exm.image

				examImg.position = i + 1
				examImg.imgId = exm.id

				examImg.descr = exm.fixation
				if (exm.isCDR) {
					examImg.descr += ' - CDR'
				} else if (exm.isFilter) {
					let f = exm.getFilter()
					examImg.descr = f != '' ? f : exm.fixation + ' - filtered'
				}

				//console.log("img: "+(examImg.image.substring(0, 50)));

				imageList.push(examImg)
			} else {
				//console.log("null addition image field - "+i);
			}
		}
		return imageList
	}

	isLastCheckedAnswer(answers: Answer[], currentIndex: number): boolean {
		for (let i = currentIndex + 1; i < answers.length; i++) {
			if (answers[i].checked) {
				return false
			}
		}
		return true
	}
}
