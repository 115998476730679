import { Component, OnInit } from '@angular/core'
import { TranslateService } from '@ngx-translate/core'

import { NgbModal, NgbActiveModal, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap'
// import { FormsModule, NgForm } from '@angular/forms' // ReactiveFormsModule

// import { Config } from '../../../config'
import { SessionService } from '../../service/session.service'
// import { Visit } from '../../models/visit.model'
// import { Patient } from '../../models/patient.model'

import { Util } from '../../models/util.model'
import { Anamnesis, PatientReply, VA } from 'src/app/models/anamnesis.model'
import { Patient } from 'src/app/models/patient.model'
import { AppToastService } from 'src/app/service/toast.service'
import { ToastOptions } from 'src/app/models/toast.model'
import { AnamnesisService } from 'src/app/service/anamnesis.service'

@Component({
	selector: 'anamnesis-modal',
	templateUrl: './anamnesis.modal.html',
	styleUrls: ['./anamnesis.modal.scss'],
})
export class AnamnesisModal implements OnInit {
	amnAccepted: boolean
	amnAnswered: boolean
	anamnesis: Anamnesis[] // list aanamnesi nuova vuota
	// patAnamnesis: PatientReply[]

	patientVa: VA
	vaComplete: boolean

	currPatient: Patient
	patId: number

	loaded: boolean

	totalSteps: number
	currentStep: number
	isInFirstStep: boolean
	isInLastStep: boolean

	constructor(
		public activeModal: NgbActiveModal,
		public session: SessionService,
		public anamnesisService: AnamnesisService,
		private translator: TranslateService,
		public modalService: NgbModal,
		private toastService: AppToastService
	) {
		Util.debug('AnamnesiModal - constructor')

		this.amnAccepted = false
		this.amnAnswered = false

		this.loaded = false

		// this.patAnamnesis = []
		// this.anamnesis = []

		this.patientVa = new VA()

		this.patId = session.getCurrentPatientId()
		this.currPatient = this.session.getDtPatient(this.patId)
		this.totalSteps = 0
		this.currentStep = 1
		this.isInFirstStep = true
		this.isInLastStep = false
		// console.log(this.currPatient)
		// console.log(this.patId)
	}
	ngOnInit(): void {
		Util.debug('AnamnesiModal - ngOninit')

		if (this.currPatient.origin == Patient.ENTRY_SELF) {
			let body = this.translator.instant('TOAST.NOTIFICATIONS.ANAMNESIS_CHECK')
			let header = this.translator.instant('TOAST.HEADER.WARNING')
			let options = new ToastOptions('info_blue')
			options.autohide = true
			options.delay = 5000
			this.toastService.show(header, body, false, options, 'center')
		}

		this.checkAnamnesis().then((anamnesis) => {
			// console.log(anamnesis)

			this.anamnesisService
				.getPatientVA(this.patId)
				.then((va) => {
					Util.debug('(AnamnesiModal) VA found ')

					this.patientVa = va
					this.vaComplete = this.patientVa.va_right !== '' && this.patientVa.va_left !== '' && this.patientVa.va_bino !== ''

					this.loaded = true
				})
				.catch((err) => {
					console.log(err)
					this.loaded = true
				})
		})
	}

	private checkAnamnesis(): Promise<Anamnesis[]> {
		const promise = new Promise<Anamnesis[]>((resolve, reject) => {
			if (this.anamnesis && this.anamnesis.length > 0) {
				Util.debug('(AnamnesiModal) found already present anamnesis found')
				resolve(this.anamnesis)
			} else {
				Util.debug('(AnamnesiModal) request new ')
				this.anamnesisService
					.loadAnamnesisQuestions()
					.then((anamnesis: Anamnesis[]) => {
						if (anamnesis && anamnesis.length > 0) {
							Util.debug('(AnamnesiModal) anamnesis found questions: ' + anamnesis.length)
							this.anamnesis = anamnesis
							resolve(this.anamnesis)
						} else {
							Util.debug('(AnamnesiModal) NO anamnesis found')
							this.anamnesis = []
							resolve(this.anamnesis)
						}
					})
					.catch((err) => {
						this.anamnesis = []
						console.log(err)
						reject(err)
					})
			}
		})

		return promise
	}

	checkMandatoryVA(va: VA) {
		// console.log(va)
		this.vaComplete = va.va_right !== '' && va.va_left !== '' && va.va_bino !== ''
	}

	buildAnswers() {
		let amnlist = this.anamnesis
		const patReply: PatientReply[] = []
		// console.log(amnlist)

		for (let i = 0; i < amnlist.length; i++) {
			const element = amnlist[i]

			const answ = amnlist[i].answers

			let obj: PatientReply = { question_id: element.question_id, reply_ids: answ.filter((el) => el.checked).map((el) => el.reply_id) }

			patReply.push(obj)
		}

		return patReply
	}

	dismiss() {
		Util.debug('(AnamnesiModal) dismiss modal, no anamnesi saved')
		this.activeModal.close(false)
	}

	saveAnamnesis() {
		let answlist = this.buildAnswers()

		let va = this.patientVa

		let canSave: boolean = false

		if (this.currPatient.origin == Patient.ENTRY_SELF) {
			canSave = this.vaComplete && answlist && answlist.length > 0
		} else {
			canSave = this.amnAnswered && this.vaComplete && answlist && answlist.length > 0
		}

		if (canSave) {
			// console.log(patId)
			this.anamnesisService
				.savePatientAnamnesis(this.patId, answlist, va)
				.then((resp) => {
					Util.debug('Save patAnamnesis ')
					this.activeModal.close(true)
				})
				.catch((err) => {
					console.log(err)
				})
		} else {
			Util.debug('PatAnamnesis - nothing to save')
		}
	}

	onStepsFetched(event: { totalSteps: number }) {
		this.totalSteps = event.totalSteps
	}

	goToPreviousStep() {
		this.currentStep--
		this.isInFirstStep = this.currentStep === 1
		this.isInLastStep = this.currentStep === this.totalSteps
	}

	goToNextStep() {
		this.currentStep++
		this.isInFirstStep = this.currentStep === 1
		this.isInLastStep = this.currentStep === this.totalSteps
	}
}
